<template>
  <div>
    <v-container fluid class="px-15 pb-10 pt-4">
      <v-row v-if="!loading" justify="start" align="start" class="ma-0 mt-5 mb-10">
        <v-col cols="12" class="pa-0">
          <div class="headline-medium text-primary">
            {{ texts.dashboard.welcomeTitle }} {{ items.sUserName }}!
          </div>
        </v-col>
        <v-col cols="12" class="pa-0">
          <div class="body-large text-secondary">
            {{ texts.dashboard.dashboardDescription }}
          </div>
        </v-col>
      </v-row>
      <v-skeleton-loader
        v-else
        class="mx-3 my-3"
        width="348"
        height="100px"
        type="image"
      ></v-skeleton-loader>
      <div style="display: flex; flex-wrap: wrap; gap: 40px; justify-content: center;">
        <v-card
          v-if="!loading"
          class="card-main mx-4"
          style="border: 1px solid #c5cdd3; border-radius: 32px;"
          width="348"
          height="200"
          outlined
        >
          <v-card-text class="pt-6">
            <v-row align="center">
              <v-col cols="9" class="pl-5">
                <div class="usd-balance">{{ texts.dashboard.exchangeRateActual }}</div>
                <div class="content-balance" style="color: #193d6e">
                  {{ formatMoney(items.exchangeRateToday ? items.exchangeRateToday : 0) }}
                  MXN
                </div>
              </v-col>
            </v-row>
            <div
              class="pl-3 pt-2 blocked-amount"
              style="position: relative; z-index: 10; padding-right: 90px"
            >
              {{ texts.dashboard.exchangeRateTodayDescription }} {{ countdownDashboard }}
              {{ texts.dashboard.seconds }}
            </div>

            <div class="pt-2">
              <v-btn
                @click="openModalAlerts"
                color="white"
                class="mt-2 d-flex align-center"
                elevation="0" 
                rounded
              >
                <div style="display: flex; justify-content: center; align-items: center; background-color: #fff; color: #193D6E; 
                            margin-right: 8px; box-shadow: 4px 0 4px rgba(0, 0, 0, 0.2); padding: 8px; border-radius: 50%; margin-left: -16px;">
                  <v-icon color="#193D6E" size="20">mdi-bell</v-icon>
                </div>
                <span style="color: black; text-align: left; text-transform: none;">
                  {{ texts.dashboard.createAlerts }}
                </span>
              </v-btn>
            </div>
            
          </v-card-text>
          <div
            style="
              position: absolute;
              top: 0;
              right: 0;
              margin-top: -12px;
              margin-right: -36px;
              background-color: #193d6e;
              width: 150px;
              height: 150px;
              border-radius: 100px;
            "
          ></div>
        </v-card>
        <v-skeleton-loader
          v-else
          class="mx-3 my-3"
          width="348"
          height="200"
          type="image"
        ></v-skeleton-loader>
        <v-card
          v-if="!loading"
          class="card-main mx-4"
          style="border: 1px solid #c5cdd3; border-radius: 32px;"
          width="348"
          height="200"
          elevation="3"
          outlined
        >
          <v-card-text class="pt-6">
            <v-row align="center">
              <v-col cols="9" class="pl-5">
                <div class="usd-balance">{{ texts.dashboard.usdBalance }}</div>
                <div class="content-balance">
                  ${{ formatMoney(balanceUsd ? balanceUsd : 0) }} USD
                </div>
              </v-col>
              <v-col cols="3" class="text-right pr-5">
                <v-img
                  style="border-radius: 10px"
                  src="../../assets/images/Flags_usa.svg"
                  alt="usa-flag"
                  width="47"
                  height="35"
                />
              </v-col>
            </v-row>
            <div class="pl-3 pt-2 blocked-amount">
              {{ texts.dashboard.blockedAmount }}
              <span class="blocked-amount-2"
                >${{ formatMoney(blockedUsd ? blockedUsd : 0) }}</span
              >
            </div>
            <div style="text-align: center">
              <v-btn
                small
                color="white"
                class="btn-custom mt-2"
                elevation="0"
                @click="openModalAddFoundss"
              >
                <v-icon left dark color="#0075B8"> - -plus-circle-outline</v-icon>
                <span class="buttonAddFounds">{{ texts.dashboard.addFounds }}</span>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-skeleton-loader
          v-else
          class="mx-3 my-3"
          width="348"
          height="200"
          type="image"
        ></v-skeleton-loader>
        <v-card
          v-if="!loading"
          class="card-main mx-4"
          style="border: 1px solid #c5cdd3; border-radius: 32px;"
          width="348"
          height="200"
          elevation="3"
          outlined
        >
          <v-card-text class="pt-6">
            <v-row align="center">
              <v-col cols="9" class="pl-5">
                <div class="usd-balance">{{ texts.dashboard.mxnBalance }}</div>
                <div class="content-balance pt-3">
                  ${{ formatMoney(balanceMxn ? balanceMxn : 0) }} MXN
                </div>
              </v-col>
              <v-col cols="3" class="text-right pr-5">
                <v-img
                  style="border-radius: 10px"
                  src="../../assets/images/Flags_mex.svg"
                  alt="mex-flag"
                  width="47"
                  height="35"
                />
              </v-col>
            </v-row>
            <div class="pl-3 pt-2 blocked-amount">
              {{ }}
              <span class="blocked-amount-2">{{ }}</span>
            </div>
            <div style="text-align: center" v-if="sTypeAccount === 'EFEXMXN'">
              <v-btn
                small
                color="white"
                class="btn-custom mt-2"
                elevation="0"
                @click="openDialogForex"
              >
                <v-icon left dark color="#0075B8">mdi-cash-fast</v-icon>
                <span class="buttonAddFounds">{{ texts.dashboard.convert }}</span>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-skeleton-loader
          v-else
          class="m"
          width="348"
          height="200"
          type="image"
        ></v-skeleton-loader>
      </div>
      <v-row class="mt-10 mx-auto mb-10" style="justify-content: space-around">
        <v-btn
          v-if="!loading && sTypeAccount != 'EFEXMXN'"
          color="#0075B8"
          class="btn-custom my-4 mx-4"
          elevation="2"
          style="width: 204px; border-radius: 36px;"
          outlined
          @click="openModalSendPay"
        >
          <v-icon left dark color="#0075B8">mdi-arrow-top-right-thin</v-icon>
          <span class="buttonAddFounds">{{ texts.dashboard.sendPay }}</span>
        </v-btn>
        <v-btn
          v-else-if="!loading && sTypeAccount === 'EFEXMXN'"
          color="#0075B8"
          class="btn-custom my-4 mx-4"
          elevation="2"
          style="width: 204px; border-radius: 36px;"
          outlined
          @click="openModalWithdrawal"
        >
          <v-icon left dark color="#0075B8">mdi-arrow-top-right-thin</v-icon>
          <span class="buttonAddFounds">{{ texts.dashboard.collectionPay }}</span>
        </v-btn>
        <v-skeleton-loader
          v-else
          class="mx-3 my-3"
          width="348"
          height="100"
          type="image"
        ></v-skeleton-loader>
        <v-btn
          v-if="!loading && sTypeAccount != 'EFEXMXN'"
          color="#0075B8"
          class="btn-custom my-4 mx-4"
          elevation="2"
          style="width: 204px; border-radius: 36px;"
          outlined
          @click="openDialogForex"
        >
          <v-icon left dark color="#0075B8">mdi-swap-horizontal</v-icon>
          <span class="buttonAddFounds">{{ texts.dashboard.convert }}</span>
        </v-btn>
        <v-btn
          v-else-if="!loading && sTypeAccount === 'EFEXMXN'"
          color="#0075B8"
          class="btn-custom my-4 mx-4"
          elevation="2"
          style="width: 204p; border-radius: 36px;"
          outlined
          @click="openModalSendPaySuppliers"
        >
          <v-icon left dark color="#0075B8">mdi-arrow-top-right-thin</v-icon>
          <span class="buttonAddFounds">{{ texts.dashboard.sendPay }}</span>
        </v-btn>
        <v-skeleton-loader
          v-else
          class="mx-3 my-3"
          width="348"
          height="100px"
          type="image"
        ></v-skeleton-loader>
        <div class="btn-container my-4 mx-4">
          <v-tooltip v-if="!loading && isDisabledForward" bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-btn
                  color="#0075B8"
                  class="btn-custom"
                  elevation="2"
                  outlined
                  :disabled="isDisabledForward"
                  @click="openDialogForward"
                  style="width: 204px; border-radius: 36px;"
                >
                  <v-icon left dark color="#0075B8">mdi-swap-horizontal</v-icon>
                  <span class="buttonAddFounds">{{ texts.dashboard.forward }}</span>
                </v-btn>
              </div>
            </template>
            <span>{{ texts.dashboard.forwardTime }}</span>
          </v-tooltip>
          <v-btn
            v-else-if="!loading"
            color="#0075B8"
            class="btn-custom"
            elevation="2"
            outlined
            :disabled="isDisabledForward"
            @click="openDialogForward"
            style="width: 204px; border-radius: 36px;"
          >
            <v-icon left dark color="#0075B8">mdi-swap-horizontal</v-icon>
            <span class="buttonAddFounds">{{ texts.dashboard.forward }}</span>
          </v-btn>
          <!-- Loader solo cuando loading es true -->
          <v-skeleton-loader
            v-if="loading"
            class="mx-3 my-3"
            width="348"
            height="100px"
            type="image"
          ></v-skeleton-loader>
        </div>
        <v-btn
          v-if="!loading"
          color="#0075B8"
          class="btn-custom my-4 mx-4"
          elevation="2"
          style="width: 204px; border-radius: 36px;"
          outlined
          :disabled="true"
          @click="openMassivePayments"
        >
          <v-icon left dark color="#0075B8">mdi-cash-multiple</v-icon>
          <span class="buttonAddFounds">Pagos masivos</span>
        </v-btn>
        <v-skeleton-loader
          v-else
          class="mx-3 my-3"
          width="348"
          height="100px"
          type="image"
        ></v-skeleton-loader>
      </v-row>
      <!-- <div class="mb-10 mt-5" v-if="!loading">
        <v-card class="pa-4 px-8" style="border-radius: 36px;">
          <div class="d-flex" style="justify-content: space-between">
            <div>
              <div class="d-flex">
                <div
                  style="
                    font-size: 32px;
                    font-weight: bold;
                    font-family: montserrat-semibold;
                  "
                >
                  {{ items.fresherCoins }}
                </div>
                <div class="d-flex">
                  <v-icon dark color="#0075B8">mdi-star</v-icon>
                </div>
              </div>
              <div class="text-primary">
                {{ "Fresher coins" }}
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <v-btn
                outlined
                :disabled="false"
                @click="dialogRewards = true"
                class="px-6"
                color="grey darken-2"
                style="border-radius: 20px"
              >
                Rewards
                <v-icon dark color="#0075B8">mdi-star</v-icon>
              </v-btn>
            </div>
          </div>
          <div class="scroll" style='overflow-x: scroll; position: relative; height: 100px; display: flex;'>
            <div class="d-flex">
              <div
                v-for="idx in 20"
                :key="idx"
                class="d-flex"
                style="flex-direction: column; justify-content: flex-end;"
              >
                <div style="margin-bottom: 5px; position: absolute; margin-top: -77px;">
                  <div style="color: #193d6e; position: absolute; margin-top: -77px; z-index: 2;" v-if="Math.floor(items.fresherCoins / 2500) == idx - 1" :style="{ marginLeft: `${( ((items.fresherCoins % 2500) / 2500) === 0 ? -3 : (((items.fresherCoins % ((idx - 1) * 2500)) / 2500) * 100) - 10 )}px` }">
                    <v-icon dark large color="#0075B8">mdi-map-marker-star</v-icon>
                  </div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on"   style="color: #193d6e; position: absolute; margin-top: -77px; z-index: 1;" v-if="Math.floor(items.pendingFresherCoins / 2500) == idx - 1" :style="{ marginLeft: `${( ((items.pendingFresherCoins % 2500) / 2500) === 0 ? -3 : (((items.pendingFresherCoins % ((idx - 1) * 2500)) / 2500) * 100) - 10 )}px` }">
                        <v-icon dark large color="grey">mdi-map-marker-star</v-icon>
                      </div>
                    </template>
                    <span>{{ selectLanguage == 'en' ? 'Pending forwards to process' : 'Forwards pendientes por procesar' }}</span>
                  </v-tooltip>
                </div>
                <div class="d-flex" style="flex-direction: row">
                  <div style="display: flex; flex-direction: column; margin-left: -5px; align-items: center; width: 40px;">
                    <div
                      :style="Math.floor(items.fresherCoins / 2500) < idx - 1 ? 'border-radius: 100px; border: 4px solid; width: 20px; background-color: white; height: 20px; z-index: 10; color: rgba(0, 0, 0, 0.25)' : 'border-radius: 100px; border: 7px solid; width: 20px; background-color: white; height: 20px; z-index: 10; color: #193d6e;'"
                    ></div>
                    <div style="color: black; font-family: 'montserrat-medium'; font-size: 16px;">
                      {{ (idx - 1) * 2500 }}
                    </div>
                  </div>
                  <div
                    :style="Math.floor(items.fresherCoins / 2500) > idx - 1 ? 
                      'width: 80px; margin-top: 7.5px; height: 5px; background-color: #193d6e; border-radius: 5px; margin-left: -15px; margin-right: -15px' : 
                      Math.floor(items.fresherCoins / 2500) == idx - 1 ? 
                      `width: 80px; margin-top: 7.5px; height: 5px; background: linear-gradient(to right, #193d6e ${(((items.fresherCoins % 2500) / 2500) * 100) - 10 }%, rgba(0, 0, 0, 0.25) ${((((items.fresherCoins % 2500) / 2500) * 100) - 10) + 30 }%); border-radius: 5px; margin-left: -15px; margin-right: -15px` : 
                      'width: 80px; margin-top: 7.5px; height: 5px; background-color: rgba(0, 0, 0, 0.25); border-radius: 5px;margin-left: -15px; margin-right: -15px'"
                  />
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </v-card>
      </div>
      <v-skeleton-loader
        v-else
        class="mx-3 my-3"
        width="100%"
        height="100px"
        type="image"
        ></v-skeleton-loader> -->
      <!-- <v-row class="mx-auto my-10" style="display: flex; justify-content: space-around">
        <v-card
          v-if="!loading"
          :to="sTypeAccount == 'EFEXUSD' ? '/invoices' : '/invoicesReceivable'"
          class="mt-5 py-6 mx-4"
          elevation="5"
          style="width: 300px; border: 1px solid #c5cdd3"
        >
          <v-card-text class="py-6 px-10">
            <v-row align="center" style="flex-direction: column">
              <v-icon x-large style="color: #193d6e">mdi-invoice-edit</v-icon>
              <div class="content-balance pt-3" style="font-size: 14px; color: #193d6e">
                {{ texts.dashboard.pendingInvoices }}
              </div>
              <div class="content-balance pt-3" style="color: #193d6e">
                {{
                  sTypeAccount == "EFEXMXN" ? invoicesLength : items.unassignedInvoices
                }}
              </div>
            </v-row>
          </v-card-text>
        </v-card>
        <v-skeleton-loader
          v-else
          class="mx-3 my-3"
          width="300px"
          type="image"
        ></v-skeleton-loader>
        <v-card
          v-if="!loading && sTypeAccount != 'EFEXMXN'"
          :to="'/balance'"
          class="mt-5 py-6 mx-4"
          elevation="5"
          style="width: 300px; border: 1px solid #c5cdd3"
        >
          <v-card-text class="py-6 px-10">
            <v-row align="center" style="flex-direction: column">
              <v-icon x-large style="color: #193d6e">mdi-cash</v-icon>
              <div
                class="content-balance pt-3"
                style="font-size: 18px; color: #193d6e; font-weight: bold"
              >
                {{ texts.dashboard.availableConversions }}
              </div>
              <div class="content-balance pt-3" style="color: #193d6e">
                {{ items.transactionsAvailable }}
              </div>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          v-if="!loading"
          :to="'/forwards'"
          class="mt-5 py-6 mx-4"
          elevation="5"
          style="width: 300px; border: 1px solid #c5cdd3"
        >
          <v-card-text class="py-6 px-10">
            <v-row align="center" style="flex-direction: column">
              <v-icon x-large style="color: #193d6e">mdi-currency-usd</v-icon>
              <div class="content-balance pt-3" style="font-size: 14px; color: #193d6e">
                {{ texts.dashboard.activeForwards }}
              </div>
              <div class="content-balance pt-3" style="color: #193d6e">
                {{ items.forwardsActive }}
              </div>
            </v-row>
          </v-card-text>
        </v-card>
        <v-skeleton-loader
          v-else
          class="mx-3 my-3"
          width="300px"
          type="image"
        ></v-skeleton-loader>
      </v-row> -->
    </v-container>

    <v-dialog v-model="dialogAlerts" persistent class="pa-8" max-width="720">
      <v-card style="background-color: #F9FAFD">
          <div>
            <v-card-title class="d-flex justify-space-between align-center" style="color: #193D6E; font-weight: bold;">
              <span style="font-size: 24px;">{{ texts.dashboard.priceAlerts }}</span>
              <v-btn icon @click="closeDialogAlerts">
                <v-icon color="#193D6E">mdi-close</v-icon>
              </v-btn>
            </v-card-title>

            <v-card-text class="px-16" style="background-color: #F9FAFD">
              <v-container
                class="pa-10 py-7"
                style="background-color: #FFF; border-radius: 10px; padding: 20px; margin-bottom: 16px;"
              >
                <v-row align="center" justify="space-between">
                  <v-col class="d-flex flex-column">
                    <div class="pb-5" style="color: #193D6E; font-size: 20px; font-weight: 550;">
                      1 USD =
                    </div>
                    <div class="pb-2" style="color: #193D6E; font-weight: bold; font-size: 36px;">
                      {{formatMoney(items.exchangeRateToday)}} MXN
                    </div>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn color="#0075B8" rounded class="d-flex align-center" @click="openDialogAlertsCreate" style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);">
                      <v-icon left color="white">mdi-bell</v-icon>
                      <span style="color: white; text-transform: none;">{{ texts.dashboard.createAlert }}</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>

              <v-container style="background-color: #FFF; border-radius: 30px; border: 1px solid #D4D4D4; min-height: 200px;" class="mb-5">
                <v-data-table
                  :headers="headersAlerts"
                  :items="alerts"
                  hide-default-footer
                >
                  <template v-slot:top>
                  </template>
                  <template v-slot:item.dAmount="{ item }">
                    <div style="display: inline-flex; align-items: center;">
                      <v-img
                        src="../../assets/icons/usa-mex.png"
                        alt="usd-mxn"
                        style="width: 26px; height: 16px; margin-right: 20px; object-fit: contain;"
                      ></v-img>
                      <span style="color: #2D2D2D; font-weight: 400;">1 USD = {{ item.dAmount }} MXN</span>
                    </div>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-icon v-if="!isLoadingAlertsDelete" size="20" color="#F44336" @click="deleteAlert(item)">mdi-trash-can</v-icon>
                    <v-progress-circular v-else indeterminate color="#F44336" size="20"></v-progress-circular>
                  </template>
                </v-data-table>
              </v-container>
            </v-card-text>
          </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAlertsCreate" persistent class="pa-8" max-width="450">
      <v-card>
        <v-card-title class="pl-16" style="color: #193D6E; font-weight: bold">
          <span style="font-size: 24px;">{{ texts.dashboard.createAlert }}</span>
        </v-card-title>
        <v-card-subtitle class="pl-16 pt-2">
          <v-icon color="#12A150">mdi-trending-up</v-icon> <span style="color: #12A150;">1 USD = {{ formatMoney(items.exchangeRateToday) }} MXN</span>
        </v-card-subtitle>
        <v-card-text>
         <v-container class="d-flex justify-center" style="color: #193D6E; font-size: 16px; font-weight: 500;">
          {{ texts.dashboard.notifyMeToSellUSD }}
         </v-container>

         <v-container class="d-flex justify-center px-12">
          <v-text-field
            type="number"
            v-model="targetPrice"
            solo
            :suffix="texts.dashboard.targetPrice"
            class="mb-5"
            style="border-radius: 10px; color: #737583;"
            hide-spin-buttons
            :autofocus="false"
          >
            <template #prepend-inner>
              <img src="../../assets/images/mx-flag.png" alt="Custom Icon" style="width: 26px; height: 20px;" />
            </template>
          </v-text-field>
         </v-container>

         <v-container class="d-flex justify-center">
          <v-row class="d-flex justify-center">
            <v-col class="d-flex justify-center">
              <v-btn color="#FFFFFF" rounded class="d-flex align-center" @click="closeDialogAlertsCreate" style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); border: 1px solid #0075B8; width: 150px;">
                <span style="color: #0075B8; text-transform: none; font-size: 16px;">{{ texts.dashboard.alertCancel }}</span>
              </v-btn>
            </v-col>
            <v-col class="d-flex justify-center">
              <v-btn
              color="#0075B8"
              rounded
              class="d-flex align-center"
              @click="createAlert"
              :loading="isLoadingAlertsCreate"
              style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); width: 150px;"
            >
              <template v-slot:loader>
                <v-progress-circular
                  indeterminate
                  :size="20"
                  style="color: white;"
                ></v-progress-circular>
              </template>
              <span style="color: white; text-transform: none; font-size: 16px;">{{ texts.dashboard.alertCreate }}</span>
            </v-btn>
            </v-col>
          </v-row>
         </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogWithdrawal" persistent class="pa-8" max-width="720">
      <v-card>
        <v-stepper v-model="e1" alt-labels elevation="0">
          <v-stepper-header
            color="#193D6E"
            class="mt-5 mr-15 ml-15"
            elevation="0"
            style="
              justify-content: center;
              align-items: center;
              justify-items: center;
              align-content: center;
              box-shadow: none;
            "
          >
            <v-stepper-step :complete="e1 > 1" step="1" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 3" step="3" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 4" step="4" color="#193D6E"> </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1" elevation="0" class="pt-0">
              <v-container class="mt-1">
                <v-card-title
                  class="pa-0"
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">{{
                    texts.invoices.selectTypeOfWallet
                  }}</span>
                </v-card-title>
                <v-card-text
                  class="py-6 px-4"
                  v-if="!loading"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: fit-content;
                    margin: auto;
                  "
                >
                  <v-card
                    class="d-flex pa-6"
                    outlined
                    :class="{ 'selected-card': selectedCard === 2 }"
                    @click="selectCard(2)"
                    elevation="2"
                    style="
                      align-self: stretch;
                      background: white;
                      border-radius: 10px;
                      border: 1px solid #dddddd;
                      justify-content: space-between;
                      align-items: center;
                      gap: 16px;
                      padding: 14px !important;
                      margin-bottom: 30px;
                    "
                  >
                    <div style="display: flex; align-items: center">
                      <div style="margin-right: 30px">
                        <div class="usd-balance">{{ texts.dashboard.usdBalance }}</div>
                        <div class="content-balance">
                          ${{ formatMoney(balanceUsd ? balanceUsd : 0) }} USD
                        </div>
                      </div>
                      <v-img
                        src="../../assets/images/Flags_usa.png"
                        alt="usa-flag"
                        width="50"
                        height="30"
                      />
                    </div>
                    <v-icon v-if="selectedCard === 2" color="#193D6E"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon v-else color="grey lighten-1"
                      >mdi-checkbox-blank-circle-outline</v-icon
                    >
                  </v-card>
                  <v-card
                    class="d-flex pa-6"
                    outlined
                    :class="{ 'selected-card': selectedCard === 3 }"
                    @click="selectCard(3)"
                    elevation="2"
                    style="
                      align-self: stretch;
                      background: white;
                      border-radius: 10px;
                      border: 1px solid #dddddd;
                      justify-content: space-between;
                      align-items: center;
                      gap: 16px;
                      padding: 14px !important;
                    "
                  >
                    <div style="display: flex; align-items: center">
                      <div style="margin-right: 30px">
                        <div class="usd-balance">{{ texts.dashboard.mxnBalance }}</div>
                        <div class="content-balance pt-3">
                          ${{ formatMoney(balanceMxn ? balanceMxn : 0) }} MXN
                        </div>
                      </div>
                      <v-img
                        src="../../assets/images/Flags_mex.png"
                        alt="mex-flag"
                        width="50"
                        height="30"
                      />
                    </div>
                    <v-icon v-if="selectedCard === 3" color="#193D6E"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon v-else color="grey lighten-1"
                      >mdi-checkbox-blank-circle-outline</v-icon
                    >
                  </v-card>
                </v-card-text>
                <div v-else style="display: flex; justify-content: center">
                  <v-progress-circular indeterminate color="amber"></v-progress-circular>
                </div>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    class="button-cancel mon-regular"
                    @click="cancel()"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    color="#193D6E"
                    @click="selectedCard === 2 ? getSendPayNowWithdrawal() : (e1 = 2)"
                    :disabled="selectedCard === null"
                  >
                    {{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-0">
              <v-container class="mt-1" v-if="selectedCard === 2">
                <v-card-title
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.dashboard.transferMoney }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                    "
                  >
                    <div class="py-8 px-10">
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.balanceAccount }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-weight: bold; font-size: 22px"
                      >
                        ${{ formatMoney(balanceUsd) }} USD
                      </div>
                    </div>
                  </div>
                  <div v-if="!loadingExchange">
                    <v-text-field
                      outlined
                      :label="texts.dashboard.sendAmount"
                      type="number"
                      persistent-placeholder
                      :hint="customHint"
                      :loading="isLoadingCalculatorMxn"
                      :disabled="isLoadingCalculatorMxn"
                      persistent-hint
                      v-model="amountForexUSD"
                      @change="updateAmountForexUSD"
                      prefix="$"
                      class="mb-5"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_usa.png"
                            alt=""
                            style="border-radius: 10px"
                          />
                          <h4 class="ml-2" style="font-weight: 500">USD</h4>
                        </div>
                      </template>
                    </v-text-field>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack" style="font-weight: 500">
                          {{ texts.dashboard.amountToConvert }}:
                          <span style="color: black; font-weight: bold"
                            >${{ formatMoney(amountForexUSD) }} USD</span
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack">
                          {{ texts.dashboard.exchangeRate }}:
                          <span style="color: black; font-weight: bold"
                            >1 USD = ${{ formatMoney(exchangeRateToday) }} MXN</span
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack">
                          {{ texts.dashboard.exchangeRateTodayDescription }}:
                          <span
                            style="color: black; font-weight: bold"
                            :style="{ color: countdownPayNow < 30 ? 'red' : 'black' }"
                            >{{ countdownPayNow }} {{ texts.dashboard.seconds }}</span
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <v-text-field
                      outlined
                      :label="texts.dashboard.toMexicanPesos"
                      :loading="isLoadingCalculatorUsd"
                      type="number"
                      persistent-placeholder
                      v-model="amountForexMXN"
                      :disabled="isLoadingCalculatorUsd"
                      @change="updateAmountForexMXN"
                      prefix="$"
                      class="mt-10"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_mex.png"
                            alt=""
                            style="border-radius: 10px"
                          />
                          <h4 class="ml-2" style="font-weight: 500">MXN</h4>
                        </div>
                      </template>
                    </v-text-field>
                  </div>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="image"
                  ></v-skeleton-loader>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 1">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="
                      parseFloat(amountForexUSD) > parseFloat(balanceUsd) ||
                      amountForexUSD == null ||
                      amountForexUSD == 0 ||
                      isLoadingCalculatorUsd ||
                      isLoadingCalculatorMxn
                    "
                    @click="e1 = 3"
                    >{{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
              <v-container class="mt-1" v-if="selectedCard === 3">
                <v-card-title
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.dashboard.transferMoney }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                    "
                  >
                    <div class="py-8 px-10">
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.balanceAccount }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-weight: bold; font-size: 22px"
                      >
                        ${{ formatMoney(balanceMxn) }} MXN
                      </div>
                    </div>
                  </div>
                  <div v-if="!loadingExchange">
                    <v-text-field
                      outlined
                      :label="texts.dashboard.sendAmount"
                      :loading="isLoadingCalculatorUsd"
                      type="number"
                      persistent-placeholder
                      v-model="amountForexMXN"
                      prefix="$"
                      class="mt-10"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_mex.png"
                            alt=""
                            style="border-radius: 10px"
                          />
                          <h4 class="ml-2" style="font-weight: 500">MXN</h4>
                        </div>
                      </template>
                    </v-text-field>
                  </div>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="image"
                  ></v-skeleton-loader>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 1">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="
                      parseFloat(amountForexMXN) > parseFloat(balanceMxn) ||
                      amountForexMXN == null ||
                      amountForexMXN == 0
                    "
                    @click="e1 = 3"
                    >{{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-0">
              <v-container class="mt-1" v-if="selectedCard === 2">
                <v-card-title
                  class="pa-0"
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.dashboard.addFoundsDetail }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="supplier">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                      flex-direction: column;
                      width: 600px;
                    "
                  >
                    <div
                      class="pt-6 pb-4 px-10"
                      style="display: flex; justify-content: space-between"
                      v-if="sTypeAccount != 'EFEXMXN'"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.clientName }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ supplier.sName }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.clabeNumber }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ "********" + sAccountNumber }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.amountToSend }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(amountForexUSD) }} USD
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.destinationReceives }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(amountForexMXN) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.exchangeRate }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(exchangeRateToday) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.sendDate }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ formatDate(paymentDay) }}
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <v-textarea
                        name="input-7-1"
                        :label="texts.dashboard.paymentConcept"
                        v-model="comment"
                        no-resize
                      ></v-textarea>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    :disabled="loading"
                    class="button-cancel mon-regular"
                    @click="getSendPayNowWithdrawal()"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="!comment || loading"
                    color="#0075B8"
                    @click="sendVerificationWithdrawal()"
                    >{{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
              <v-container class="mt-1" v-if="selectedCard === 3">
                <v-card-title
                  class="pa-0"
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.dashboard.addFoundsDetail }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="supplier">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                      flex-direction: column;
                      width: 600px;
                    "
                  >
                    <div
                      class="pt-6 pb-4 px-10"
                      style="display: flex; justify-content: space-between"
                      v-if="sTypeAccount != 'EFEXMXN'"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.clientName }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ supplier.sName }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.clabeNumber }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ "********" + sAccountNumber }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.destinationReceives }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(amountForexMXN) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.sendDate }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ formatDate(paymentDay) }}
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <v-textarea
                        name="input-7-1"
                        :label="texts.dashboard.paymentConcept"
                        v-model="comment"
                        no-resize
                      ></v-textarea>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    :disabled="loading"
                    class="button-cancel mon-regular"
                    @click="e1 = 2"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="!comment || loading"
                    color="#0075B8"
                    @click="sendVerificationWithdrawal()"
                    >{{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="4" class="pt-0">
              <v-container class="mt-1">
                <v-card-title
                  class="pa-0"
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.twoFactor.twoFactor }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="supplier">
                  <div
                    class="pt-4 px-4 mb-4"
                    style="
                      display: flex;
                      justify-content: space-around;
                      flex-direction: row;
                      width: 600px;
                    "
                  >
                    <div>
                      <v-otp-input
                        v-model="otp"
                        length="6"
                        type="number"
                        color="#193D6E"
                      ></v-otp-input>
                      <p style="font-size: 14px; font-weight: 500">
                        {{ texts.twoFactor.enterCode }}
                      </p>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    :disabled="loading"
                    class="button-cancel mon-regular"
                    @click="e1 = 3"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="selectedCard === 2"
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="otp.length < 6"
                    :loading="loading"
                    color="#193D6E"
                    @click="sendWithdrawal()"
                    >{{
                      sTypeAccount == "EFEXMXN"
                        ? texts.modalForm.confirmCollect
                        : texts.modalForm.confirmPay
                    }}
                  </v-btn>
                  <v-btn
                    v-if="selectedCard === 3"
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="otp.length < 6"
                    :loading="loading"
                    color="#193D6E"
                    @click="sendWithdrawalMX()"
                    >{{
                      sTypeAccount == "EFEXMXN"
                        ? texts.modalForm.confirmCollect
                        : texts.modalForm.confirmPay
                    }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogSendPaySuppliers"
      persistent
      width="fit-content"
      class="pa-8"
    >
      <v-card>
        <v-stepper v-model="e1" alt-labels elevation="0">
          <v-stepper-header
            color="#193D6E"
            class="mt-5 mr-15 ml-15"
            elevation="0"
            style="
              justify-content: center;
              align-items: center;
              justify-items: center;
              align-content: center;
              box-shadow: none;
            "
          >
            <v-stepper-step :complete="e1 > 1" step="1" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 3" step="3" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 4" step="4" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 5" step="5" color="#193D6E"> </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1" elevation="0" class="pt-0">
              <v-card-title
                style="display: flex; text-align: center; justify-content: center"
              >
                <span class="title font-weight-bold text-textblack">
                  {{ texts.dashboard.sendPayDetail }}
                </span>
              </v-card-title>
              <v-card-text class="py-0">
                <div class="mb-4">
                  <v-list rounded v-if="!isLoadingSuppliers">
                    <v-autocomplete
                      :items="suppliers"
                      filled
                      label="Contactos"
                      item-text="sNameAC"
                      return-object
                      v-model="selected"
                      @change="selectSupplier(selected)"
                      rounded
                    ></v-autocomplete>
                  </v-list>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </v-card-text>
              <div style="display: flex; justify-content: end; margin-top: 20px">
                <v-btn elevation="0" class="button-cancel mon-regular" @click="cancel()">
                  {{ texts.modalForm.cancel }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="button-save"
                  style="max-width: 200px; color: #fff; background-color: #193d6e"
                  elevation="0"
                  :disabled="selected == null"
                  @click="e1 = 2"
                >
                  {{ texts.modalForm.continue }}
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2" elevation="0" class="pt-0">
              <v-container class="mt-1">
                <v-card-title
                  class="pa-0"
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">{{
                    texts.invoices.selectTypeOfWalletPay
                  }}</span>
                </v-card-title>
                <v-card-text
                  class="py-6 px-4"
                  v-if="!loading"
                  style="
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: fit-content;
                    margin: auto;
                  "
                >
                  <v-card
                    class="d-flex pa-6"
                    outlined
                    :class="{ 'selected-card': selectedCard === 2 }"
                    @click="selectCard(2)"
                    elevation="2"
                    style="
                      align-self: stretch;
                      background: white;
                      border-radius: 10px;
                      border: 1px solid #dddddd;
                      justify-content: space-between;
                      align-items: center;
                      gap: 16px;
                      padding: 14px !important;
                      margin-bottom: 30px;
                    "
                  >
                    <div style="display: flex; align-items: center">
                      <div style="margin-right: 30px">
                        <div class="usd-balance">{{ texts.dashboard.usdBalance }}</div>
                        <div class="content-balance">
                          ${{ formatMoney(balanceUsd ? balanceUsd : 0) }} USD
                        </div>
                      </div>
                      <v-img
                        src="../../assets/images/Flags_usa.png"
                        alt="usa-flag"
                        width="50"
                        height="30"
                      />
                    </div>
                    <v-icon v-if="selectedCard === 2" color="#193D6E"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon v-else color="grey lighten-1"
                      >mdi-checkbox-blank-circle-outline</v-icon
                    >
                  </v-card>
                  <v-card
                    class="d-flex pa-6"
                    outlined
                    :class="{ 'selected-card': selectedCard === 3 }"
                    @click="selectCard(3)"
                    elevation="2"
                    style="
                      align-self: stretch;
                      background: white;
                      border-radius: 10px;
                      border: 1px solid #dddddd;
                      justify-content: space-between;
                      align-items: center;
                      gap: 16px;
                      padding: 14px !important;
                    "
                  >
                    <div style="display: flex; align-items: center">
                      <div style="margin-right: 30px">
                        <div class="usd-balance">{{ texts.dashboard.mxnBalance }}</div>
                        <div class="content-balance pt-3">
                          ${{ formatMoney(balanceMxn ? balanceMxn : 0) }} MXN
                        </div>
                      </div>
                      <v-img
                        src="../../assets/images/Flags_mex.png"
                        alt="mex-flag"
                        width="50"
                        height="30"
                      />
                    </div>
                    <v-icon v-if="selectedCard === 3" color="#193D6E"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon v-else color="grey lighten-1"
                      >mdi-checkbox-blank-circle-outline</v-icon
                    >
                  </v-card>
                </v-card-text>
                <div v-else style="display: flex; justify-content: center">
                  <v-progress-circular indeterminate color="amber"></v-progress-circular>
                </div>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 1">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    color="#193D6E"
                    @click="selectedCard === 2 ? getSendPayNowSuppliers() : (e1 = 3)"
                    :disabled="selectedCard === null"
                  >
                    {{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-0">
              <v-container class="mt-1" v-if="selectedCard === 2">
                <v-card-title
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.dashboard.transferMoney }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                    "
                  >
                    <div class="py-8 px-10">
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.client }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-weight: bold; font-size: 22px"
                      >
                        {{ supplier.sName }}
                      </div>
                    </div>
                    <div class="py-8 px-10">
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.balanceAccount }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-weight: bold; font-size: 22px"
                      >
                        ${{ formatMoney(balanceUsd) }} USD
                      </div>
                    </div>
                  </div>
                  <div v-if="!loadingExchange">
                    <v-text-field
                      outlined
                      :label="texts.dashboard.sendAmount"
                      type="number"
                      persistent-placeholder
                      :hint="customHint"
                      :loading="isLoadingCalculatorMxn"
                      :disabled="isLoadingCalculatorMxn"
                      persistent-hint
                      v-model="amountForexUSD"
                      @change="updateAmountForexUSD"
                      prefix="$"
                      class="mb-5"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_usa.png"
                            alt=""
                            style="border-radius: 10px"
                          />
                          <h4 class="ml-2" style="font-weight: 500">USD</h4>
                        </div>
                      </template>
                    </v-text-field>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack" style="font-weight: 500">
                          {{ texts.dashboard.amountToConvert }}:
                          <span style="color: black; font-weight: bold"
                            >${{ formatMoney(amountForexUSD) }} USD</span
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack">
                          {{ texts.dashboard.exchangeRate }}:
                          <span style="color: black; font-weight: bold"
                            >1 USD = ${{ formatMoney(exchangeRateToday) }} MXN</span
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack">
                          {{ texts.dashboard.exchangeRateTodayDescription }}:
                          <span
                            style="color: black; font-weight: bold"
                            :style="{ color: countdownPayNow < 30 ? 'red' : 'black' }"
                            >{{ countdownPayNow }} {{ texts.dashboard.seconds }}</span
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <v-text-field
                      outlined
                      :label="texts.dashboard.toMexicanPesos"
                      :loading="isLoadingCalculatorUsd"
                      type="number"
                      persistent-placeholder
                      v-model="amountForexMXN"
                      :disabled="isLoadingCalculatorUsd"
                      @change="updateAmountForexMXN"
                      prefix="$"
                      class="mt-10"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_mex.png"
                            alt=""
                            style="border-radius: 10px"
                          />
                          <h4 class="ml-2" style="font-weight: 500">MXN</h4>
                        </div>
                      </template>
                    </v-text-field>
                  </div>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="image"
                  ></v-skeleton-loader>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 2">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="
                      parseFloat(amountForexUSD) > parseFloat(balanceUsd) ||
                      amountForexUSD == null ||
                      amountForexUSD == 0 ||
                      isLoadingCalculatorUsd ||
                      isLoadingCalculatorMxn
                    "
                    @click="e1 = 4"
                    >{{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
              <v-container class="mt-1" v-if="selectedCard === 3">
                <v-card-title
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.dashboard.transferMoney }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                    "
                  >
                    <div class="py-8 px-10">
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.client }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-weight: bold; font-size: 22px"
                      >
                        {{ supplier.sName }}
                      </div>
                    </div>
                    <div class="py-8 px-10">
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.balanceAccount }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-weight: bold; font-size: 22px"
                      >
                        ${{ formatMoney(balanceMxn) }} MXN
                      </div>
                    </div>
                  </div>
                  <div v-if="!loadingExchange">
                    <v-text-field
                      outlined
                      :label="texts.dashboard.sendAmount"
                      :loading="isLoadingCalculatorUsd"
                      type="number"
                      persistent-placeholder
                      v-model="amountForexMXN"
                      :disabled="isLoadingCalculatorUsd"
                      prefix="$"
                      class="mt-10"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_mex.png"
                            alt=""
                            style="border-radius: 10px"
                          />
                          <h4 class="ml-2" style="font-weight: 500">MXN</h4>
                        </div>
                      </template>
                    </v-text-field>
                  </div>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="image"
                  ></v-skeleton-loader>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 2">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="
                      parseFloat(amountForexMXN) > parseFloat(balanceMxn) ||
                      amountForexMXN == null ||
                      amountForexMXN == 0
                    "
                    @click="e1 = 4"
                    >{{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="4" class="pt-0">
              <v-container class="mt-1" v-if="selectedCard === 2">
                <v-card-title
                  class="pa-0"
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.dashboard.addFoundsDetail }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="supplier">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                      flex-direction: column;
                      width: 600px;
                    "
                  >
                    <div
                      class="pt-6 pb-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.clientName }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ supplier.sName }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.clabeNumber }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ "********" + sAccountNumber }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.amountToSend }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(amountForexUSD) }} USD
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.destinationReceives }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(amountForexMXN) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.exchangeRate }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(exchangeRateToday) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.sendDate }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ formatDate(paymentDay) }}
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <v-textarea
                        name="input-7-1"
                        :label="texts.dashboard.paymentConcept"
                        v-model="comment"
                        no-resize
                      ></v-textarea>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    :disabled="loading"
                    class="button-cancel mon-regular"
                    @click="getSendPayNowSuppliers()"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="!comment || loading"
                    color="#0075B8"
                    @click="sendVerificationSuppliers()"
                    >{{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
              <v-container class="mt-1" v-if="selectedCard === 3">
                <v-card-title
                  class="pa-0"
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.dashboard.addFoundsDetail }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="supplier">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                      flex-direction: column;
                      width: 600px;
                    "
                  >
                    <div
                      class="pt-6 pb-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.clientName }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ supplier.sName }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.clabeNumber }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ "********" + sAccountNumber }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.destinationReceives }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(amountForexMXN) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.sendDate }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ formatDate(paymentDay) }}
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <v-textarea
                        name="input-7-1"
                        :label="texts.dashboard.paymentConcept"
                        v-model="comment"
                        no-resize
                      ></v-textarea>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    :disabled="loading"
                    class="button-cancel mon-regular"
                    @click="e1 = 3"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="!comment || loading"
                    color="#0075B8"
                    @click="sendVerificationSuppliers()"
                    >{{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="5" class="pt-0">
              <v-container class="mt-1">
                <v-card-title
                  class="pa-0"
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.twoFactor.twoFactor }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="supplier">
                  <div
                    class="pt-4 px-4 mb-4"
                    style="
                      display: flex;
                      justify-content: space-around;
                      flex-direction: row;
                      width: 600px;
                    "
                  >
                    <div>
                      <v-otp-input
                        v-model="otp"
                        length="6"
                        type="number"
                        color="#193D6E"
                      ></v-otp-input>
                      <p style="font-size: 14px; font-weight: 500">
                        {{ texts.twoFactor.enterCode }}
                      </p>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    :disabled="loading"
                    class="button-cancel mon-regular"
                    @click="e1 = 4"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="selectedCard === 2"
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="otp.length < 6"
                    :loading="loading"
                    color="#193D6E"
                    @click="sendPayNowSupplier()"
                    >{{ texts.modalForm.confirmPay }}
                  </v-btn>
                  <v-btn
                    v-if="selectedCard === 3"
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="otp.length < 6"
                    :loading="loading"
                    color="#193D6E"
                    @click="sendPayNowMX()"
                    >{{ texts.modalForm.confirmPay }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSendPay" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-stepper v-model="e1" alt-labels elevation="0">
          <v-stepper-header
            color="#193D6E"
            class="mt-5 mr-15 ml-15"
            elevation="0"
            style="
              justify-content: center;
              align-items: center;
              justify-items: center;
              align-content: center;
              box-shadow: none;
            "
          >
            <v-stepper-step :complete="e1 > 1" step="1" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 3" step="3" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 4" step="4" color="#193D6E"> </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1" elevation="0" class="pt-0">
              <v-card-title
                style="display: flex; text-align: center; justify-content: center"
              >
                <span class="title font-weight-bold text-textblack">
                  {{ texts.dashboard.sendPayDetail }}
                </span>
              </v-card-title>
              <v-card-text class="py-0">
                <div class="mb-4">
                  <v-list rounded v-if="!isLoadingSuppliers">
                    <v-autocomplete
                      :items="suppliers"
                      filled
                      label="Contactos"
                      item-text="sNameAC"
                      return-object
                      v-model="selected"
                      @change="selectSupplier(selected)"
                      rounded
                    ></v-autocomplete>
                  </v-list>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </v-card-text>
              <div style="display: flex; justify-content: end; margin-top: 20px">
                <v-btn elevation="0" class="button-cancel mon-regular" @click="cancel()">
                  {{ texts.modalForm.cancel }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="button-save"
                  style="max-width: 200px; color: #fff; background-color: #193d6e"
                  elevation="0"
                  :disabled="selected == null"
                  @click="getSendPayNow()"
                >
                  {{ texts.modalForm.continue }}
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-0">
              <v-container class="mt-1">
                <v-card-title
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.dashboard.transferMoney }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                    "
                  >
                    <div class="py-8 px-10">
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.client }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-weight: bold; font-size: 22px"
                      >
                        {{ supplier.sName }}
                      </div>
                    </div>
                    <div class="py-8 px-10">
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.balanceAccount }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-weight: bold; font-size: 22px"
                      >
                        ${{ formatMoney(balanceUsd) }} USD
                      </div>
                    </div>
                  </div>
                  <div v-if="!loadingExchange">
                    <v-text-field
                      outlined
                      :label="texts.dashboard.sendAmount"
                      type="number"
                      persistent-placeholder
                      :hint="customHint"
                      :loading="isLoadingCalculatorMxn"
                      :disabled="isLoadingCalculatorMxn"
                      persistent-hint
                      v-model="amountForexUSD"
                      @change="updateAmountForexUSD"
                      prefix="$"
                      class="mb-5"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_usa.png"
                            alt=""
                            style="border-radius: 10px"
                          />
                          <h4 class="ml-2" style="font-weight: 500">USD</h4>
                        </div>
                      </template>
                    </v-text-field>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack" style="font-weight: 500">
                          {{ texts.dashboard.amountToConvert }}:
                          <span style="color: black; font-weight: bold"
                            >${{ formatMoney(amountForexUSD) }} USD</span
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack">
                          {{ texts.dashboard.exchangeRate }}:
                          <span style="color: black; font-weight: bold"
                            >1 USD = ${{ formatMoney(exchangeRateToday) }} MXN</span
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 text-textblack">
                          {{ texts.dashboard.exchangeRateTodayDescription }}:
                          <span
                            style="color: black; font-weight: bold"
                            :style="{ color: countdownPayNow < 30 ? 'red' : 'black' }"
                            >{{ countdownPayNow }} {{ texts.dashboard.seconds }}</span
                          >
                        </div>
                      </v-col>
                    </v-row>
                    <v-text-field
                      outlined
                      :label="texts.dashboard.toMexicanPesos"
                      :loading="isLoadingCalculatorUsd"
                      type="number"
                      persistent-placeholder
                      v-model="amountForexMXN"
                      :disabled="isLoadingCalculatorUsd"
                      @change="updateAmountForexMXN"
                      prefix="$"
                      class="mt-10"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_mex.png"
                            alt=""
                            style="border-radius: 10px"
                          />
                          <h4 class="ml-2" style="font-weight: 500">MXN</h4>
                        </div>
                      </template>
                    </v-text-field>
                  </div>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="image"
                  ></v-skeleton-loader>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 1">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="
                      parseFloat(amountForexUSD) > parseFloat(balanceUsd) ||
                      amountForexUSD == null ||
                      amountForexUSD == 0 ||
                      isLoadingCalculatorUsd ||
                      isLoadingCalculatorMxn
                    "
                    @click="e1 = 3"
                    >{{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-0">
              <v-container class="mt-1">
                <v-card-title
                  class="pa-0"
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.dashboard.addFoundsDetail }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="supplier">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                      flex-direction: column;
                      width: 600px;
                    "
                  >
                    <div
                      class="pt-6 pb-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.clientName }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ supplier.sName }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.clabeNumber }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ "********" + sAccountNumber }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.amountToSend }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(amountForexUSD) }} USD
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.destinationReceives }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(amountForexMXN) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.exchangeRate }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(exchangeRateToday) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.sendDate }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ formatDate(paymentDay) }}
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <v-textarea
                        name="input-7-1"
                        :label="texts.dashboard.paymentConcept"
                        v-model="comment"
                        no-resize
                      ></v-textarea>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    :disabled="loading"
                    class="button-cancel mon-regular"
                    @click="getSendPayNow()"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="!comment || loading"
                    color="#0075B8"
                    @click="sendVerification()"
                    >{{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="4" class="pt-0">
              <v-container class="mt-1">
                <v-card-title
                  class="pa-0"
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font-weight-bold text-textblack">
                    {{ texts.twoFactor.twoFactor }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="supplier">
                  <div
                    class="pt-4 px-4 mb-4"
                    style="
                      display: flex;
                      justify-content: space-around;
                      flex-direction: row;
                      width: 600px;
                    "
                  >
                    <div>
                      <v-otp-input
                        v-model="otp"
                        length="6"
                        type="number"
                        color="#193D6E"
                      ></v-otp-input>
                      <p style="font-size: 14px; font-weight: 500">
                        {{ texts.twoFactor.enterCode }}
                      </p>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    :disabled="loading"
                    class="button-cancel mon-regular"
                    @click="e1 = 3"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="otp.length < 6"
                    :loading="loading"
                    color="#193D6E"
                    @click="sendPayNow()"
                    >{{ texts.modalForm.confirmPay }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAddFoundss" persistent width="600" class="pa-8">
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center">
          <span class="title font-weight-bold text-textblack">
            {{ texts.dashboard.addFounds }}
          </span>
          <v-btn icon @click="dialogAddFoundss = false">
            <v-icon color="#2E404E">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="py-6">
          <v-sheet rounded="lg" color="#F7F8F9" class="pa-13">
            <div class="mb-4">
              <div class="subtitle-1 font-weight-bold text-textblack">
                {{ texts.dashboard.addFoundsDetail }}
              </div>
              <v-divider></v-divider>
            </div>

            <div class="my-2 pl-3 pr-8">
              <v-row
                v-for="(value, key) in dataAddFounds"
                :key="key"
                align="center"
                justify="space-between"
                class="mb-4"
              >
                <div class="body-1 modalTextAddFounds">{{ key }}</div>
                <div class="subtitle-2 font-weight-bold text-textblack">
                  {{ value }}
                </div>
              </v-row>
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogForex" persistent width="550" class="pa-8">
      <v-card>
        <v-stepper v-model="e1">
          <v-stepper-header
            color="#0075B8"
            class="mt-5 mr-15 ml-15"
            elevation="0"
            style="
              justify-content: center;
              align-items: center;
              justify-items: center;
              align-content: center;
              box-shadow: none;
            "
          >
            <v-btn
              icon
              @click="cancel()"
              style="
                margin-right: 10px;
                position: absolute;
                right: 0;
                top: 0;
                margin-top: 10px;
              "
            >
              <v-icon color="#2E404E">mdi-close</v-icon>
            </v-btn>
            <v-stepper-step :complete="e1 > 1" step="1" color="#0075B8"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2" color="#0075B8"> </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card-title
                style="display: flex; text-align: center; justify-content: center"
              >
                <span class="title font-weight-bold text-textblack">
                  {{ texts.dashboard.convert }}
                </span>
              </v-card-title>
              <v-card-text class="py-6" v-if="!loadingExchange">
                <div class="mb-4">
                  <div
                    class="subtitle-1 font-weight-bold text-textblack mb-8"
                    style="color: black"
                  >
                    {{ texts.dashboard.convertAmount }}
                  </div>
                  <div>
                    <v-text-field
                      outlined
                      :label="texts.dashboard.fromUsdDollars"
                      type="number"
                      :disabled="isLoadingCalculatorUsd"
                      :loading="isLoadingCalculatorMxn"
                      persistent-placeholder
                      style="font-weight: bold"
                      :hint="customHint"
                      v-model="amountForexUSD"
                      @change="updateAmountForexUSD"
                      persistent-hint
                      prefix="$"
                      class="mb-10"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_usa.png"
                            alt=""
                            style="border-radius: 10px"
                          />
                          <h4 class="ml-2" style="font-weight: 500">USD</h4>
                        </div>
                      </template>
                    </v-text-field>
                    <v-text-field
                      outlined
                      :disabled="isLoadingCalculatorUsd"
                      :loading="isLoadingCalculatorUsd"
                      :label="texts.dashboard.toMexicanPesos"
                      type="number"
                      style="font-weight: bold"
                      v-model="amountForexMXN"
                      @change="updateAmountForexMXN"
                      persistent-placeholder
                      prefix="$"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_mex.png"
                            alt=""
                            style="border-radius: 10px"
                          />
                          <h4 class="ml-2" style="font-weight: 500">MXN</h4>
                        </div>
                      </template>
                    </v-text-field>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 font-weight-bold text-textblack">
                          {{ texts.dashboard.exchangeRate }}
                        </div>
                      </v-col>
                      <v-col
                        class="pb-0"
                        style="display: flex; justify-content: flex-end"
                      >
                        <h2 style="color: black">
                          1 USD = ${{ formatMoney(exchangeRateToday) }} MXN
                        </h2>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 font-weight-bold text-textblack">
                          {{ texts.dashboard.exchangeRateTodayDescription }}
                        </div>
                      </v-col>
                      <v-col
                        class="pb-0"
                        style="display: flex; justify-content: flex-end"
                      >
                        <h2 :style="{ color: countdown < 30 ? 'red' : 'black' }">
                          {{ countdown }} {{ texts.dashboard.seconds }}
                        </h2>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 font-weight-bold text-textblack">
                          {{ texts.dashboard.totalConverted }}
                        </div>
                      </v-col>
                      <v-col
                        class="pb-0"
                        style="display: flex; justify-content: flex-end"
                      >
                        <h2 style="color: black">
                          ${{ amountForexMXN ? formatMoney(amountForexMXN) : 0 }} MXN
                        </h2>
                      </v-col>
                    </v-row>
                    <div class="mt-10" style="display: flex; justify-content: center">
                      <v-btn
                        large
                        color="#0075B8"
                        class="btn-custom mt-2"
                        elevation="0"
                        style="width: 400px"
                        outlined
                        :disabled="
                          !amountForexUSD ||
                          isLoadingCalculatorUsd ||
                          isLoadingCalculatorMxn
                        "
                        @click="sendVerificationForex()"
                      >
                        <span class="buttonAddFounds">{{
                          texts.modalForm.continue
                        }}</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-skeleton-loader v-else class="mx-auto" type="image"></v-skeleton-loader>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-card-title
                class="pa-0"
                style="display: flex; text-align: center; justify-content: center"
              >
                <span class="title font-weight-bold text-textblack">
                  {{ texts.twoFactor.twoFactor }}
                </span>
              </v-card-title>
              <v-card-text class="py-6 pb-0" v-if="supplier">
                <div
                  class="pt-4 mb-4"
                  style="
                    display: flex;
                    justify-content: space-around;
                    flex-direction: row;
                  "
                >
                  <div>
                    <v-otp-input
                      v-model="otp"
                      length="6"
                      type="number"
                      color="#0075B8"
                    ></v-otp-input>
                    <p style="font-size: 14px; font-weight: 500">
                      {{ texts.twoFactor.enterCode }}
                    </p>
                  </div>
                </div>
              </v-card-text>
              <div style="display: flex; justify-content: end; margin-top: 20px">
                <v-btn
                  elevation="0"
                  :disabled="loading"
                  class="button-cancel mon-regular"
                  @click="e1 = 1"
                >
                  {{ texts.modalForm.cancel }}
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn
                  class="button-save"
                  style="max-width: 200px; color: #fff; background-color: #193d6e"
                  elevation="0"
                  :disabled="otp.length < 6"
                  :loading="loading"
                  color="#0075B8"
                  @click="sendForex()"
                  >{{ texts.modalForm.confirmConvert }}
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogForward" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-stepper v-model="e1" alt-labels elevation="0">
          <v-stepper-header
            color="#193D6E"
            class="mt-5 mr-15 ml-15"
            elevation="0"
            style="
              justify-content: center;
              align-items: center;
              justify-items: center;
              align-content: center;
              box-shadow: none;
            "
          >
            <v-stepper-step :complete="e1 > 1" step="1" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2" color="#193D6E"> </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 3" step="3" color="#193D6E"> </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1" elevation="0" class="pt-0" style="width: 100%">
              <v-card-title
                style="display: flex; text-align: center; justify-content: center"
              >
                <span class="title font-weight-bold text-textblack">
                  {{ texts.dashboard.buyForward }}
                </span>
              </v-card-title>
              <v-card-text class="py-6">
                <div class="mb-4">
                  <div
                    class="subtitle-1 font-weight-bold text-textblack mb-8"
                    style="color: black"
                  >
                    {{ texts.dashboard.convertAmount }}
                  </div>
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="dDateForward"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dDateForward"
                        :label="texts.dashboard.selectDateForward"
                        prepend-inner-icon="mdi-calendar"
                        outlined
                        class="mb-3"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dDateForward"
                      :min="today"
                      :allowed-dates="onlyWeekdays"
                      :max="limit"
                      @change="updateDateForward()"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        :disabled="loadingExchange"
                        @click="$refs.dialog.save(dDateForward)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <v-text-field
                    outlined
                    :disabled="dDateForward == null || isLoadingCalculatorMxn"
                    :label="texts.dashboard.fromUsdDollars"
                    type="number"
                    persistent-placeholder
                    v-model="amountForwardUSD"
                    @change="updateAmountForwardUSD"
                    :loading="isLoadingCalculatorMxn"
                    persistent-hint
                    prefix="$"
                    class="mb-6"
                  >
                    <template v-slot:append>
                      <div style="display: flex; align-items: center !important">
                        <img
                          width="24"
                          height="24"
                          src="../../assets/images/Flags_usa.png"
                          alt=""
                          style="border-radius: 10px"
                        />
                        <h4 class="ml-2" style="font-weight: 500">USD</h4>
                      </div>
                    </template>
                  </v-text-field>
                  <v-text-field
                    outlined
                    :label="texts.dashboard.toMexicanPesos"
                    :disabled="dDateForward == null || isLoadingCalculatorUsd"
                    v-model="amountForwardMXN"
                    @change="updateAmountForwardMXN"
                    :loading="isLoadingCalculatorUsd"
                    type="number"
                    persistent-placeholder
                    prefix="$"
                  >
                    <template v-slot:append>
                      <div style="display: flex; align-items: center !important">
                        <img
                          width="24"
                          height="24"
                          src="../../assets/images/Flags_mex.png"
                          alt=""
                          style="border-radius: 10px"
                        />
                        <h4 class="ml-2" style="font-weight: 500">MXN</h4>
                      </div>
                    </template>
                  </v-text-field>
                  <div v-if="!loadingExchange">
                    <v-row v-if="forwardRate?.rate">
                      <v-col class="pb-0">
                        <div class="subtitle-1 font-weight-bold text-textblack">
                          {{ texts.dashboard.exchangeRate }}
                        </div>
                      </v-col>
                      <v-col
                        class="pb-0"
                        style="display: flex; justify-content: flex-end"
                      >
                        <h2 style="color: black">1 USD = ${{ forwardRate.rate }} MXN</h2>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pb-0">
                        <div class="subtitle-1 font-weight-bold text-textblack">
                          {{ texts.dashboard.totalConverted }}
                        </div>
                      </v-col>
                      <v-col
                        class="pb-0"
                        style="display: flex; justify-content: flex-end"
                      >
                        <h2 style="color: black">
                          ${{ formatMoney(amountForwardMXN) }} MXN
                        </h2>
                      </v-col>
                    </v-row>
                  </div>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="image"
                  ></v-skeleton-loader>
                </div>
              </v-card-text>
              <div style="display: flex; justify-content: end; margin-top: 20px">
                <v-btn elevation="0" class="button-cancel mon-regular" @click="cancel()">
                  {{ texts.modalForm.cancel }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  class="button-save"
                  style="max-width: 200px; color: #fff; background-color: #193d6e"
                  elevation="0"
                  :disabled="amountForwardUSD == null || amountForwardUSD == 0"
                  :loading="loading"
                  color="#193D6E"
                  @click="e1 = 2"
                >
                  {{ texts.modalForm.continue }}
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-0">
              <v-container class="mt-1">
                <v-card-text class="pt-0 pb-0" v-if="forwardRate">
                  <div
                    class="pt-4 px-4 mb-4"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e5f6fd;
                      flex-direction: row;
                      width: 600px;
                    "
                  >
                    <div>
                      <v-icon color="#2c93d6" style="margin-right: 12px"
                        >mdi-alert-octagon-outline</v-icon
                      >
                    </div>
                    <div>
                      <p style="font-size: 16px; font-weight: 700; color: #014361">
                        {{ texts.dashboard.forwardConditions }}
                      </p>
                      <p
                        style="font-size: 14px; font-weight: 500; color: #014361"
                        class="mb-0"
                      >
                        1. {{ texts.dashboard.forwardConditions1 }}
                      </p>
                      <v-divider class="my-4"></v-divider>
                      <p style="font-size: 14px; font-weight: 500; color: #014361">
                        2. {{ texts.dashboard.forwardConditions2 }}
                      </p>
                    </div>
                  </div>
                </v-card-text>
                <v-card-text class="pt-0 pb-0" v-if="forwardRate">
                  <div
                    class="mb-4"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                      flex-direction: column;
                      width: 600px;
                    "
                  >
                    <span
                      class="title font-weight-bold text-textblack"
                      style="text-align: center"
                    >
                      {{ texts.dashboard.forwardDetail }}
                    </span>
                    <div
                      class="pt-6 pb-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.exchangeRate }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        1 USD = ${{ formatMoney(forwardRate.rate) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.amountToSend }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        $ {{ formatMoney(amountForwardUSD) }} USD
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.amountToReceive }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        ${{ formatMoney(amountForwardMXN) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.scheduledDate }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ formatDate(dDateForward) }}
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        {{ texts.dashboard.forwardPercentage }}
                      </div>
                      <div
                        class=""
                        style="color: black; font-size: 22px; font-weight: bold"
                      >
                        {{ formatMoney(this.forwardRate.initial_percentage_amount) }} USD
                      </div>
                    </div>
                  </div>
                </v-card-text>
                <v-card-text class="pt-0 pb-0" v-if="forwardRate">
                  <div
                    class="pt-4 px-4 mb-4"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #fff4e5;
                      flex-direction: row;
                      width: 600px;
                    "
                  >
                    <div>
                      <v-icon color="rgb(237, 108, 2)" style="margin-right: 12px"
                        >mdi-alert-outline</v-icon
                      >
                    </div>
                    <div>
                      <p
                        style="font-size: 16px; font-weight: 700; color: rgb(102, 60, 0)"
                      >
                        {{ texts.dashboard.forwardConditions3 }}
                      </p>
                      <p
                        style="font-size: 14px; font-weight: 500; color: rgb(102, 60, 0)"
                      >
                        {{ texts.dashboard.forwardConditions3TextPart1 }}
                        {{ formatDate(dDateForward) }}.
                        {{ texts.dashboard.forwardConditions3TextPart2 }}
                      </p>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 1">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    color="#193D6E"
                    :disabled="loading"
                    @click="sendVerificationForward"
                  >
                    {{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-0">
              <v-container class="mt-1" style="width: 600px">
                <v-card-title
                  class="pa-0"
                  style="display: flex; text-align: center; justify-content: center"
                >
                  <span class="title font -weight-bold text-textblack">
                    {{ texts.twoFactor.twoFactor }}
                  </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="supplier">
                  <div
                    class="pt-4 mb-4"
                    style="
                      display: flex;
                      justify-content: space-around;
                      flex-direction: row;
                    "
                  >
                    <div>
                      <v-otp-input
                        v-model="otp"
                        length="6"
                        type="number"
                        color="#193D6E"
                      ></v-otp-input>
                      <p style="font-size: 14px; font-weight: 500">
                        {{ texts.twoFactor.enterCode }}
                      </p>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    :disabled="loading"
                    class="button-cancel mon-regular"
                    @click="e1 = 2"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #fff; background-color: #193d6e"
                    elevation="0"
                    :disabled="otp.length < 6"
                    :loading="loading"
                    color="#193D6E"
                    @click="sendForward()"
                  >
                    {{ texts.modalForm.confirmForward }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogMassivePayments" persistent width="80%" class="pa-8">
      <v-card>
        <v-btn
          icon
          @click="cancel()"
          style="
            margin-right: 10px;
            position: absolute;
            right: 0;
            top: 0;
            margin-top: 10px;
          "
        >
          <v-icon color="#2E404E">mdi-close</v-icon>
        </v-btn>
        <v-card-title style="display: flex; text-align: center; justify-content: center">
          <span class="title font-weight-bold text-textblack"> Pagos masivos </span>
        </v-card-title>
        <v-card-text class="py-6">
          <v-card elevation="4" class="pa-5 mb-4">
            <div
              class="subtitle-1 font-weight-bold text-textblack mb-2"
              style="color: black"
            >
              1. Descarga tu plantilla para cargar la información de pagos.
            </div>
            <v-btn
              color="green"
              class="ma-2 white--text"
              style="font-family: montserrat-bold"
              @click="downloadXlsx()"
            >
              Download
              <v-icon right dark> mdi-microsoft-excel </v-icon>
            </v-btn>
          </v-card>
          <v-card elevation="4" class="pa-5 mb-4">
            <div
              class="subtitle-1 font-weight-bold text-textblack mb-2"
              style="color: black"
            >
              2. Sube tu archivo para procesar tus pagos.
            </div>
            <v-file-input
              accept=".xlsx"
              v-model="file"
              @change="handleFileUpload"
              label="Ingresa tu xlsx con los datos de tus proveedores."
            ></v-file-input>
          </v-card>
          <v-card elevation="4" class="pa-5 mb-4">
            <div
              class="subtitle-1 font-weight-bold text-textblack mb-2"
              style="color: black"
            >
              3. Sube tu archivo para procesar tus pagos.
            </div>
            <v-data-table
              :headers="headersMassivePayment"
              :items="jsonData"
              hide-default-footer
            >
            </v-data-table>
          </v-card>
        </v-card-text>
        <div
          style="
            display: flex;
            justify-content: end;
            margin-top: 20px;
            padding: 0 24px 20px;
          "
        >
          <v-spacer></v-spacer>
          <v-btn
            class="button-save"
            style="max-width: 200px; color: #fff; background-color: #193d6e"
            elevation="0"
            :disabled="dDateForward == null"
            @click="e1 = 2"
            >Continuar
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRewards" persiten width="884" class="pa-8">
      <v-card class="container">
        <v-card-title class="d-flex justify-space-between align-center">
          <v-spacer></v-spacer>
          <div style="display: flex; align-items: center">
            <v-btn icon @click="dialogRewards = false">
              <v-icon color="#2E404E">mdi-information-slab-circle-outline</v-icon>
            </v-btn>
            <v-btn icon @click="dialogRewards = false">
              <v-icon color="#2E404E">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="py-6 pt-0">
          <div class="mb-4" style="display: flex; flex-direction: column; align-items: center;">
            <div class="font-weight-bold text-textblack" style="font-size: 24px; text-align: center; width: 382px; margin-bottom: 20px; color: #000000">
              {{ "¡Lorem ipsum dolor sit amet, consectetur adipiscing elit!" }}
            </div>
            <div class="font-weight-bold text-textblack" style="font-size: 16px; text-align: center;">
              {{ "Lorem ipsum dolor sit amet, consectetur adipiscing elit" }}
            </div>
            <v-divider></v-divider>
            <div class="mt-2">
              <div class="toggle-container">
                <v-btn
                  :class="['toggle-button', { active: selectedTab === 'catalogo' }]"
                  @click="changeTab('catalogo')"
                  elevation="0"
                  style="font-size: 12px; font-weight: 500; text-transform: none;"
                >
                  Catálogo
                </v-btn>
                <v-btn
                  :class="['toggle-button', { active: selectedTab === 'mis-productos' }]"
                  @click="changeTab('mis-productos')"
                  elevation="0"
                  style="font-size: 12px; font-weight: 500; text-transform: none;"
                >
                  Mis productos
                </v-btn>
              </div>
            </div>
          </div>
          <div class="px-10 d-flex mb-4" style="flex-wrap: wrap; gap: 20px;">
            <v-img
              style="border-radius: 25px"
              aspect-ratio="1"
              class="grey lighten-2"
              width="145"
              height="138"
            >
              <div style="position: absolute; bottom: 9px; right: 13px; background-color: #61A3D9; color: white; border-radius: 18px;">
                <v-icon color="white">mdi-plus</v-icon>
              </div>
            </v-img>
            <v-img
              style="border-radius: 25px"
              aspect-ratio="1"
              class="grey lighten-2"
              width="229"
              height="138"
            >
              <div style="position: absolute; bottom: 9px; right: 13px; background-color: #61A3D9; color: white; border-radius: 18px;">
                <v-icon @click="dialogProduct = true" color="white">mdi-plus</v-icon>
              </div>
            </v-img>
            <v-img
              style="border-radius: 25px"
              aspect-ratio="1"
              class="grey lighten-2"
              width="145"
              height="138"
            >
              <div style="position: absolute; bottom: 9px; right: 13px; background-color: #61A3D9; color: white; border-radius: 18px;">
                <v-icon color="white">mdi-plus</v-icon>
              </div>
            </v-img>
            <v-img
              style="border-radius: 25px"
              aspect-ratio="1"
              class="grey lighten-2"
              width="145"
              height="138"
            >
              <div style="position: absolute; bottom: 9px; right: 13px; background-color: #61A3D9; color: white; border-radius: 18px;">
                <v-icon color="white">mdi-plus</v-icon>
              </div>
            </v-img>
            <v-img
              style="border-radius: 25px"
              aspect-ratio="1"
              class="grey lighten-2"
              width="295"
              height="138"
            >
              <div style="position: absolute; bottom: 9px; right: 13px; background-color: #61A3D9; color: white; border-radius: 18px;">
                <v-icon color="white">mdi-plus</v-icon>
              </div>
            </v-img>
            <v-img
              style="border-radius: 25px"
              aspect-ratio="1"
              class="grey lighten-2"
              width="145"
              height="138"
            >
              <div style="position: absolute; bottom: 9px; right: 13px; background-color: #61A3D9; color: white; border-radius: 18px;">
                <v-icon color="white">mdi-plus</v-icon>
              </div>
            </v-img>
            <v-img
              style="border-radius: 25px"
              aspect-ratio="1"
              class="grey lighten-2"
              width="229"
              height="138"
            >
              <div style="position: absolute; bottom: 9px; right: 13px; background-color: #61A3D9; color: white; border-radius: 18px;">
                <v-icon color="white">mdi-plus</v-icon>
              </div>
            </v-img>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogProduct" persisten width="503">
      <v-card>
        <v-card-text class="py-6 pt-10">
          <div class=" d-flex mb-4" style="flex-wrap: wrap; gap: 20px;">
            <v-img
              style="border-radius: 25px"
              aspect-ratio="1"
              class="grey lighten-2"
              min-width="450"
              max-width="450"
              height="223"
            >
            </v-img>
          </div>
          <div class="mb-4" style="display: flex; flex-direction: column;">
            <div class="font-weight-bold text-textblack" style="font-size: 24px; width: 272px; margin-bottom: 20px; color: #000000; font-family: 'montserrat-bold';">
              {{ "Nombre de producto" }}
            </div>
            <div class="d-flex mb-2">
              <img src="@/assets/icons/fresherCoin.svg" width="24" heigth="24" alt="" />
              <div class="ml-2" style="font-size: 14px; font-family: 'montserrat-regular';">
                {{ "2000 Fresher coins"}}
              </div>
            </div>
            <div class="font-weight-bold text-textblack" style="font-size: 14px; text-align: left; font-family: 'montserrat-regular';">
              {{ "aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. " }}
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import * as XLSX from "xlsx";

export default {
  name: "DashboardView",
  data() {
    return {
      dialogRewards: false,
      clockTime: "",
      selectedTab: "catalogo",
      selected: null,
      sTypeAccount: "",
      currentDate: moment(),
      currentDateCdmx: moment().clone().utcOffset("-06:00"),
      isDisabledForward: false,
      lastUpdateclockTime: "",
      texts: "",
      amountForexUSD: 0,
      amountForexMXN: 0,
      amountForwardUSD: 0,
      dialogProduct: false,
      amountForwardMXN: 0,
      amountForexRealUSD: 0,
      modalAlerts: false,
      dialogAlerts: false,
      alerts: [],
      amountForwardRealUSD: 0,
      dialogForward: false,
      dialogMassivePayments: false,
      loading: true,
      dialogForex: false,
      modal: false,
      drawer: null,
      otp: "",
      actualCurrency: "0",
      actualCurrencyMxn: "0",
      selectedItem: null,
      headersMassivePayment: [
        {
          text: "Destinatario",
          value: "sPaymentRecipient",
          align: "center",
          sortable: false,
        },
        { text: "Email", value: "sEmail", align: "center", sortable: false },
        {
          text: "Valor a pagar (MXN)",
          value: "dAmount",
          align: "center",
          sortable: false,
        },
        { text: "RFC", value: "sRfc", align: "center", sortable: false },
        { text: "Banco destino", value: "sBankName", align: "center", sortable: false },
        {
          text: "Cuenta clabe",
          value: "sAccountNumber",
          align: "center",
          sortable: false,
        },
        { text: "Comentarios", value: "sComments", align: "center", sortable: false },
      ],
      isLoadingCalculatorUsd: false,
      isLoadingCalculatorMxn: false,
      referenceCurrency: "0",
      isLoading: false,
      isRegisterEfex: true,
      exchangeRateData: [],
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      balance: "0",
      items: [],
      today: null,
      tab: 0,
      contacts: [],
      itemsSelected: [],
      itemsSelectedForwards: [],
      isForwardRateCalculated: false,
      dDateForward: null,
      menu: false,
      comment: "",
      search: "SFD",
      bSelectedAll: false,
      dialogAddFoundss: false,
      dialogSendPay: false,
      dialogSendPaySuppliers: false,
      dialogWithdrawal: false,
      dialogAddFounds: false,
      dataAddFounds: {},
      dialogCreate: false,
      dialogPay: false,
      editableData: {},
      selectedCard: null,
      totalUsd: 0,
      selectedMenuItem: "dashboard",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY",
      },
      isForwardsActive: false,
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      bLoading: false,
      isDeferredPayment: false,
      stepper: false,
      e1: 1,
      blockedUsd: 0,
      timerCount: 30,
      headersInvoices: [
        { text: "Supplier", value: "sProvider", align: "center" },
        { text: "Purchase date", value: "dPurchaseDate", align: "center" },
        { text: "Invoice", value: "sInvoice", align: "center" },
        { text: "Total amount MXN", value: "dTotalAmount", align: "center" },
        { text: "Due date", value: "dDueDate", align: "center" },
        {
          text: "Exchange rate protection MXN",
          value: "dRateProtection",
          align: "center",
        },
        { text: "Currency profit (%)", value: "dProfitPercentage", align: "center" },
        { text: "Currency gain", value: "dCurrencyGain", align: "center" },
        { text: "Status", value: "sStatusId", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      headersForwards: [
        { text: "Type Transaction", value: "type", align: "center" },
        { text: "Transaction date", value: "dTransactionDate", align: "center" },
        { text: "Due date", value: "dDueDate", align: "center" },
        { text: "Missing days", value: "diasFaltantes", align: "center" },
        { text: "Total amount MXN", value: "dTotalAmount", align: "center" },
        { text: "Exchange rate", value: "dRateProtection", align: "center" },
        { text: "Invoice", value: "invoice", align: "center" },
        { text: "Purchase date", value: "purchaseDate", align: "center" },
        { text: "Currency profit (%)", value: "dProfitPercentage", align: "center" },
        { text: "Currency gain", value: "dCurrencyGain", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      headersForex: [
        { text: "Fecha de pago", value: "created_at", align: "center" },
        { text: "Destinatario", value: "sName", align: "center" },
        // { text: "Type Transaction", value: "type", align: "center" },
        { text: "Total amount MXN", value: "dDesAmount", align: "center" },
        { text: "Total amount USD", value: "dAmount", align: "center" },
        { text: "Exchange rate", value: "dExchangeRate", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      headersAlerts: [
        { text: "Active alerts", value: "dAmount", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      itemsForwards: [
        {
          type: "Forward",
          dTransactionDate: "March 15, 2024",
          diasFaltantes: "-7 days",
          dDueDate: "April 15, 2024",
          dTotalAmount: "300000",
          dRateProtection: "16.50",
          invoice: "",
          purchaseDate: "",
          dProfitPercentage: "",
          dCurrencyGain: "",
        },
        {
          type: "Forward",
          dTransactionDate: "April 1, 2024",
          diasFaltantes: "8 days",
          dDueDate: "April 30, 2024",
          dTotalAmount: "100000",
          dRateProtection: "16.50",
          invoice: "D-102",
          purchaseDate: "April 1, 2024",
          dProfitPercentage: "4.5",
          dCurrencyGain: "400",
        },
      ],
      itemsForex: [
        {
          type: "Forex",
          diasFaltantes: "-3",
          dDueDate: "April 15, 2024",
          dTotalAmount: "300000",
          dRateProtection: "16.50",
          invoice: "",
          purchaseDate: "",
          dProfitPercentage: "",
          dCurrencyGain: "",
        },
        {
          type: "Forex",
          diasFaltantes: "13",
          dDueDate: "April 30, 2024",
          dTotalAmount: "100000",
          dRateProtection: "16.50",
          invoice: "D-103",
          purchaseDate: "April 1, 2024",
          dProfitPercentage: "4.5",
          dCurrencyGain: "3000",
        },
        {
          type: "Forward",
          diasFaltantes: "13",
          dDueDate: "April 30, 2024",
          dTotalAmount: "100000",
          dRateProtection: "16.50",
          invoice: "D-104",
          purchaseDate: "April 1, 2024",
          dProfitPercentage: "4.5",
          dCurrencyGain: "200",
        },
      ],
      timerId: null,
      invoicesLength: 0,
      dataEfex: {},
      balanceUsd: null,
      balanceMxn: null,
      exchangeRateAdjustments: [],
      exchangeRatesStateForwards: [],
      isPayMxn: false,
      bSelectedAllSeparate: false,
      dialogEditProfileActive: false,
      isForwards: false,
      isForwardsPayment: false,
      userName: "",
      modalCurrency: 0,
      sections: [
        {
          icon: "mdi mdi-view-dashboard",
          value: "operation",
          title: "Operaciones",
          items: [
            {
              icon: "mdi mdi-view-dashboard",
              value: "dashboard",
              title: "Dashboard",
              disabled: false,
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Seguimiento de facturas",
              disabled: false,
            },
            {
              icon: "mdi-forward",
              value: "activeForwards",
              title: "Forwards activos",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-history",
          value: "history",
          title: "Historial",
          items: [
            {
              icon: "mdi-file-document",
              value: "exchangeRate",
              title: "Historial de conversiones",
              disabled: true,
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Historial de pagos",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-wallet",
          value: "wallets",
          title: "Wallets",
          items: [
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Proveedores",
              disabled: true,
            },
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Estado de cuenta",
              disabled: true,
            },
          ],
        },
      ],
      miniVariant: true,
      expandOnHover: false,
      registerFields: [
        { model: "sBankName", type: "text", label: "sBankName" },
        { model: "sAccountNumber", type: "text", label: "sAccountNumber" },
        { model: "sRoutingNumber", type: "text", label: "sRoutingNumber" },
      ],
      transactions: [],
      invoices: [],
      exchangeRateToday: null,
      exchangeRateTodayFullNumbers: null,
      exchangeRateTodayReference: null,
      loadingExchange: false,
      countdown: 59,
      countdownPayNow: 59,
      countdownDashboard: 59,
      intervalId: null,
      forexReverse: false,
      isLoadingSuppliers: false,
      forwardRate: {
        uuid: null,
        rate: null,
        initial_percentage_amount: null,
      },
      suppliers: [],
      supplier: {},
      sAccountNumber: null,
      limit: null,
      file: null,
      jsonData: [],
      isLoadingAlerts: false,
      isLoadingAlertsDelete: false,
      dialogAlertsCreate: false,
      targetPrice: null,
      isLoadingAlertsCreate: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    changeTab(tab) {
      this.selectedTab = tab; // Cambiar el estado de la pestaña
      this.$emit('tab-changed', tab); // Emitir evento para comunicar el cambio
    },
    tabStyle(tabIndex) {
      return {
        color: this.tab === tabIndex ? "#f0be43" : "#506172",
        fontSize: "16px",
        fontWeight: "600",
        width: "200px",
        lineHeight: "24px",
        fontFamily: "montserrat-bold",
      };
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    startCountdown() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.countdown = 59;
      this.intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(this.intervalId);
          this.updateDialogForex();
          this.getExchangeRate();
        }
      }, 1000);
    },
    selectCard(cardNumber) {
      this.selectedCard = cardNumber;
    },
    async calculatorAmount(amount, rate, reverse) {
      const headers = {
        Authorization: this.$store.state.sToken
          ? `Bearer ${this.$store.state.sToken}`
          : "",
      };
      try {
        const response = await DB.post(
          `${URI}/api/v1/${this.selectLanguage}/dashboard/calculatorAmount`,
          {
            amount: amount,
            rate: rate,
            reverse: reverse,
          },
          { headers }
        );
        return Math.floor(response.data.data.result * 100) / 100;
      } catch (error) {
        return null;
      }
    },
    async updateAmountForexUSD() {
      this.isLoadingCalculatorUsd = true;
      this.amountForexMXN = await this.calculatorAmount(
        this.amountForexUSD,
        this.exchangeRateTodayReference,
        false
      );
      this.amountForexRealUSD = this.amountForexUSD;
      this.forexReverse = false;
      this.isLoadingCalculatorUsd = false;
      this.isLoadingCalculatorMxn = false;
    },
    async updateAmountForexMXN() {
      this.isLoadingCalculatorMxn = true;
      this.amountForexUSD = await this.calculatorAmount(
        this.amountForexMXN,
        this.exchangeRateTodayReference,
        true
      );
      this.amountForexRealUSD = this.amountForexMXN;
      this.forexReverse = true;
      this.isLoadingCalculatorMxn = false;
      this.isLoadingCalculatorUsd = false;
    },
    updateDateForward() {
      if (this.amountForwardUSD > 0 || this.amountForwardMXN > 0) {
        this.forwardRate = this.getForwardRate(this.dDateForward, false);
      }
    },
    async updateAmountForwardUSD() {
      this.isLoadingCalculatorUsd = true;

      try {
        this.forwardRateResponse = await this.getForwardRate(
          this.amountForwardUSD,
          false
        );
        this.forwardRate.uuid = this.forwardRateResponse.uuid;
        this.forwardRate.rate = this.forwardRateResponse.rate
          .toString()
          .match(/^-?\d+(?:\.\d{0,2})?/)[0];
        this.forwardRate.initial_percentage_amount = this.forwardRateResponse.initial_percentage_amount
          .toString()
          .match(/^-?\d+(?:\.\d{0,2})?/)[0];
        this.amountForwardUSD = this.forwardRateResponse.amount
          .toString()
          .match(/^-?\d+(?:\.\d{0,2})?/)[0];
        this.amountForwardMXN = this.forwardRateResponse.des_amount
          .toString()
          .match(/^-?\d+(?:\.\d{0,2})?/)[0];
      } catch (error) {
        console.error("Error in update amount forward:", error);
      } finally {
        this.isLoadingCalculatorUsd = false;
      }
    },
    async updateAmountForwardMXN() {
      this.isLoadingCalculatorMxn = true;
      try {
        this.forwardRateResponse = await this.getForwardRate(this.amountForwardMXN, true);
        this.forwardRate.uuid = this.forwardRateResponse.uuid;
        this.forwardRate.rate = this.forwardRateResponse.rate
          .toString()
          .match(/^-?\d+(?:\.\d{0,2})?/)[0];
        this.forwardRate.initial_percentage_amount = this.forwardRateResponse.initial_percentage_amount
          .toString()
          .match(/^-?\d+(?:\.\d{0,2})?/)[0];
        this.amountForwardUSD = this.forwardRateResponse.amount
          .toString()
          .match(/^-?\d+(?:\.\d{0,2})?/)[0];
        this.amountForwardMXN = this.forwardRateResponse.des_amount
          .toString()
          .match(/^-?\d+(?:\.\d{0,2})?/)[0];
      } catch (error) {
        console.error("Error in update amount forward:", error);
      } finally {
        this.isLoadingCalculatorMxn = false;
      }
    },
    sendVerificationWithdrawal() {
      this.e1 = 4;
      try {
        DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        })
          .then((response) => {
            if (!response.data.success) {
              this.mixError(response.data.message, response.status);
            }
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } catch (error) {
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    sendVerificationSuppliers() {
      this.e1 = 5;
      try {
        DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        })
          .then((response) => {
            if (!response.data.success) {
              this.mixError(response.data.message, response.status);
            }
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } catch (error) {
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    sendVerification() {
      this.e1 = 4;
      try {
        DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        })
          .then((response) => {
            if (!response.data.success) {
              this.mixError(response.data.message, response.status);
            }
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } catch (error) {
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    sendVerificationForex() {
      this.e1 = 2;
      try {
        DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        })
          .then((response) => {
            if (!response.data.success) {
              this.mixError(response.data.message, response.status);
            }
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } catch (error) {
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    sendVerificationForward() {
      this.e1 = 3;
      try {
        DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        })
          .then((response) => {
            if (!response.data.success) {
              this.mixError(response.data.message, response.status);
            }
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } catch (error) {
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    async validateOtp() {
      try {
        const payload = {
          sCode: this.otp,
        };

        const response = await DB.post(
          `${URI}/api/v1/${this.selectLanguage}/authenticator/verify`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : "",
            },
          }
        );

        return response.data.success;
      } catch (error) {
        return false;
      }
    },
    async sendForex() {
      this.loading = true;

      const isOtpValid = await this.validateOtp();

      if (!isOtpValid) {
        this.loading = false;
        this.mixError("Invalid code", 400);
        return;
      }

      const payload = {
        sTransactionType: "e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a",
        dAmount: parseFloat(this.amountForexRealUSD),
        dExchangeRate: this.exchangeRateToday,
        referenceExchangeRate: this.exchangeRateTodayReference,
        bExternal: false,
        reverse: this.forexReverse,
        dAmountUsd: parseFloat(this.amountForexUSD),
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/transactions/`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          if (!response.data.success) {
            this.mixError(response.data.message);
            this.loading = false;
          } else {
            this.mixSuccess(response.data.message);
            this.cancel();
            this.getDashboard();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    async sendForward() {
      this.loading = true;

      const isOtpValid = await this.validateOtp();

      if (!isOtpValid) {
        this.loading = false;
        this.mixError("Invalid code", 400);
        return;
      }

      const payload = {
        sTransactionType: "b80e8dc6-4138-449e-8903-968efb8437b3",
        dAmount: parseFloat(this.amountForwardMXN),
        dExchangeRate: this.forwardRate.rate,
        referenceExchangeRate: this.forwardRate.uuid,
        bExternal: false,
        dForwardTransactionDate: this.dDateForward,
        dPercentageAmount: this.forwardRate.initial_percentage_amount,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/transactions/`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          if (!response.data.success) {
            this.mixError(response.data.message, response.status);
            this.loading = false;
            return;
          }
          this.mixSuccess(response.data.message);
          this.cancel();
          this.getDashboard();
        })
        .catch((error) => {
          this.loading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    async getForwardRate(amount, reverse) {
      const payload = {
        dDateForward: this.dDateForward,
        dAmount: amount,
        referenceExchangeRate: this.exchangeRateTodayReference,
        bExternal: false,
        reverse: reverse,
      };

      try {
        const response = await DB.post(
          `${URI}/api/v1/${this.selectLanguage}/transactions/forwardRate`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : "",
            },
          }
        );
        return response.data.forwardRate;
      } catch (error) {
        this.mixError(
          error.response?.data?.message || "Error desconocido",
          error.response?.status || 500
        );
        throw error;
      }
    },
    cancel() {
      this.otp = "";
      this.step = 1;
      this.selectedCard = null;
      this.e1 = 1;
      this.getDashboard();
      this.loading = false;
      this.dialogForex = false;
      this.dialogSendPay = false;
      this.dialogSendPaySuppliers = false;
      this.dialogWithdrawal = false;
      this.dialogForward = false;
      this.dialogMassivePayments = false;
      this.exchangeRateToday = 0;
      this.amountForexUSD = 0;
      this.amountForexMXN = 0;
      this.forwardRate = {
        uuid: null,
        rate: null,
        initial_percentage_amount: null,
      };
      this.amountForwardUSD = 0;
      this.amountForwardMXN = 0;
      this.dDateForward = null;
      this.suppliers = [];
      this.selected = null;
    },
    getDashboard() {
      this.loading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/dashboard`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.loading = false;
          this.startCountdownDashboard();
          this.items = response.data;
          this.balanceUsd = response.data.balances.balanceUsd.balance;
          this.balanceMxn = response.data.balances.balanceMxn.balance;
          this.blockedUsd = response.data.balances.blocked.balance;
          this.exchangeRateTodayReference = this.items.exchangeRateTodayReference;
        })
        .catch((error) => {
          if (error.response.data.code) {
            this.$router.push("/welcome").catch(() => {});
          }
          // this.isLoadingSuppliers = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    updateDialogForex() {
      this.startCountdown();
      this.getExchangeRate();
      this.amountForexMXN = 0;
      this.amountForexRealUSD = 0;
      this.amountForexUSD = 0;
    },
    openDialogForex() {
      this.dialogForex = true;
      this.startCountdown();
      this.getExchangeRate();
    },
    getExchangeRate() {
      this.loadingExchange = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/dashboard`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      }).then((response) => {
        this.loadingExchange = false;
        this.exchangeRateTodayFullNumbers = response.data.exchangeRateToday;
        this.exchangeRateToday = Math.round(response.data.exchangeRateToday * 100) / 100;
        this.exchangeRateTodayReference = response.data.exchangeRateTodayReference;
      });
    },
    getInvoices() {
      this.isLoading = true;
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/invoices/?exchangeRateToday=${this.actualCurrency}`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      ).then((response) => {
        this.invoices = response.data.invoices;
      });
    },
    getTransactions() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      }).then((response) => {
        this.transactions = response.data.transactions;
      });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let truncated = Math.trunc(multiplied);
      let formattedNumber = truncated / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(formattedNumber);
    },
    getContacts: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/contacts-wallet/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.contacts = response.data.contacts;
          this.contacts.push({
            id: 0,
            name: "Crear nuevo contacto",
          });
        })
        .catch((error) => {
          // Handle error if needed
        });
    },
    openDialogForward() {
      this.dialogForward = true;
      this.dDateForward = null;
      this.e1 = 1;
    },
    openModalSendPaySuppliers() {
      this.e1 = 1;
      this.dialogSendPaySuppliers = true;
      this.getSuppliers();
    },
    openModalSendPay() {
      this.e1 = 1;
      this.dialogSendPay = true;
      this.getSuppliers();
    },
    openModalWithdrawal() {
      this.e1 = 1;
      this.dialogWithdrawal = true;
      this.getSupplier();
    },
    getSuppliers() {
      this.isLoadingSuppliers = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/payment-recipient/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.isLoadingSuppliers = false;
          this.suppliers = response.data.paymentRecipients;
          this.suppliers.map((item) => {
            item.sNameAC = item.sName + " - *******" + item.sAccountNumber.slice(-4);
          });

          // if (this.suppliers.length == 1) {
          //   this.selected = this.suppliers[0]
          //   this.sAccountNumber = this.suppliers[0].sAccountNumber.slice(-4);
          // }
        })
        .catch((error) => {
          this.isLoadingSuppliers = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getSupplier() {
      this.isLoadingSuppliers = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/payment-recipient/company`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.isLoadingSuppliers = false;
          this.suppliers = response.data.paymentRecipients;
          this.suppliers.map((item) => {
            item.sNameAC = item.sName + " - *******" + item.sAccountNumber.slice(-4);
          });

          if (this.suppliers.length == 1) {
            this.selected = this.suppliers[0];
            this.sAccountNumber = this.suppliers[0].sAccountNumber.slice(-4);
          }
        })
        .catch((error) => {
          this.isLoadingSuppliers = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectSupplier(item) {
      this.supplier = item;
      this.sAccountNumber = this.supplier.sAccountNumber.slice(-4);
    },
    getSendPayNowWithdrawal() {
      this.e1 = 2;
      this.comment = null;
      this.getExchangeRate();
      this.amountForexMXN = 0;
      this.amountForexRealUSD = 0;
      this.amountForexUSD = 0;
      this.startCountdownToPayNow();
    },
    getSendPayNow() {
      this.e1 = 2;
      this.comment = null;
      this.getExchangeRate();
      this.amountForexMXN = 0;
      this.amountForexRealUSD = 0;
      this.amountForexUSD = 0;
      this.startCountdownToPayNow();
    },
    getSendPayNowSuppliers() {
      this.e1 = 3;
      this.comment = null;
      this.getExchangeRate();
      this.amountForexMXN = 0;
      this.amountForexRealUSD = 0;
      this.amountForexUSD = 0;
      this.startCountdownToPayNowSuppliers();
    },
    async sendWithdrawal() {
      this.loading = true;

      const isValid = await this.validateOtp();
      if (!isValid) {
        this.mixError("Código OTP incorrecto", 400);
        this.loading = false;
        return;
      }

      const payload = {
        dAmount: parseFloat(this.amountForexRealUSD),
        comment: this.comment,
        sExchangeRateReference: this.exchangeRateTodayReference,
        reverse: this.forexReverse,
      };

      try {
        const response = await DB.post(
          `${URI}/api/v1/${this.selectLanguage}/billings/sendWithdrawal`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : "",
            },
          }
        );
        if (!response.data.success) {
          this.mixError(response.data.message, response.status);
          this.loading = false;
          return;
        }
        this.mixSuccess(response.data.message);
        this.cancel();
        this.getDashboard();
      } catch (error) {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    async sendWithdrawalMX() {
      this.loading = true;

      const isValid = await this.validateOtp();
      if (!isValid) {
        this.mixError("Código OTP incorrecto", 400);
        this.loading = false;
        return;
      }

      const payload = {
        dAmount: parseFloat(this.amountForexMXN),
        comment: this.comment,
      };

      try {
        const response = await DB.post(
          `${URI}/api/v1/${this.selectLanguage}/billings/sendWithdrawalMX`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : "",
            },
          }
        );
        if (!response.data.success) {
          this.mixError(response.data.message, response.status);
          this.loading = false;
          return;
        }
        this.mixSuccess(response.data.message);
        this.cancel();
        this.getDashboard();
      } catch (error) {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    async sendPayNowSupplier() {
      this.loading = true;

      const isValid = await this.validateOtp();
      if (!isValid) {
        this.mixError("Código OTP incorrecto", 400);
        this.loading = false;
        return;
      }

      const payload = {
        dAmount: parseFloat(this.amountForexRealUSD),
        comment: this.comment,
        sPaymentRecipientId: this.supplier.sPaymentRecipientId,
        sExchangeRateReference: this.exchangeRateTodayReference,
        reverse: this.forexReverse,
        dAmountUsd: parseFloat(this.amountForexUSD),
      };

      try {
        const response = await DB.post(
          `${URI}/api/v1/${this.selectLanguage}/billings/sendPayNow`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : "",
            },
          }
        );
        if (!response.data.success) {
          this.mixError(response.data.message, response.status);
          this.loading = false;
          return;
        }
        this.mixSuccess(response.data.message);
        this.cancel();
        this.getDashboard();
      } catch (error) {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    async sendPayNowMX() {
      this.loading = true;

      const isValid = await this.validateOtp();
      if (!isValid) {
        this.mixError("Código OTP incorrecto", 400);
        this.loading = false;
        return;
      }

      const payload = {
        dAmount: parseFloat(this.amountForexMXN),
        comment: this.comment,
        sPaymentRecipientId: this.supplier.sPaymentRecipientId,
      };

      try {
        const response = await DB.post(
          `${URI}/api/v1/${this.selectLanguage}/billings/sendPayNowMX`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : "",
            },
          }
        );
        if (!response.data.success) {
          this.mixError(response.data.message, response.status);
          this.loading = false;
          return;
        }
        this.mixSuccess(response.data.message);
        this.cancel();
        this.getDashboard();
      } catch (error) {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    async sendPayNow() {
      this.loading = true;
      const isValid = await this.validateOtp();
      if (!isValid) {
        this.mixError("Código OTP incorrecto", 400);
        this.loading = false;
        return;
      }

      const payload = {
        dAmount: parseFloat(this.amountForexRealUSD),
        comment: this.comment,
        sPaymentRecipientId: this.supplier.sPaymentRecipientId,
        sExchangeRateReference: this.exchangeRateTodayReference,
        reverse: this.forexReverse,
        dAmountUsd: parseFloat(this.amountForexUSD),
      };

      try {
        const response = await DB.post(
          `${URI}/api/v1/${this.selectLanguage}/payments/sendPayNow`,
          payload,
          {
            headers: {
              Authorization: this.$store.state.sToken
                ? `Bearer ${this.$store.state.sToken}`
                : "",
            },
          }
        );
        if (!response.data.success) {
          this.mixError(response.data.message, response.status);
          this.loading = false;
          return;
        }
        this.mixSuccess(response.data.message);
        this.cancel();
        this.getDashboard();
      } catch (error) {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    startCountdownToPayNow() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.countdownPayNow = 59;
      this.intervalId = setInterval(() => {
        if (this.countdownPayNow > 0) {
          this.countdownPayNow--;
        } else {
          clearInterval(this.intervalId);
          if (this.e1 == 2) {
            this.getSendPayNow();
          }
        }
      }, 1000);
    },
    startCountdownToPayNowSuppliers() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.countdownPayNow = 59;
      this.intervalId = setInterval(() => {
        if (this.countdownPayNow > 0) {
          this.countdownPayNow--;
        } else {
          clearInterval(this.intervalId);
          if (this.e1 == 3) {
            this.getSendPayNowSuppliers();
          }
        }
      }, 1000);
    },
    startCountdownDashboard() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.countdownDashboard = 59;
      this.intervalId = setInterval(() => {
        if (this.countdownDashboard > 0) {
          this.countdownDashboard--;
        } else {
          clearInterval(this.intervalId);
          if (
            this.dialogForex == false &&
            this.dialogSendPay == false &&
            this.dialogForward == false &&
            this.dialogMassivePayments == false
          ) {
            this.getDashboard();
            this.checkTime();
          }
        }
      }, 1000);
    },
    openModalAddFoundss() {
      this.dialogAddFoundss = true;
      const payload = {
        amount: 100,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/exchange-rate/addFounds`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.dataAddFounds = response.data.account;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    closeModalAddFounds() {
      this.dialogAddFounds = false;
    },
    toggleAll: function (checked) {
      this.exchangeRateData.forEach((item) => {
        if (
          item.statusName !== "WAITING_FOR_DEPOSIT" ||
          item.statusName !== "PROCESSING"
        ) {
          item.bSelected = checked;
        }
      });
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
    // formatMoney: function (money) {
    //   return FORMAT_MONEY(money);
    // },
    resetForm() {
      this.editableData = {
        dPurchaseDate: "",
        sProvider: "",
        sInvoiceNumber: "",
        dTotalAmount: "",
        iCreditPeriod: "",
        dInvoiceExchangeRateProtection: "",
      };
    },
    openModalCreate() {
      this.dialogCreate = true;
      this.resetForm();
    },
    validateForm: function () {
      return true;
    },
    saveChanges: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(this.editableData.dPurchaseDate);

      const payload = {
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        createContact: this.showCreateProvider,
        contactData: this.contactData,
        dInvoiceExchangeRateProtection: this.editableData.dInvoiceExchangeRateProtection,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/exchange-rate/`, payload, config)
        .then((response) => {
          this.bLoading = false;
          this.dialogCreate = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatDateToSend: function (date) {
      let newDate = new Date(date);
      let day = newDate.getDate() + 1;
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },
    changebSelectedAll: function () {
      this.bSelectedAll = !this.bSelectedAll;
    },
    closeModalCreate() {
      this.dialogCreate = false;
      this.showCreateProvider = false;
      this.contactData = {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY",
      };
    },
    sendToPayNow: function () {
      this.isDeferredPayment = false;
      this.sendToPay();
    },
    sendToPay: function () {
      this.itemsSelected = this.exchangeRateData.filter((item) => item.bSelected);
      if (this.itemsSelected.length > 0) {
        this.stepper = true;
        this.itemsSelected.forEach((item) => {
          item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          this.totalUsd += item.dTotalUsdToday;
        });
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    actualizarE1(nuevoValor) {
      this.e1 = nuevoValor;
    },
    closeModalPay() {
      this.dialogPay = false;
      this.totalUsd = 0;
      this.e1 = 1;
    },
    sendToPayNowMxn: function () {
      this.isDeferredPayment = false;
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRateDataSeparate.filter((item) => item.bSelected);
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.dialogPay = true;
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    sendToPayNowForwards: function () {
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRatesStateForwards.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.sendPayEfex();
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    closeModalStepper() {
      this.stepper = false;
      this.totalUsd = 0;
      this.e1 = 1;
      this.isForwards = false;
      this.isForwardsPayment = false;
      //this.getActualCurrency();
    },
    changeDeferredPayment() {
      this.isDeferredPayment = !this.isDeferredPayment;
    },
    changePayMxn() {
      this.isPayMxn = !this.isPayMxn;
    },
    sendPayEfex: function () {
      this.bLoading = true;

      if (this.isPayMxn) {
        this.totalUsd = this.totalUsd * this.actualCurrency;
      }

      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        actualCurrency: this.actualCurrency,
        isDeferredPayment: this.isDeferredPayment,
        isPayMxn: this.isPayMxn,
        totalUsd: this.totalUsd,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayment/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogPay = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.data.message, error.response.status);
        });
    },
    openReceipt: function (item) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getPaymentRecipt/${item.paymentExchangeRate[0].sPayment_reference}/`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (response.data && response.data.payment) {
            let base64 = response.data.payment.data;

            const base64Pattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
            if (base64Pattern.test(base64)) {
              base64 = base64.split(",")[1];
            }

            try {
              const binaryString = window.atob(base64);
              const bytes = new Uint8Array(binaryString.length);
              for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              const blob = new Blob([bytes], { type: "application/pdf" });

              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
            } catch (e) {}
          } else {
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendSeparateEfex: function () {
      this.bLoading = true;
      const payload = {
        exchangeRateId: this.itemsSelected,
        rateUuid: this.referenceCurrency,
        rateInt: this.actualCurrency,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/separateExchangeRate/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogSeparate = false;
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    toggleAllSeparated: function (checked) {
      this.exchangeRateDataSeparate.forEach((item) => {
        item.bSelected = checked;
      });
    },
    toggleAllForwards: function (checked) {
      this.exchangeRatesStateForwards.forEach((item) => {
        this.$set(item, "bSelected", checked);
      });
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/sub-account/`, payload, config)
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openModal(item) {
      this.totalUsd = 0;
      this.selectedItem = item;
      this.editableData = { ...this.selectedItem };
      this.dialogEditProfileActive = true;
    },
    openModalAlerts() {
        this.dialogAlerts = true;
        this.isLoadingAlerts = true;
        //Get alerts
        DB.get(`${URI}/api/v1/${this.selectLanguage}/alerts/`, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        })
        .then((response) => {
          this.alerts = response.data.alerts;
          this.isLoadingAlerts = false;
        })
        .catch((error) => {
          this.isLoadingAlerts = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    async deleteAlert(item) {
      this.isLoadingAlertsDelete = true;
      const payload = {
        sAlertId: item.sAlertId
      };
      const response = await DB.post(`${URI}/api/v1/${this.selectLanguage}/alerts/deleteAlert`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      });

      if (response.data.success) {
        this.mixSuccess(response.data.message);
        this.alerts = this.alerts.filter(alert => alert.sAlertId !== item.sAlertId);
      } else {
        this.mixError(response.data.message, 400);
      }
      this.isLoadingAlertsDelete = false;
    },
    openDialogAlertsCreate() {
      this.dialogAlertsCreate = true;
    },
    closeDialogAlerts() {
      this.dialogAlerts = false;
      this.isLoadingAlerts = false;
      this.alerts = [];
    },
    closeDialogAlertsCreate() {
      this.dialogAlertsCreate = false;
      this.targetPrice = null;
      this.isLoadingAlertsCreate = false;
    },
    createAlert() {
      this.isLoadingAlertsCreate = true;
      const payload = {
        dAmount: this.targetPrice,
        dAmountSpot: this.items.exchangeRateToday,
        sType: "8e574e88-2670-469e-b96e-7091082d82b9"
      };
      DB.post(`${URI}/api/v1/${this.selectLanguage}/alerts`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      })
      .then((response) => {
        if (response.data.success) {
          this.mixSuccess(response.data.message);
          this.isLoadingAlertsCreate = false;
          this.dialogAlertsCreate = false;
          this.targetPrice = null;
          this.openModalAlerts();
      } else {
          this.mixError(response.data.message, 400);
        }
        this.isLoadingAlertsCreate = false;
      })
      .catch((error) => {
        this.isLoadingAlertsCreate = false;
        this.mixError(error.response.data.message, error.response.status);
      });
    },
    closeDialog() {
      this.dialogEditProfileActive = false;
    },
    backStep() {
      this.e1 = 1;
    },
    updateExchangeRate: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(this.editableData.dPurchaseDate);
      const payload = {
        sExchangeRateId: this.selectedItem.sExchangeRateId,
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        dInvoiceExchangeRateProtection: this.editableData.dInvoiceExchangeRateProtection,
        dAmountAdjustment: parseFloat(this.editableData.dAmountAdjustment),
        sCurrencyAdjustment: "MXN",
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/${this.selectedItem.sExchangeRateId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogEditProfileActive = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeForwards: function (value) {
      this.isForwards = value;
    },
    getForwards: function () {
      this.bLoading = true;
      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        reverse: true,
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getRateForwards/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
            this.bLoading = false;
            this.isForwardsPayment = false;
          } else {
            this.mixSuccess(response.data.message);
            this.bLoading = false;
            this.isForwardsPayment = true;
            this.isForwardRateCalculated = response.data.isForwardRateCalculated;
            this.itemsSelected = response.data.responsePaysEfex;
          }
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.isForwardsPayment = false;
          this.mixError(error.data.message, 400);
        });
    },
    sendForwardPayment: function () {
      const payload = {
        pays: this.itemsSelected,
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayForward/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.stepper = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isForwardsPayment = false;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeModalCurrency: function (value) {
      this.modalCurrency = value;
    },
    updateTotalUsd: function (value) {
      this.totalUsd = value;
    },
    onlyWeekdays(date) {
      const day = new Date(date).getDay();
      return day !== 5 && day !== 6;
    },
    downloadXlsx() {
      const link = document.createElement("a");
      link.href = "/template-bulks-mx.xlsx";
      link.download = "template-bulks-mx.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    openMassivePayments() {
      this.dialogMassivePayments = true;
    },
    async handleFileUpload(event) {
      const file = this.file;
      const reader = new FileReader();

      this.jsonData = [];
      if (file) {
        // Definir el manejador del evento onload
        reader.onload = (e) => {
          try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Ajuste el header según tus necesidades

            const filteredJson = json.slice(12);

            // Filtrar los elementos vacíos del array
            this.jsonData = filteredJson.filter((row) =>
              Object.values(row).some((value) => value && value.length > 0)
            );

            const headers = [
              "sPaymentRecipient",
              "sEmail",
              "dAmount",
              "sRfc",
              "sBankName",
              "sAccountNumber",
              "sComments",
            ];

            this.jsonData = this.jsonData.map((row) => {
              const obj = {};
              headers.forEach((header, index) => {
                obj[header] = row[index];
              });
              return obj;
            });
          } catch (error) {
            console.error("Error al procesar el archivo:", error);
          }
        };

        // Definir el manejador del evento onerror
        reader.onerror = (e) => {
          console.error("Error al leer el archivo:", e.target.error);
        };

        // Leer el archivo como ArrayBuffer
        reader.readAsArrayBuffer(file);
        // reader.readAsArrayBuffer(file);
      } else {
        // this.results = [];
      }
    },
    checkTime() {
      this.currentTime = this.currentDate.format("MMMM DD, YYYY, HH:mm:ss [GMT]Z");
      this.currentTimeCdmx = this.currentDateCdmx.format(
        "MMMM DD, YYYY, HH:mm:ss [GMT-6]"
      );
      const currentHourCdmx = this.currentDateCdmx.hour();
      const currentDayCdmx = this.currentDateCdmx.day();
      if (currentDayCdmx === 6 || currentDayCdmx === 0) {
        this.isDisabledForward = true;
      } else if (currentHourCdmx >= 9 && currentHourCdmx < 16) {
        this.isDisabledForward = false;
      } else {
        this.isDisabledForward = true;
      }
    },
    getInvoices() {
      this.isLoading = true;
      this.selected = [];
      const payload = {
        sStatusId: "8e99628b-317c-4569-b149-9dc0105503a5",
        sId: this.selectedId,
      };
      DB.post(`${URI}/api/v1/${this.selectLanguage}/invoicesMx/invoices`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.invoicesLength = response.data.invoices.length;
        })
        .catch((error) => {
          this.isLoadingSuppliers = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getDashboard();
    this.sTypeAccount = this.$store.state.sTypeAccount;
    if (this.sTypeAccount == "EFEXMXN") {
      this.getInvoices();
    }
    this.paymentDay = moment().format("YYYY-MM-DD");
    this.today = moment().add(1, "day").format("YYYY-MM-DD");
    this.limit = moment(this.today).add(1, "year").format("YYYY-MM-DD");
    this.checkTime();
    this.startCountdownDashboard();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
    customHint() {
      return `Usted solo puede convertir $${
        this.items ? this.formatMoney(this.balanceUsd) : 0
      } USD`;
    },
    customHintMXN() {
      return `Usted solo puede convertir $${
        this.items ? this.formatMoney(this.balanceMXN) : 0
      } MXN`;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    },
    clockTime(currentTime) {
      var ms = moment(this.lastUpdateclockTime, "HH:mm:ss").diff(
        moment(currentTime, "HH:mm:ss")
      );
      var d = moment.duration(ms);
    },
  },
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
    color: yellow !important;
    background-color: yellow !important;
  }

  .active-section .v-list-item__title {
    background-color: yellow !important;
  } */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #f0be43 !important;
  height: 3px;
  color: #f0be43 !important;
  border-radius: 100px;
}

.card-main {
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #f0be43;
}

 /* .btn-custom-alerts {
  color: black;
  font-size: 10px;
  background-color: #FFF;
} */

.custom-dialog-alerts {
  background-color: red; /* Cambia al color deseado */
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #f0be43;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #0075b8;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}
.scroll::-webkit-scrollbar {
  display: none;  /* Oculta la barra de desplazamiento en navegadores basados en WebKit */
}
.toggle-container {
  display: flex;
  border: 4px solid #3578ba;
  border-radius: 100px;
  overflow: hidden;
  background-color: #3578ba;
}

.toggle-button {
  flex: 1;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.toggle-button.active {
  background-color: #ffffff;
  color: #3578ba;
  border-radius: 20px;
}

.toggle-button:not(.active) {
  background-color: #3578ba;
  color: white;
}

.v-btn--outlined {
  border: 2px solid currentColor;
}

</style>
