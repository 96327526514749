<template>
  <div class="pt-4">
    
    <v-container fluid class="px-15">
      <v-row class="ma-0z mb-5">
        <v-col class="ma-4" cols="4">
          <v-col cols="12" class="pa-0">
            <div class="headline-medium text-primary">{{ texts.invoices.title }}</div>
          </v-col>
          <v-col cols="12" class="pa-0">
            <div class="body-large text-secondary">{{ texts.invoices.subTitle }}</div>
          </v-col>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="display: flex; justify-content: flex-end;">
          <v-card v-if="selectedStatus === 0" class="card-main mx-3 my-3" style="border: 1px solid #C5CDD3;" width="350px" elevation="0" outlined>
            <v-card-text class="pt-6">
              <v-row align="center">
                <v-col cols="9" class="pl-5">
                  <div class="usd-balance">{{ texts.dashboard.usdBalance }}</div>
                  <div class="content-balance">
                    ${{ formatMoney(balanceUsd ? balanceUsd : 0) }} USD
                  </div>
                </v-col>
                <v-col cols="3" class="text-right pr-5">
                  <v-img
                    src="../../assets/images/Flags_usa.png"
                    alt="usa-flag"
                    width="50"
                    height="30"
                  />
                </v-col>
              </v-row>
              <div class="pl-3 pt-2 blocked-amount">
                {{ texts.dashboard.blockedAmount }}
                <span class="blocked-amount-2">${{ formatMoney(blockedUsd ? blockedUsd : 0) }}</span>
              </div>
            </v-card-text>
          </v-card>
          <v-card v-if="selectedStatus === 1" class="card-main mx-3 my-3" style="border: 1px solid #C5CDD3;" width="350px" elevation="0" outlined>
          <v-card-text class="pt-6">
            <v-row align="center">
              <v-col cols="9" class="pl-5">
                <div class="usd-balance">{{ texts.dashboard.mxnBalance }}</div>
                <div class="content-balance pt-3"> 
                  ${{ formatMoney(balanceMxn ? balanceMxn : 0) }} MXN
                </div>
              </v-col>
              <v-col cols="3" class="text-right pr-5">
                <v-img
                  src="../../assets/images/Flags_mex.png"
                  alt="mex-flag"
                  width="50"
                  height="30"
                />
              </v-col>
            </v-row>
            <div class="pl-3 pt-2 blocked-amount">
              {{ texts.conversions.assigned + ": " }}
              <span class="blocked-amount-2">${{ formatMoney(totalAmountMxn ? totalAmountMxn : 0) }}</span>
            </div>
          </v-card-text>
        </v-card>
        </v-col>
        <v-spacer v-if="selectedStatus === 0"></v-spacer>
        <v-col v-if="selectedStatus === 0">
          <v-card class="card-main mx-3 my-3" style="border: 1px solid #C5CDD3;" width="350px" elevation="0" outlined>
            <v-card-text class="pt-6">
              <v-row align="center">
                <v-col cols="9" class="pl-5">
                  <div class="usd-balance">{{ texts.invoices.exchangeRateActual }}</div> 
                  <div class="content-balance" style="color: #193D6E; font-weight: bold">
                    {{
                      formatMoney(items.exchangeRateToday ? items.exchangeRateToday : 0)
                    }}
                    MXN
                  </div>
                </v-col>
              </v-row>
              <div class="pl-3 pt-2 blocked-amount" style="position: relative; z-index: 10; padding-right: 85px;">
                {{ texts.invoices.exchangeRateTodayDescription }} {{ countdownDashboard }} {{ texts.invoices.seconds }}
              </div>
            </v-card-text>
            <div
              style="
                position: absolute;
                top: 0;
                right: 0;
                margin-top: -12px;
                margin-right: -36px;
                background-color: #193D6E;
                width: 150px;
                height: 150px;
                border-radius: 100px;
              "
            ></div>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="rounded-xl elevation-5">
        <v-toolbar dense dark color="#142e53" elevation="2">
          <v-toolbar-title>
            <v-tabs v-model="selectedStatus" @change="getInvoices()" background-color="transparent" color="#FFFF" show-arrows>
              <v-tab
                class="global-tab"
                v-for="item in status"
                :key="item.sStatusId"
                :value="item.sStatusId"
              >
                {{ item.sName }}
              </v-tab>
              <v-tabs-slider></v-tabs-slider>
            </v-tabs>
          </v-toolbar-title> 
          <v-spacer></v-spacer>
          <v-btn text small @click="dataXLSX()">
            <h4 style="font-family: 'montserrat-bold';">{{ texts.invoices.download }}</h4>
            <v-icon right>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <div style="display: flex; flex-direction: row;">
            <v-autocomplete
              :label="texts.invoices.selectSupplier"
              :items="suppliers"
              hide-details
              outlined
              solo
              clearable
              rounded
              dense
              class="pl-3"
              item-value="sPaymentRecipientId"
              item-text="sName"
              @change="getInvoices()"
              v-model="selectedSupplier"
            ></v-autocomplete>
            <v-text-field v-model="search" class="pl-3" :label="texts.invoices.selectId" append-icon="mdi-magnify" outlined dense solo rounded hide-details variant="underlined"></v-text-field>
          </div>
         <v-spacer></v-spacer>
          <v-btn
            style="
              text-align: center;
              color: #193D6E;
              font-size: 15px;
              background-color: transparent;
              font-family: montserrat-bold;
              font-weight: 600;
              line-height: 24px;
              text-transform: capitalize;
              word-wrap: break-word;
              border-radius: 5px;
              border: 1px solid #193D6E;
              padding: 8px 16px;
            "
            elevation="0"
            class="ml-2"
            @click="openDialogSupplier()"
          >
            <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
            {{ texts.invoices.addInvoice }}
          </v-btn>
          <v-btn
            style="
              text-align: center;
              color: #193D6E;
              font-size: 15px;
              background-color: transparent;
              font-family: montserrat-bold;
              font-weight: 600;
              line-height: 24px;
              text-transform: capitalize;
              word-wrap: break-word;
              border-radius: 5px;
              border: 1px solid #193D6E;
              padding: 8px 16px;
            "
            elevation="0"
            class="ml-2"
            @click="openPayNow(selected)"
            :disabled="selected.length === 0"
            v-if="selectedStatus === 0"
          >
            <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
            {{ texts.invoices.pay }}
          </v-btn>

          <v-btn
            style="
              text-align: center;
              color: #193D6E;
              font-size: 15px;
              background-color: transparent;
              font-family: montserrat-bold;
              font-weight: 600;
              line-height: 24px;
              text-transform: capitalize;
              word-wrap: break-word;
              border-radius: 5px;
              border: 1px solid #193D6E;
              padding: 8px 16px;
            "
            elevation="0"
            class="ml-2"
            @click="openPayNowMxn(selected)"
            :disabled="selected.length === 0"
            v-if="selectedStatus === 1"
          >
            <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
            {{ texts.invoices.pay }}
          </v-btn>

          <v-btn
            style="
              text-align: center;
              color: #193D6E;
              font-size: 15px;
              background-color: transparent;
              font-family: montserrat-bold;
              font-weight: 600;
              line-height: 24px;
              text-transform: capitalize;
              word-wrap: break-word;
              border-radius: 5px;
              border: 1px solid #193D6E;
              padding: 8px 16px;
            "
            elevation="0"
            class="ml-2"
            :disabled="selected.length === 0"
            @click="openConvert(selected)"
            v-if="selectedStatus === 0"
          >
            <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
            {{ texts.invoices.convert }}
          </v-btn>
        </v-card-title>
        <v-card-title class="mb-4" style="display: flex; justify-content: flex-end; font-family: 'montserrat-bold';">
          <div style="background: #FFFFFF; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); border-radius: 10px; padding: 15px; border: 1px solid #C5CDD3;">
            {{ texts.invoices.totalCurrencyGain }}
            <span class="ml-2" style="font-weight: bold;" :style="{ color: items.totalProfit < 0 ? 'red' : 'green'}"> 
              {{ " $" + formatMoney(items.totalProfit) + " USD" }}
            </span>
          </div>
        </v-card-title>
        <v-tabs-items class="global-item-tab mt-5" v-model="selectedStatus">
          <v-tab-item>
            <v-card-text>
            <v-data-table
              :search="search"
              show-select
              single-select
              item-key="sInvoiceId"
              v-model="selected"
              :headers="headersInvoices"
              :items="invoices"
            >
              <template v-slot:item.data-table-select="{ item, select }">
                <v-checkbox
                  :input-value="isSelected(item)"
                  @click="toggleSelect(item, select)"
                  :disabled="item.sStatusId != '453f47d8-d5ad-4159-be49-b48b0f04caa5'"
                ></v-checkbox>
              </template>
              <template v-slot:item.sName="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">{{ item.sName }}</h4>
              </template>
              <template v-slot:item.dDueDate="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">
                  {{ formatDate(item.dDueDate) }}
                </h4>
              </template>
              <template v-slot:item.dPurchaseDate="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">
                  {{ formatDate(item.dPurchaseDate) }}
                </h4>
              </template>
              <template v-slot:item.sInvoice="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">{{ item.sInvoice }}</h4>
              </template>
              <template v-slot:item.sId="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">{{ item.sId }}</h4>
              </template>
              <template v-slot:item.dTotalAmount="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  $ {{ formatMoney(item.dTotalAmount) }} MXN
                </h4>
              </template>
              <template v-slot:item.dRateProtection="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  $ {{ formatMoney(item.dRateProtection) }} MXN
                </h4>
              </template>
              <template v-slot:item.dProfitPercentage="{ item }">
                <div v-if="isLoading" class="d-flex justify-center align-center">
                  <v-skeleton-loader
                      class="mx-3 my-1"
                      height="20px"
                      width="100px"
                      type="text"
                  ></v-skeleton-loader>
                </div>
                <div v-else>
                  <h4
                      :style="{ color: item.dProfitPercentage < 0 ? 'red' : 'green' }"
                      style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                  >
                    {{ formatMoney(item.dProfitPercentage) + "%" }}
                  </h4>
                </div>
              </template>
              <template v-slot:item.dCurrencyGain="{ item }">
                <div v-if="isLoading" class="d-flex justify-center align-center">
                  <v-skeleton-loader
                      class="mx-3 my-1"
                      height="20px"
                      width="100px"
                      type="text"
                  ></v-skeleton-loader>
                </div>
                <div v-else>
                  <h4
                      :style="{ color: item.dCurrencyGain < 0 ? 'red' : 'green' }"
                      style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                  >
                    $ {{ formatMoney(item.dCurrencyGain) }} USD
                  </h4>
                </div>
              </template>

              <template v-slot:item.sStatusId="{ item }">
                <v-chip
                  v-if="item.sStatusId == '453f47d8-d5ad-4159-be49-b48b0f04caa5'"
                  class="ma-2"
                  color="primary"
                  outlined
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "To pay" }}</h4>
                </v-chip>
                <v-chip
                  v-else-if="item.sStatusId == '904a91ab-bdfc-45dc-a72f-fc02c2a15005'"
                  class="ma-2"
                  outlined
                  color="green"
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "Converted" }}</h4>
                </v-chip>
                <v-chip
                  v-else-if="item.sStatusId == 'f42d5b71-bcc8-4c2c-997a-40ad2da2bc31'"
                  class="ma-2"
                  outlined
                  color="#193D6E"
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "Paid" }}</h4>
                </v-chip>
                <v-chip
                  v-else-if="item.sStatusId == '63b83525-e0ff-4d52-821a-df20611cb4a3'"
                  class="ma-2"
                  outlined
                  color="red"
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "Parcial" }}</h4>
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <div style="display: flex; align-items: center; justify-content: center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="editInvoice(item)"
                        :disabled="
                          loading
                        "
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="black"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar factura</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="openDialogDeleteInvoice(item)"
                        :disabled="
                          loading ||
                          item.sStatusId != '453f47d8-d5ad-4159-be49-b48b0f04caa5'
                        "
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="red"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete item</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-card-text>
            <v-data-table
              :search="search"
              show-select
              single-select
              item-key="sInvoiceId"
              :headers="headersInvoicesConverted"
              :items="invoices"
            >
              <template v-slot:item.data-table-select="{ item, select }">
                <v-checkbox
                  :input-value="isSelected(item)"
                  @click="toggleSelect(item, select)"
                  :disabled="item.invoice_payments_relationship.some(rel => rel.transaction.sStatusId != '5a792637-1103-4a89-8f67-86c49b93e227') || parseFloat(item.ddTotalAmount) != item.totalAmount"
                ></v-checkbox>
              </template>
              <template v-slot:item.sName="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">{{ item.sName }}</h4>
              </template>
              <template v-slot:item.dDueDate="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">
                  {{ formatDate(item.dDueDate) }}
                </h4>
              </template>
              <template v-slot:item.dPurchaseDate="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">
                  {{ formatDate(item.dPurchaseDate) }}
                </h4>
              </template>
              <template v-slot:item.sTransactionAvaible="{ item }">
                <v-icon v-if="item.invoice_payments_relationship.some(rel => rel.transaction.sStatusId != '5a792637-1103-4a89-8f67-86c49b93e227') || parseFloat(item.ddTotalAmount) != item.totalAmount" style="color: grey;">mdi-alert-circle</v-icon>
                <v-icon v-else style="color: green;">mdi-check-circle</v-icon>
              </template>
              <template v-slot:item.sInvoice="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">{{ item.sInvoice }}</h4>
              </template>
              <template v-slot:item.sId="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">{{ item.sId }}</h4>
              </template>
              <template v-slot:item.dTotalAmount="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  $ {{ formatMoney(item.dTotalAmount) }} MXN
                </h4>
              </template>
              <template v-slot:item.dRateProtection="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  $ {{ formatMoney(item.dRateProtection) }} MXN
                </h4>
              </template>
              <template v-slot:item.dProfitPercentage="{ item }">
                <div v-if="isLoading" class="d-flex justify-center align-center">
                  <v-skeleton-loader
                      class="mx-3 my-1"
                      height="20px"
                      width="100px"
                      type="text"
                  ></v-skeleton-loader>
                </div>
                <div v-else>
                  <h4
                      :style="{ color: item.dProfitPercentage < 0 ? 'red' : 'green' }"
                      style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                  >
                    {{ formatMoney(item.dProfitPercentage) + "%" }}
                  </h4>
                </div>
              </template>
              <template v-slot:item.dCurrencyGain="{ item }">
                <div v-if="isLoading" class="d-flex justify-center align-center">
                  <v-skeleton-loader
                      class="mx-3 my-1"
                      height="20px"
                      width="100px"
                      type="text"
                  ></v-skeleton-loader>
                </div>
                <div v-else>
                  <h4
                      :style="{ color: item.dCurrencyGain < 0 ? 'red' : 'green' }"
                      style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                  >
                    $ {{ formatMoney(item.dCurrencyGain) }} USD
                  </h4>
                </div>
              </template>

              <template v-slot:item.sStatusId="{ item }">
                <v-chip
                  v-if="item.sStatusId == '453f47d8-d5ad-4159-be49-b48b0f04caa5'"
                  class="ma-2"
                  color="primary"
                  outlined
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "To pay" }}</h4>
                </v-chip>
                <v-chip
                  v-else-if="item.sStatusId == '904a91ab-bdfc-45dc-a72f-fc02c2a15005'"
                  class="ma-2"
                  outlined
                  color="green"
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "Converted" }}</h4>
                </v-chip>
                <v-chip
                  v-else-if="item.sStatusId == 'f42d5b71-bcc8-4c2c-997a-40ad2da2bc31'"
                  class="ma-2"
                  outlined
                  color="#193D6E"
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "Paid" }}</h4>
                </v-chip>
                <v-chip
                  v-else-if="item.sStatusId == '63b83525-e0ff-4d52-821a-df20611cb4a3'"
                  class="ma-2"
                  outlined
                  color="red"
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "Parcial" }}</h4>
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <div style="display: flex; align-items: center; justify-content: center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="editInvoice(item)"
                        :disabled="
                          loading
                        "
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="black"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar factura</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="openDialogDeleteInvoice(item)"
                        :disabled="
                          loading ||
                          item.sStatusId != '453f47d8-d5ad-4159-be49-b48b0f04caa5'
                        "
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="red"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete item</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
          </v-tab-item>
          <v-tab-item>
            <v-card-text>
            <v-data-table
              :search="search"
              show-select
              single-select
              item-key="sInvoiceId"
              v-model="selected"
              :headers="headersInvoices"
              :items="invoices"
            >
              <template v-slot:item.data-table-select="{ item, select }">
                <v-checkbox
                  :input-value="isSelected(item)"
                  @click="toggleSelect(item, select)"
                  :disabled="item.sStatusId != '453f47d8-d5ad-4159-be49-b48b0f04caa5'"
                ></v-checkbox>
              </template>
              <template v-slot:item.sName="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">{{ item.sName }}</h4>
              </template>
              <template v-slot:item.dDueDate="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">
                  {{ formatDate(item.dDueDate) }}
                </h4>
              </template>
              <template v-slot:item.dPurchaseDate="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">
                  {{ formatDate(item.dPurchaseDate) }}
                </h4>
              </template>
              <template v-slot:item.sInvoice="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">{{ item.sInvoice }}</h4>
              </template>
              <template v-slot:item.sId="{ item }">
                <h4 style="font-family: 'Montserrat', sans-serif">{{ item.sId }}</h4>
              </template>
              <template v-slot:item.dTotalAmount="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  $ {{ formatMoney(item.dTotalAmount) }} MXN
                </h4>
              </template>
              <template v-slot:item.dRateProtection="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  $ {{ formatMoney(item.dRateProtection) }} MXN
                </h4>
              </template>
              <template v-slot:item.dProfitPercentage="{ item }">
                <div v-if="isLoading" class="d-flex justify-center align-center">
                  <v-skeleton-loader
                      class="mx-3 my-1"
                      height="20px"
                      width="100px"
                      type="text"
                  ></v-skeleton-loader>
                </div>
                <div v-else>
                  <h4
                      :style="{ color: item.dProfitPercentage < 0 ? 'red' : 'green' }"
                      style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                  >
                    {{ formatMoney(item.dProfitPercentage) + "%" }}
                  </h4>
                </div>
              </template>
              <template v-slot:item.dCurrencyGain="{ item }">
                <div v-if="isLoading" class="d-flex justify-center align-center">
                  <v-skeleton-loader
                      class="mx-3 my-1"
                      height="20px"
                      width="100px"
                      type="text"
                  ></v-skeleton-loader>
                </div>
                <div v-else>
                  <h4
                      :style="{ color: item.dCurrencyGain < 0 ? 'red' : 'green' }"
                      style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                  >
                    $ {{ formatMoney(item.dCurrencyGain) }} USD
                  </h4>
                </div>
              </template>

              <template v-slot:item.sStatusId="{ item }">
                <v-chip
                  v-if="item.sStatusId == '453f47d8-d5ad-4159-be49-b48b0f04caa5'"
                  class="ma-2"
                  color="primary"
                  outlined
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "To pay" }}</h4>
                </v-chip>
                <v-chip
                  v-else-if="item.sStatusId == '904a91ab-bdfc-45dc-a72f-fc02c2a15005'"
                  class="ma-2"
                  outlined
                  color="green"
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "Converted" }}</h4>
                </v-chip>
                <v-chip
                  v-else-if="item.sStatusId == 'f42d5b71-bcc8-4c2c-997a-40ad2da2bc31'"
                  class="ma-2"
                  outlined
                  color="#193D6E"
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "Paid" }}</h4>
                </v-chip>
                <v-chip
                  v-else-if="item.sStatusId == '63b83525-e0ff-4d52-821a-df20611cb4a3'"
                  class="ma-2"
                  outlined
                  color="red"
                >
                  <h4 style="font-family: 'montserrat-medium';">{{ "Parcial" }}</h4>
                </v-chip>
              </template>
              <template v-slot:item.actions="{ item }">
                <div style="display: flex; align-items: center; justify-content: center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="editInvoice(item)"
                        :disabled="
                          loading
                        "
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="black"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar factura</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="openDialogDeleteInvoice(item)"
                        :disabled="
                          loading ||
                          item.sStatusId != '453f47d8-d5ad-4159-be49-b48b0f04caa5'
                        "
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="red"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete item</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-container>
    
    <v-dialog v-model="dialogSendPay" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-stepper v-model="e1" alt-labels elevation="0">
          <v-stepper-header color="#193D6E" class="mt-5 mr-15 ml-15" elevation="0" style="justify-content: center; align-items: center; justify-items: center; align-content: center; box-shadow: none;">
            <v-stepper-step :complete="e1 > 1" step="1" color="#193D6E"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2" color="#193D6E"></v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Paso 1: Detalle de pago -->
            <v-stepper-content step="1" elevation="0" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center">
                  <span class="title font-weight-bold text-textblack">{{ texts.invoices.paymentDetail }}</span>
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>{{ texts.invoices.invoiceToPay }}</th>
                        <th>{{ texts.invoices.customerName }}</th>
                        <th>{{ texts.invoices.clabe }}</th>
                        <th>{{ texts.invoices.amountToSend }}</th>
                        <th>{{ texts.invoices.destinationReceives }}</th>
                        <th>{{ texts.invoices.exchangeRate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="loadingCalculator">
                        <tr v-for="n in 3" :key="n">
                          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        </tr>
                        <tr>
                          <td colspan="3" class="text-right font-weight-bold">
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td class="font-weight-bold">
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td class="font-weight-bold">
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td></td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr v-for="(invoice, index) in payDetail.invoices" :key="invoice.sInvoiceId">
                          <td>{{ invoice.sInvoice }}</td>
                          <td>{{ invoice.supplier }}</td>
                          <td>{{ "********" + invoice.sAccountNumber }}</td>
                          <td>${{ formatMoney(invoice.dTotalUsd) }} USD</td>
                          <td>${{ formatMoney(invoice.dTotalAmount) }} MXN</td>
                          <td>${{ formatMoney(payDetail.exchangeRateToday) }} MXN</td>
                        </tr>
                        <tr>
                          <td colspan="3" class="text-right font-weight-bold">Total:</td>
                          <td class="font-weight-bold">${{ formatMoney(totalAmountUsd) }} USD</td>
                          <td class="font-weight-bold">${{ formatMoney(totalAmountMxn) }} MXN</td>
                          <td></td>
                        </tr>
                      </template>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="cancel()">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="button-save"
                  style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                   elevation="0" 
                   color="#193D6E" 
                   @click="sendVerification(2)">
                    {{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>

            <!-- Paso 2: Verificación OTP -->
            <v-stepper-content step="2" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center">
                  <span class="title font-weight-bold text-textblack">{{ texts.twoFactor.twoFactor }}</span> 
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <div class="pt-4 px-4 mb-4" style="display: flex; justify-content: space-around; flex-direction: row; width: 600px">
                    <div>
                      <v-otp-input v-model="otp" length="6" type="number" color="#193D6E"></v-otp-input>
                      <p style="font-size: 14px; font-weight: 500;">{{ texts.twoFactor.enterCode }}</p>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 1">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="button-save" 
                  style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                  elevation="0"
                  :disabled="otp.length < 6"
                  :loading="loading"
                  color="#193D6E"
                  @click="payInvoice()"
                  >
                    {{ texts.modalForm.confirmPay }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSendPayMxn" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-stepper v-model="e1" alt-labels elevation="0">
          <v-stepper-header color="#193D6E" class="mt-5 mr-15 ml-15" elevation="0" style="justify-content: center; align-items: center; justify-items: center; align-content: center; box-shadow: none;">
            <v-stepper-step :complete="e1 > 1" step="1" color="#193D6E"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2" color="#193D6E"></v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Paso 1: Detalle de pago -->
            <v-stepper-content step="1" elevation="0" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center">
                  <span class="title font-weight-bold text-textblack">{{ texts.invoices.paymentDetail }}</span>
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <v-simple-table>
                    <thead>
                      <tr>
                        <th>{{ texts.invoices.invoiceToPay }}</th>
                        <th>{{ texts.invoices.customerName }}</th>
                        <th>{{ texts.invoices.clabe }}</th>
                        <th>{{ texts.invoices.destinationReceives }}</th>
                        <th>{{ texts.dashboard.paymentConcept }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="loadingCalculator">
                        <tr v-for="n in 3" :key="n">
                          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                          <td><v-skeleton-loader type="text"></v-skeleton-loader></td>
                        </tr>
                        <tr>
                          <td colspan="3" class="text-right font-weight-bold">
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td class="font-weight-bold">
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td class="font-weight-bold">
                            <v-skeleton-loader type="text"></v-skeleton-loader>
                          </td>
                          <td></td>
                        </tr>
                      </template>
                      <template v-else>
                        <tr v-for="(invoice, index) in payDetail.invoices" :key="invoice.sInvoiceId">
                          <td>{{ invoice.sInvoice }}</td>
                          <td>{{ invoice.supplier }}</td>
                          <td>{{ "********" + invoice.sAccountNumber }}</td>
                          <td>${{ formatMoney(invoice.dTotalAmount) }} MXN</td>
                          <td>
                            <v-text-field
                              v-model="invoice.comment"
                              :label="texts.dashboard.paymentConcept"
                              clearable
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3" class="text-right font-weight-bold">Total:</td>
                          <td class="font-weight-bold">${{ formatMoney(totalAmountMxn) }} MXN</td>
                          <td></td>
                        </tr>
                      </template>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="cancelPayMxn()">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="button-save"
                  style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                   elevation="0" 
                   color="#193D6E" 
                   @click="sendVerification(2)">
                    {{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>

            <!-- Paso 2: Verificación OTP -->
            <v-stepper-content step="2" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center">
                  <span class="title font-weight-bold text-textblack">{{ texts.twoFactor.twoFactor }}</span> 
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <div class="pt-4 px-4 mb-4" style="display: flex; justify-content: space-around; flex-direction: row; width: 600px">
                    <div>
                      <v-otp-input v-model="otp" length="6" type="number" color="#193D6E"></v-otp-input>
                      <p style="font-size: 14px; font-weight: 500;">{{ texts.twoFactor.enterCode }}</p>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 1">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="button-save" 
                  style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                  elevation="0"
                  :disabled="otp.length < 6"
                  :loading="loading"
                  color="#193D6E"
                  @click="payInvoiceMxn()"
                  >
                    {{ texts.modalForm.confirmPay }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConvert" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-stepper v-model="e1" alt-labels elevation="0">
          <v-stepper-header color="#193D6E" class="mt-5 mr-15 ml-15" elevation="0" style="justify-content: center; align-items: center; justify-items: center; align-content: center; box-shadow: none;">
            <v-stepper-step :complete="e1 > 1" step="1" color="#193D6E"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 2" step="2" color="#193D6E"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="e1 > 3" step="3" color="#193D6E"></v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Paso 1: Selección de tarjeta -->
            <v-stepper-content step="1" elevation="0" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center">
                  <span class="title font-weight-bold text-textblack">{{ texts.invoices.selectTypeConversion }}</span> 
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="!loading">
                  <v-col cols="12">
                    <v-card class="d-flex pa-6" outlined :class="{ 'selected-card': selectedCard === 2 }" @click="selectCard(2)" elevation="0" style="align-self: stretch; background: white; border-radius: 10px; border: 1px solid #dddddd; justify-content: space-between; align-items: flex-start; gap: 16px;">
                      <div class="d-flex flex-column" style="flex: 1 1 0; gap: 8px; align-items: flex-start">
                        <div class="subtitle-1 font-weight-bold" style="color: #302e2e; font-size: 24px">{{ texts.payments.paySeparate }}</div>
                        <div class="body-1" style="color: #666565; text-align: justify">{{ texts.payments.paySeparateDescription }}</div>
                      </div>
                      <v-icon v-if="selectedCard === 2" color="#193D6E">mdi-check-circle</v-icon>
                      <v-icon v-else color="grey lighten-1">mdi-checkbox-blank-circle-outline</v-icon>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card class="d-flex pa-6" :disabled="isDisabledForward" outlined :class="{ 'selected-card': selectedCard === 3 }" @click="selectCard(3)" elevation="0" style="align-self: stretch; background: white; border-radius: 10px; border: 1px solid #dddddd; justify-content: space-between; align-items: flex-start; gap: 16px;">
                      <div class="d-flex flex-column" style="flex: 1 1 0; gap: 8px; align-items: flex-start">
                        <div class="subtitle-1 font-weight-bold" style="color: #302e2e; font-size: 24px">{{ texts.payments.payForward }}</div>
                        <div class="body-1" style="color: #666565; text-align: justify"> {{isDisabledForward ? texts.dashboard.payForwardDescriptionDisabled : texts.payments.payForwardDescription }}</div>
                      </div>
                      <v-icon v-if="selectedCard === 3" color="#193D6E">mdi-check-circle</v-icon>
                      <v-icon v-else color="grey lighten-1">mdi-checkbox-blank-circle-outline</v-icon>
                    </v-card>
                  </v-col>
                </v-card-text>
                <div v-else style="display: flex; justify-content: center">
                  <v-progress-circular indeterminate color="amber"></v-progress-circular>
                </div>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="cancel()">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn 
                    class="button-save"
                    style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                    elevation="0"
                    color="#193D6E"
                    @click="selectTypeConversion(selectedCard)"
                    :disabled="selectedCard === null"
                  >
                    {{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>

            <!-- Paso 2: Detalles según selección -->
            <v-stepper-content step="2" elevation="0" class="pt-0">

                <v-container class="mt-1" v-if="selectedCard === 2">
                  <!-- Skeleton loader para v-if "selectedCard === 2" -->
                  <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center">
                    <span class="title font-weight-bold text-textblack">{{ texts.invoices.conversionDetail }}</span>
                  </v-card-title>
                  <v-card-text class="pt-0 pb-0 px-0">
                    <v-simple-table style="width: 100vh;">
                      <template v-if="loadingCalculator">
                         <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
                      </template>
                      <template v-else>
                        <thead>
                          <tr>
                            <th>{{ texts.invoices.invoiceToPay }}</th>
                            <th>{{ texts.invoices.customerName }}</th>
                            <th>{{ texts.invoices.clabe }}</th>
                            <th>{{ texts.invoices.amountToSend }}</th>
                            <th>{{ texts.invoices.destinationReceives }}</th>
                            <th>{{ texts.invoices.exchangeRate }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(invoice, index) in forexDetail.invoices" :key="invoice.sInvoiceId">
                            <td>{{ invoice.sInvoice }}</td>
                            <td>{{ invoice.supplier }}</td>
                            <td>{{ "********" + invoice.sAccountNumber }}</td>
                            <td>${{ formatMoney(invoice.dTotalUsd) }} USD</td>
                            <td>${{ formatMoney(invoice.dTotalAmount) }} MXN</td>
                            <td>${{ formatMoney(invoice.exchangeRate) }} MXN</td>
                          </tr>
                          <tr>
                            <td colspan="3" class="text-right font-weight-bold">Total:</td>
                            <td class="font-weight-bold">${{ formatMoney(forexDetail.totalUSD) }} USD</td>
                            <td class="font-weight-bold">${{ formatMoney(forexDetail.totalAmountMxn) }} MXN</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                  <div style="display: flex; justify-content: end; margin-top: 20px">
                    <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 1">
                      {{ texts.modalForm.cancel }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="button-save"
                          style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                          elevation="0"
                          color="#193D6E"
                          @click="sendVerificationTransaction">
                      {{ texts.modalForm.continue }}
                    </v-btn>
                  </div>
                </v-container>

              <v-container class="mt-1" v-else-if="selectedCard === 3">
                <!-- Skeleton loader igualado para v-else-if "selectedCard === 3" -->
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center">
                  <span class="title font-weight-bold text-textblack">{{ texts.invoices.forwardDetail }}</span>
                </v-card-title>
                <v-card-text class="pt-0 pb-0 px-0">
                  <div class="pt-4 px-4 mb-4" style="display: flex; justify-content: center; background-color: #e5f6fd; flex-direction: row; width: 60vw;">
                    <div>
                      <v-icon color="#2c93d6" style="margin-right: 12px">mdi-alert-octagon-outline</v-icon>
                    </div>
                    <div>
                      <p style="font-size: 16px; font-weight: 700; color: #014361">{{ texts.invoices.forwardConditions }}</p>
                      <p style="font-size: 14px; font-weight: 500; color: #014361" class="mb-0">{{ texts.invoices.forwardConditions1 }}</p>
                      <v-divider class="my-4"></v-divider>
                      <p style="font-size: 14px; font-weight: 500; color: #014361">{{ texts.invoices.forwardConditions2 }}</p>
                    </div>
                  </div>
                </v-card-text>
                <v-card-text class="py-6 pb-0">
                  <v-simple-table>
                    <template v-if="loadingCalculator">
                         <v-skeleton-loader type="table-tbody"></v-skeleton-loader>
                    </template>
                    <template v-else>
                      <thead>
                        <tr>
                          <th>{{ texts.invoices.invoiceToPay }}</th>
                          <th>{{ texts.invoices.purchaseDate }}</th>
                          <th>{{ texts.invoices.customerName }}</th>
                          <th>{{ texts.invoices.clabe }}</th>
                          <th>{{ texts.invoices.amountToSend }}</th>
                          <th>{{ texts.invoices.destinationReceives }}</th>
                          <th>{{ texts.invoices.exchangeRate }}</th>
                          <th>Forward Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(invoice, index) in forwardDetail.invoices" :key="invoice.sInvoiceId">
                          <td>{{ invoice.sInvoice }}</td>
                          <td>{{ invoice.dPurchaseDate ? formatDate(invoice.dPurchaseDate) : 'N/A' }}</td>
                          <td>{{ invoice.supplier }}</td>
                          <td>{{ "********" + invoice.sAccountNumber }}</td>
                          <td>{{ invoice.dTotalUsd ? '$' + formatMoney(invoice.dTotalUsd) + ' USD' : 'N/A' }}</td>
                          <td>${{ formatMoney(invoice.dTotalAmount) }} MXN</td>
                          <td>{{ invoice.dForwardExchangeRate === 0.00 ? '-' : '$' + formatMoney(invoice.dForwardExchangeRate) + ' MXN' }}</td>
                          <td>
                            <v-dialog ref="dialog" v-model="invoice.modal" width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn text :color="invoice.dForwardDate ? 'primary' : 'grey'" v-bind="attrs" v-on="on">
                                  {{ invoice.dForwardDate ? formatDate(invoice.dForwardDate) : "Select Date" }}
                                </v-btn>
                              </template>
                              <v-date-picker v-model="invoice.dForwardDate" :min="today" :allowed-dates="onlyWeekdays" :max="limit" scrollable @input="getForwardRate(invoice)">
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="closeDatePicker(invoice)"
                                  >
                                  Cancel
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4" class="text-right font-weight-bold">Total:</td>
                          <td class="font-weight-bold">{{ forwardDetail.totalUSD ? '$' + formatMoney(forwardDetail.totalUSD) + ' USD' : 'N/A' }}</td>
                          <td class="font-weight-bold">${{ formatMoney(forwardDetail.totalAmountMxn) }} MXN</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
                <v-card-text class="pt-0 pb-0 px-0">
                  <div class="pt-4 px-4 mb-4" style="display: flex; justify-content: center; background-color: #fff4e5; flex-direction: row; width: 60vw;">
                    <div>
                      <v-icon color="rgb(237, 108, 2)" style="margin-right: 12px">mdi-alert-outline</v-icon>
                    </div>
                    <div>
                      <p style="font-size: 16px; font-weight: 700; color: rgb(102, 60, 0)">{{ texts.invoices.forwardConditions3 }}</p>
                      <p style="font-size: 14px; font-weight: 500; color: rgb(102, 60, 0)">
                        {{ texts.invoices.forwardConditions4 }} {{ texts.invoices.forwardConditions5 }}
                      </p>
                      <v-checkbox v-model="checkboxModel" color="primary" hide-details class="pb-5">
                        <template v-slot:label>
                          <div style="font-size: 14px; font-weight: 500; color: rgb(102, 60, 0)">
                            {{ texts.invoices.forwardConditions6 }} <span style="font-size: 18px; font-weight: bold;">{{ forwardDetail.totalAmountPercentage ? formatMoney(forwardDetail.totalAmountPercentage) + ' USD' : 'N/A' }}</span> {{ texts.invoices.forwardConditions7 }}
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 1">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="button-save"
                        style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                        elevation="0"
                        color="#193D6E"
                        :disabled="!checkboxModel || !allDatesSelected || !allRatesSelected"
                        @click="sendVerification(3)">
                    {{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>

            <!-- Paso 3: Verificación OTP -->
            <v-stepper-content step="3" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center">
                  <span class="title font-weight-bold text-textblack">{{ texts.twoFactor.twoFactor }}</span>
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <div class="pt-4 px-4 mb-4" style="display: flex; justify-content: space-around; flex-direction: row; width: 600px">
                    <div>
                      <v-otp-input v-model="otp" length="6" type="number" color="#193D6E"></v-otp-input>
                      <p style="font-size: 14px; font-weight: 500;">{{ texts.twoFactor.enterCode }}</p>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="e1 = 2">
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="button-save"
                   style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                   elevation="0"
                   :disabled="otp.length < 6"
                   :loading="loading"
                   color="#193D6E"
                   @click="confirmTransaction()"> 
                    {{ texts.modalForm.confirmConvert }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title style="display: flex; justify-content: center">
          <span class="text-h5">{{ texts.invoices.addInvoice }}</span> 
        </v-card-title>
        <v-card-subtitle class="pb-0 mt-3">
          <span style="font-size: 15px"
            >{{ texts.invoices.addInvoiceDetailText }}</span
          >
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-form
                ref="formNew"
                style="width: 100%"
                lazy-validation
                v-on:submit.prevent
              >
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.invoice + '*'" 
                    required
                    v-model="forms.new.sInvoice"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.sId"
                    required
                    v-model="forms.new.sId"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :label="texts.invoices.supplier + '*'" 
                    required
                    :items="suppliers"
                    item-value="sPaymentRecipientId"
                    item-text="sName"
                    v-model="forms.new.sPaymentRecipientId"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.purchaseDate + '*'" 
                    required
                    type="date"
                    v-model="forms.new.dPurchaseDate"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.totalAmountMXN + '*'" 
                    required
                    type="number"
                    v-model="forms.new.dTotalAmount"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.creditTime + '*'" 
                    required
                    type="number"
                    v-model="forms.new.iCreditPeriod"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.exchangeRateProtected + '*'" 
                    required
                    type="number"
                    v-model="forms.new.dRateProtection"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
          <small>*{{ texts.invoices.requiredField }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text :disabled="loading" @click="cancel()"> {{ texts.modalForm.cancel }} </v-btn>
          <v-btn class="button-save"
            style="max-width: 200px; color: #FFF; background-color: #193D6E;"
            elevation="0"
            :disabled="loading"
            :loading="loading"
            @click="saveInvoice(false)"> 
            {{ texts.modalForm.save }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDuplicate" persistent max-width="fit-content">
      <v-card>
        <v-card-title class="text-h5 my-4">
          {{ texts.invoices.invoiceDuplicateTitle }}
        </v-card-title>
        <v-card-text v-if="!loading" class="my-6">
          {{ texts.invoices.invoiceDuplicateSubtitle }}
        </v-card-text
        >
        <div v-else style="display: flex; justify-content: center">
          <v-progress-circular indeterminate color="amber"></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text :disabled="loading" @click="dialogDuplicate = false"> {{ texts.modalForm.cancel }} </v-btn> 
          <v-btn color="#193D6E" :disabled="loading" @click="saveInvoice(true)" style="color: white;">
            {{ texts.modalForm.save }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditInvoice" persistent max-width="600px">
      <v-card>
        <v-card-title style="display: flex; justify-content: center">
          <span class="text-h5">{{ texts.invoices.editInvoice }}</span> 
        </v-card-title>
        <v-card-subtitle class="pb-0 mt-3">
          <span style="font-size: 15px"
            >{{ texts.invoices.addInvoiceDetail }}</span>
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-form
                ref="formEdit"
                style="width: 100%"
                lazy-validation
                v-on:submit.prevent
              >
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.invoice + '*'"
                    required
                    v-model="forms.detail.sInvoice"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.sId"
                    v-model="forms.detail.sId"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :label="texts.invoices.supplier + '*'" 
                    required
                    :items="suppliers"
                    item-value="sPaymentRecipientId"
                    item-text="sName"
                    v-model="forms.detail.sPaymentRecipientId"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.purchaseDate + '*'" 
                    required
                    type="date"
                    v-model="forms.detail.dPurchaseDate"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.totalAmountMXN + '*'" 
                    required
                    type="number"
                    v-model="forms.detail.dTotalAmount"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.creditTime + '*'" 
                    required
                    type="number"
                    v-model="forms.detail.iCreditPeriod"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.exchangeRateProtected + '*'" 
                    required
                    type="number"
                    v-model="forms.detail.dRateProtection"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
          <small>*{{ texts.invoices.requiredField }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
              <v-btn color="black" text :disabled="loading" @click="cancel()"> {{ texts.modalForm.cancel }} </v-btn>
              <v-btn class="button-save"
                style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                elevation="0"
                :disabled="loading"
                @click="updateInvoice()"> 
                {{ texts.modalForm.save }}
              </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="fit-content">
      <v-card>
        <v-card-title class="text-h5 my-4">
          {{ texts.invoices.invoiceDeleteTitle }}
        </v-card-title>
        <v-card-text v-if="!loading" class="my-6"
          >{{ texts.invoices.invoiceDeleteMessage }}</v-card-text
        >
        <div v-else style="display: flex; justify-content: center">
          <v-progress-circular indeterminate color="amber"></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text :disabled="loading" @click="cancel()"> {{ texts.modalForm.cancel }} </v-btn> 
          <v-btn color="#193D6E" :disabled="loading" @click="deleteInvoice()">
            {{ texts.modalForm.delete }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import moment from "moment";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export default {
  name: "DashboardView",
  data() {
    return {
      nameXLSX: "FacturasPorAsignar.xlsx",
      modal: false,
      dialogForward: false,
      loadingExchange: false,
      loadingCalculator: false,
      texts: "",
      currentDate: moment(),
      currentDateCdmx: moment().clone().utcOffset("-06:00"),
      isDisabledForward: false,
      selectedCard: 0,
      dialog: false,
      otp: "",
      blockedUsd: 0,
      checkboxModel: false,
      dialogEditInvoice: false,
      allDatesSelected: false,
      allRatesSelected: false,
      dialogDelete: false,
      dialogSendPayMxn: false,
      dialogForex: false,
      sExchangeRateReference: null,
      drawer: null,
      actualCurrency: "0",
      actualCurrencyMxn: "0",
      referenceCurrency: "0",
      dialogConvert: false,
      search: "",
      isLoading: false,
      isLoadingStart: false,
      isRegisterEfex: true,
      invoices: [],
      exchangeRateData: [],
      totalAmountMxn: 0,
      selected: [],
      ifHaveAmount: false,
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      balanceMxn: "0",
      balanceUsd: "0",
      countdownDashboard: 59,
      balance: "0",
      selected: [],
      items: [],
      selectedSupplier: "",
      selectedStatus: "453f47d8-d5ad-4159-be49-b48b0f04caa5",
      limit: null,
      tab: 0,
      contacts: [],
      today: null,
      itemsSelected: [],
      itemsSelectedForwards: [],
      isForwardRateCalculated: false,
      bSelectedAll: false,
      dialogAddFoundss: false,
      dialogSendPay: false,
      dialogAddFounds: false,
      dataAddFounds: {},
      dialogCreate: false,
      dialogPay: false,
      editableData: {},
      selectedCard: null,
      totalUsd: 0,
      loading: false,
      selectedMenuItem: "dashboard",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY",
      },
      isForwardsActive: false,
      dDateForward: null,
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      bLoading: false,
      isDeferredPayment: false,
      rules: {
        required: (v) => !!v || "El campo es obligatorio",
      },
      forms: {
        new: {
          dPurchaseDate: "",
          sPaymentRecipientId: "",
          sInvoice: "",
          sId: "",
          dTotalAmount: "",
          dRateProtection: "",
          iCreditPeriod: "",
        },
        detail: {
          sInvoiceId: "",
          dPurchaseDate: "",
          sPaymentRecipientId: "",
          sInvoice: "",
          sId: "",
          dTotalAmount: "",
          dRateProtection: "",
          iCreditPeriod: "",
        },
        delete: {
          sInvoiceId: "",
        },
      },
      stepper: false,
      e1: 1,
      suppliers: [],
      status: [
        { sName: "To pay", sStatusId: "453f47d8-d5ad-4159-be49-b48b0f04caa5" },
        { sName: "Converted", sStatusId: "904a91ab-bdfc-45dc-a72f-fc02c2a15005" },
        { sName: "Paid", sStatusId: "f42d5b71-bcc8-4c2c-997a-40ad2da2bc31" },
      ],
      timerCount: 30,
      headersInvoices: [
        this.selectLanguage === 'en' ? { text: "Supplier", value: "sName", align: "center" } : { text: "Proveedor", value: "sName", align: "center" },
        this.selectLanguage === 'en' ? { text: "Purchase date", value: "dPurchaseDate", align: "center" } : { text: "Fecha de compra", value: "dPurchaseDate", align: "center" },
        this.selectLanguage === 'en' ? { text: "Due date", value: "dDueDate", align: "center" } : { text: "Fecha de vencimiento", value: "dDueDate", align: "center" },
        this.selectLanguage === 'en' ? { text: "Invoice", value: "sInvoice", align: "center" } : { text: "Factura", value: "sInvoice", align: "center" },
        this.selectLanguage === 'en' ? { text: "Purchase Order", value: "sId", align: "center" } : { text: "Orden de compra", value: "sId", align: "center" },
        this.selectLanguage === 'en' ? { text: "Status", value: "sStatusId", align: "center" } : { text: "Estado", value: "sStatusId", align: "center" },
        this.selectLanguage === 'en' ? { text: "Total amount MXN", value: "dTotalAmount", align: "center" } : { text: "Monto total MXN", value: "dTotalAmount", align: "center" },
        this.selectLanguage === 'en' ? { text: "Exchange rate protection MXN", value: "dRateProtection", align: "center", sortable: false } : { text: "Cobertura de tasa de cambio MXN", value: "dRateProtection", align: "center", sortable: false },   
        this.selectLanguage === 'en' ? { text: "Currency profit (%)", value: "dProfitPercentage", align: "center" } : { text: "Beneficio de moneda (%)", value: "dProfitPercentage", align: "center" },
        this.selectLanguage === 'en' ? { text: "Currency gain", value: "dCurrencyGain", align: "center" } : { text: "Beneficio de moneda", value: "dCurrencyGain", align: "center" },
        this.selectLanguage === 'en' ? { text: "Actions", value: "actions", align: "center" } : { text: "Acciones", value: "actions", align: "center" }
      ],
      headersInvoicesConverted: [
        this.selectLanguage === 'en' ? { text: "Supplier", value: "sName", align: "center" } : { text: "Proveedor", value: "sName", align: "center" },
        this.selectLanguage === 'en' ? { text: "Purchase date", value: "dPurchaseDate", align: "center" } : { text: "Fecha de compra", value: "dPurchaseDate", align: "center" },
        this.selectLanguage === 'en' ? { text: "Due date", value: "dDueDate", align: "center" } : { text: "Fecha de vencimiento", value: "dDueDate", align: "center" },
        this.selectLanguage === 'en' ? { text: "Invoice", value: "sInvoice", align: "center" } : { text: "Factura", value: "sInvoice", align: "center" },
        this.selectLanguage === 'en' ? { text: "Purchase Order", value: "sId", align: "center" } : { text: "Orden de compra", value: "sId", align: "center" },
        this.selectLanguage === 'en' ? { text: "Status", value: "sStatusId", align: "center" } : { text: "Estado", value: "sStatusId", align: "center" },
        this.selectLanguage === 'en' ? { text: "Avaible payment", value: "sTransactionAvaible", align: "center" } : { text: "Pago disponible", value: "sTransactionAvaible", align: "center" },
        this.selectLanguage === 'en' ? { text: "Total amount MXN", value: "dTotalAmount", align: "center" } : { text: "Monto total MXN", value: "dTotalAmount", align: "center" },
        this.selectLanguage === 'en' ? { text: "Exchange rate protection MXN", value: "dRateProtection", align: "center", sortable: false } : { text: "Cobertura de tasa de cambio MXN", value: "dRateProtection", align: "center", sortable: false },   
        this.selectLanguage === 'en' ? { text: "Currency profit (%)", value: "dProfitPercentage", align: "center" } : { text: "Beneficio de moneda (%)", value: "dProfitPercentage", align: "center" },
        this.selectLanguage === 'en' ? { text: "Currency gain", value: "dCurrencyGain", align: "center" } : { text: "Beneficio de moneda", value: "dCurrencyGain", align: "center" },
        this.selectLanguage === 'en' ? { text: "Actions", value: "actions", align: "center" } : { text: "Acciones", value: "actions", align: "center" }
      ],
      timerId: null,
      dataEfex: {},
      exchangeRateAdjustments: [],
      exchangeRatesStateForwards: [],
      isPayMxn: false,
      bSelectedAllSeparate: false,
      dialogEditProfileActive: false,
      isForwards: false,
      isForwardsPayment: false,
      userName: "",
      forwardRate: null,
      modalCurrency: 0,
      forwardActualRate: null,
      selectedId: "",
      sections: [
        {
          icon: "mdi mdi-view-dashboard",
          value: "operation",
          title: "Operaciones",
          items: [
            {
              icon: "mdi mdi-view-dashboard",
              value: "dashboard",
              title: "Dashboard",
              disabled: false,
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Seguimiento de facturas",
              disabled: false,
            },
            {
              icon: "mdi-forward",
              value: "activeForwards",
              title: "Forwards activos",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-history",
          value: "history",
          title: "Historial",
          items: [
            {
              icon: "mdi-file-document",
              value: "exchangeRate",
              title: "Historial de conversiones",
              disabled: true,
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Historial de pagos",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-wallet",
          value: "wallets",
          title: "Wallets",
          items: [
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Proveedores",
              disabled: true,
            },
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Estado de cuenta",
              disabled: true,
            },
          ],
        },
      ],
      payDetail: {
        supplier: "",
        totalUSD: "",
        exchangeRate: "",
        dTotalAmount: "",
        sAccountNumber: "",
        exchangeRateToday: "",
        sInvoice: "",
      },
      totalAmountUsd: 0,
      totalAmountMxn: 0,
      forexDetail: {
        sInvoice: "",
        totalUSD: "",
        exchangeRate: "",
        dTotalAmount: "",
      },
      forwardDetail: {
        sInvoice: "",
        totalUSD: "",
        exchangeRate: "",
        dTotalAmount: "",
        totalAmountPercentage: "",
      },
      miniVariant: true,
      expandOnHover: false,
      registerFields: [
        { model: "sBankName", type: "text", label: "sBankName" },
        { model: "sAccountNumber", type: "text", label: "sAccountNumber" },
        { model: "sRoutingNumber", type: "text", label: "sRoutingNumber" },
      ],
      transactions: [],
      dialogDuplicate: false,
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    tabStyle(tabIndex) {
      return {
        color: this.tab === tabIndex ? "#193D6E" : "#506172",
        fontSize: "16px",
        fontWeight: "600",
        width: "200px",
        lineHeight: "24px",
        fontFamily: "montserrat-bold",
      };
    },
    openDialogSupplier() {
      this.dialog = true;
    },
    toggleSelect(item) {
      const index = this.selected.findIndex(
        (selectedItem) => selectedItem.sInvoiceId === item.sInvoiceId
      );
      if (index === -1) {
        this.selected.push(item);
      } else {
        this.selected.splice(index, 1);
      }
    },
    // toggleSelect(item) {
    //   const index = this.selected.findIndex(
    //     (selectedItem) => selectedItem.sInvoiceId === item.sInvoiceId
    //   );
    //   if (index === -1) {
    //     this.selected.push(item);
    //   } else {
    //     this.selected.splice(index, 1);
    //   }
    // },
    isSelected(item) {
      return this.selected.some(
        (selectedItem) => selectedItem.sInvoiceId === item.sInvoiceId
      );
    },
    openConvert(item) {
      this.dialogConvert = true;
    },
    async sendVerification(step) {
      // Verifica si el paso es 3 y valida el balance
      if (step === 3) {
        const requiredAmount = this.forwardDetail.totalUSD * 0.05;
        if (requiredAmount > this.balanceUsd) {
          this.mixError(this.texts.invoices.amountError, 400);
          this.loading = false;
          return;
        }
      }

      // Asigna el paso actual
      this.e1 = step;

      try {
        // Obtiene el token de autorización de forma más segura
        const token = this.$store.state.sToken ? `Bearer ${this.$store.state.sToken}` : "";

        // Realiza la solicitud GET
        const response = await DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
          headers: { Authorization: token }
        });

        const { data, status } = response;

        // Maneja la respuesta exitosa pero con success falso
        if (!data.success) {
          this.mixError(data.message, status);
        }
      } catch (error) {
        // Manejo de errores más robusto
        const errMessage = error?.response?.data?.message || 'Ocurrió un error inesperado.';
        const errStatus = error?.response?.status || 500;
        this.mixError(errMessage, errStatus);
      } finally {
        // Asegura que el indicador de carga se desactive siempre
        this.loading = false;
      }
    },
    async calculatorAmount(amount, rate) {
      const headers = {
        Authorization: this.$store.state.sToken
          ? `Bearer ${this.$store.state.sToken}`
          : "",
      }
      try {
        const response = await DB.post(`${URI}/api/v1/${this.selectLanguage}/dashboard/calculatorAmount`, {
          amount: amount,
          rate: rate,
          reverse: true
        }, { headers });
        return response.data.data.result;
      } catch (error) {
        console.error('Error en calculatorAmount:', error);
        return null;
      }
    },
    async openPayNow(items) {
      this.dialogSendPay = true;
      this.loadingCalculator = true;
      this.totalAmountUsd = 0;
      this.totalAmountMxn = 0;
      this.payDetail.invoices = await Promise.all(items.map(async item => ({
        sInvoiceId: item.sInvoiceId,
        supplier: item.sName,
        dTotalUsd: await this.calculatorAmount(item.dTotalAmount, this.sExchangeRateReference),
        dTotalAmount: item.dTotalAmount,
        sAccountNumber: item.sAccountNumber.slice(-4),
        sInvoice: item.sInvoice,
      })));
      this.totalAmountUsd = this.payDetail.invoices.reduce((sum, invoice) => sum + parseFloat(invoice.dTotalUsd), 0);
      this.totalAmountUsd = Math.ceil(this.totalAmountUsd * 100) / 100;
      this.totalAmountMxn = this.payDetail.invoices.reduce((sum, invoice) => sum + parseFloat(invoice.dTotalAmount), 0);
      this.loadingCalculator = false;
    },
    async openPayNowMxn(items) {
      this.dialogSendPayMxn = true;
      this.loadingCalculator = true;
      this.totalAmountMxn = 0;
      this.totalAmountMxn = items.reduce((sum, invoice) => sum + parseFloat(invoice.dTotalAmount), 0);
      this.payDetail.invoices = await Promise.all(items.map(async item => ({
        sInvoiceId: item.sInvoiceId,
        supplier: item.sName,
        dTotalUsd: await this.calculatorAmount(item.dTotalAmount, this.sExchangeRateReference),
        dTotalAmount: item.dTotalAmount,
        sAccountNumber: item.sAccountNumber.slice(-4),
        sInvoice: item.sInvoice,
        dPurchaseDate: item.dPurchaseDate,
        comment: '',
      })));
      this.loadingCalculator = false;
    },
    async payInvoiceMxn() {
      this.loading = true;

      const isOtpValid = await this.validateOtp();

      if (!isOtpValid) {
        this.loading = false;
        this.mixError('Invalid code', 400);
        return;
      }

      const payload = {
        invoices: this.payDetail.invoices.map(invoice => ({
          sInvoiceId: invoice.sInvoiceId,
          comment: invoice.comment
        })),
      };

      try {
        const response = await DB.post(`${URI}/api/v1/${this.selectLanguage}/payments/sendPaymentMasiveFromInvoices`, payload, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        });

        if (!response.data.success) {
          this.mixError(response.data.message, response.data.status);
          this.loading = false;
          return;
        }
        else {
          this.loading = false;
          this.mixSuccess(response.data.message, response.data.status);
          this.getInvoices();
          this.cancelPayMxn();
        }
      } catch (error) {
        this.mixError(error.response.data.message, error.response.status);
        this.loading = false;
        return;
      }

      this.loading = false;
      this.getInvoices();
      this.cancelPayMxn();


    },
    selectCard(cardNumber) {
      this.selectedCard = cardNumber;
    },
    async selectTypeConversion(cardNumber) {
    this.loadingCalculator = true;
      this.e1 = 2;
      this.loading = true;

      //const exchangeRateReference = parseFloat(this.sExchangeRateReference);
      const exchangeRateToday = parseFloat(this.payDetail.exchangeRateToday);

        // Helper function to process invoices
      const processInvoices = async (items, extraFields = {}) => {
        const invoicePromises = items.map(async item => {
            let dTotalUsd = await this.calculatorAmount(item.dTotalAmount, this.sExchangeRateReference);
            dTotalUsd = Math.floor(dTotalUsd * 100) / 100;
            return {
            sInvoiceId: item.sInvoiceId,
            supplier: item.sName,
            sInvoice: item.sInvoice,
            dPurchaseDate: item.dPurchaseDate,
            dTotalUsd: parseFloat(dTotalUsd),
            dTotalAmount: parseFloat(item.dTotalAmount),
            sAccountNumber: item.sAccountNumber.slice(-4),
            ...extraFields,
          };
        });
        return Promise.all(invoicePromises);
      };

      if (cardNumber === 2) {
        this.forexDetail.invoices = await processInvoices(this.selected, {
          exchangeRate: exchangeRateToday,
        });
        this.forexDetail.totalUSD = this.forexDetail.invoices.reduce(
          (sum, invoice) => sum + invoice.dTotalUsd,
          0
        );
        this.forexDetail.totalAmountMxn = this.forexDetail.invoices.reduce(
          (sum, invoice) => sum + invoice.dTotalAmount,
          0
        );
      }

      if (cardNumber === 3) {
        this.forwardDetail.invoices = await processInvoices(this.selected, {
          dForwardExchangeRate: 0.0,
        });

        this.forwardDetail.totalUSD = this.forwardDetail.invoices.reduce(
          (sum, invoice) => sum + invoice.dTotalUsd,
          0
        );
        this.forwardDetail.totalAmountMxn = this.forwardDetail.invoices.reduce(
          (sum, invoice) => sum + invoice.dTotalAmount,
          0
        );
      }

      this.loadingCalculator = false;
      this.loading = false;
    },
    sendVerificationTransaction(){
      this.e1 = 3;
      try {
        DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }).then((response) => {
          if(!response.data.success){
           this.mixError(response.data.message, response.status);
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
        }
        catch(error){
          this.mixError(error.response.data.message, error.response.status);
        }
    },
    async confirmTransaction(){
      this.loading = true;
      const isOtpValid = await this.validateOtp();

      if (!isOtpValid) {
        this.loading = false;
        this.mixError('Invalid code', 400);
        return;
      }

      if(this.selectedCard == 2){
         this.convertInvoice();
      }
      if(this.selectedCard == 3){
         this.sendForward();
      }
    },
    // getExchangeRate() {
    //   DB.get(`${URI}/api/v1/${this.selectLanguage}/dashboard`, {
    //     headers: {
    //       Authorization: this.$store.state.sToken
    //         ? `Bearer ${this.$store.state.sToken}`
    //         : "",
    //     },
    //   }).then((response) => {
    //     this.payDetail.exchangeRateToday = response.data.exchangeRateToday;
    //     this.sExchangeRateReference = response.data.exchangeRateTodayReference;
    //   });
    // },
    getDashboard() {
        this.loading = true;
        DB.get(`${URI}/api/v1/${this.selectLanguage}/dashboard`, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }).then((response) => {
          this.loading = false;
          this.startCountdownDashboard();
          this.items = response.data;
          this.balanceUsd = response.data.balances.balanceUsd.balance;
          this.balanceMxn = response.data.balances.balanceMxn.balance;
          this.blockedUsd = response.data.balances.blocked.balance;
          this.exchangeRateTodayReference = this.items.exchangeRateTodayReference;
          this.payDetail.exchangeRateToday = response.data.exchangeRateToday;
          this.sExchangeRateReference = response.data.exchangeRateTodayReference;
          this.getInvoices();
        }).catch((error) => {
          if (error.response.data.code) {
            this.$router.push("/welcome").catch(() => {});
          }
          // this.isLoadingSuppliers = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    saveInvoice(isValid = false) {
      if (this.$refs.formNew.validate()) {
        this.loading = true;

        let payload = {
          dPurchaseDate: this.forms.new.dPurchaseDate,
          sPaymentRecipientId: this.forms.new.sPaymentRecipientId,
          sInvoice: this.forms.new.sInvoice,
          sId: this.forms.new.sId,
          dTotalAmount: this.forms.new.dTotalAmount,
          dRateProtection: this.forms.new.dRateProtection,
          iCreditPeriod: this.forms.new.iCreditPeriod,
          skipDuplicate: isValid,
          contactData: {
            create: false,
          },
        };

        DB.post(`${URI}/api/v1/${this.selectLanguage}/invoices/`, payload, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        })
          .then((response) => {
            if(response.status === 202){
              this.dialogDuplicate = true;
              this.loading = false;
              //this.mixError(response.data.message, response.status);
            }
            else {
              this.loading = false;
              this.dialogDuplicate = false;
              this.getInvoices();
              this.cancel();
            }
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.error = true;
        this.codeError = "INVALID_INPUT";
      }
    },
    updateInvoice() {
      if (this.$refs.formEdit.validate()) {
        this.loading = true;

        let payload = {
          sInvoiceId: this.forms.detail.sInvoiceId,
          dPurchaseDate: this.forms.detail.dPurchaseDate,
          sPaymentRecipientId: this.forms.detail.sPaymentRecipientId,
          sInvoice: this.forms.detail.sInvoice,
          sId: this.forms.detail.sId,
          dTotalAmount: this.forms.detail.dTotalAmount,
          dRateProtection: this.forms.detail.dRateProtection,
          iCreditPeriod: this.forms.detail.iCreditPeriod,
        };

        DB.put(`${URI}/api/v1/${this.selectLanguage}/invoices/`, payload, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        })
          .then((response) => {
            this.loading = false;
            this.getInvoices();
            this.cancel();
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.error = true;
        this.codeError = "INVALID_INPUT";
      }
    },
    deleteInvoice() {
      this.loading = true;

      let payload = {
        sInvoiceId: this.forms.delete.sInvoiceId,
      };

      DB.put(`${URI}/api/v1/${this.selectLanguage}/invoices/delete`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.loading = false;
          this.getInvoices();
          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    editInvoice(item) {
      this.$router.push("/invoices/" + item.sInvoiceId);
      // this.dialogEditInvoice = true;
      // this.forms.detail.sInvoiceId = item.sInvoiceId;
      // this.forms.detail.sId = item.sId;
      // this.forms.detail.dPurchaseDate = item.dPurchaseDate;
      // this.forms.detail.sPaymentRecipientId = item.sPaymentRecipientId;
      // this.forms.detail.sInvoice = item.sInvoice;
      // this.forms.detail.dTotalAmount = item.dTotalAmount;
      // this.forms.detail.dRateProtection = item.dRateProtection;
      // this.forms.detail.iCreditPeriod = item.iCreditPeriod;
    },
    openDialogDeleteInvoice(item) {
      this.dialogDelete = true;
      this.forms.delete.sInvoiceId = item.sInvoiceId;
    },
    convertInvoice() {
      this.loading = true;

      let payload = {
        invoices: this.forexDetail.invoices.map(invoice => invoice.sInvoiceId),
        sTransactionType: "e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a",
        dExchangeRate: this.payDetail.exchangeRateToday,
        referenceExchangeRate: this.sExchangeRateReference,
        bExternal: false,
        dAmountRemaining: this.forexDetail.totalUSD,
        dAmountRemainingMXN: this.forexDetail.totalAmountMxn
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/createTransactionForexFromInvoice`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.success) {
            this.mixError(response.data.message, response.data.status);
            this.getDashboard();
            this.loading = false;
          }
          else {
            this.loading = false;
            this.getInvoices();
            this.cancel();
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendForward() {
      this.loading = true;
      const allDatesSelected = this.forwardDetail.invoices.every(invoice => invoice.dForwardDate);
      const allRatesSelected = this.forwardDetail.invoices.every(invoice => invoice.dForwardExchangeRate);
      if (!allDatesSelected || !allRatesSelected) {
        this.mixError('Please select all dates and rates.', 400);
        this.loading = false;
        return;
      }
    
      let payload = {
        invoices: this.forwardDetail.invoices.map(invoice => ({
          sInvoiceId: invoice.sInvoiceId,
          sTransactionType: "b80e8dc6-4138-449e-8903-968efb8437b3",
          dExchangeRate: invoice.dForwardExchangeRate,
          referenceExchangeRate: invoice.sForwardExchangeRate,
          dForwardTransactionDate: invoice.dForwardDate,
        })),
        bExternal: false,
        sTransactionType: "b80e8dc6-4138-449e-8903-968efb8437b3",
        dAmountRemaining: this.forwardDetail.totalUSD * 0.05,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/createForwardTransactionFromInvoice`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          this.loading = false;
          this.getDashboard();
          this.cancel();
        })
        .catch((error) => {
          this.loading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    async validateOtp() {
      try {
            const payload = {
              sCode: this.otp
            };

        const response = await DB.post(`${URI}/api/v1/${this.selectLanguage}/authenticator/verify`, payload, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        });

          return response.data.success;
        } catch (error) {
          console.error('Error in validateOtp:', error);
          return false;
        }
    },
    async payInvoice() {
      this.loading = true;

      const isOtpValid = await this.validateOtp();

      if (!isOtpValid) {
        this.loading = false;
        this.mixError('Invalid code', 400);
        return;
      }
      const totalUSD = this.payDetail.invoices.reduce((sum, invoice) => sum + parseFloat(invoice.dTotalUsd), 0);
      let payload = {
        sInvoices: this.payDetail.invoices.map(invoice => ({
          sInvoiceId: invoice.sInvoiceId,
          dTotalUsd: invoice.dTotalUsd
        })),
        sExchangeRateReference: this.sExchangeRateReference,
        dExchangeRate: this.payDetail.exchangeRateToday,
        dAmountRemaining: totalUSD
      };

      try {
        const response = await DB.post(`${URI}/api/v1/${this.selectLanguage}/payments/invoice`, payload, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        });

        if (!response.data.success) {
          this.mixError(response.data.message, response.data.status);
          this.loading = false;
          return;
        }
      } catch (error) {
        this.mixError(error.response.data.message, error.response.status);
        this.loading = false;
        return;
      }

      this.loading = false;
      this.getDashboard();
      this.cancel();
    },
    cancel() {
      this.getDashboard();
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogSendPay = false;
      this.forwardActualRate = null;
      this.selectedCard = null;
      this.dialogEditInvoice = false;
      this.dialogConvert = false;
      this.dDateForward = null;
      this.dialogForex = false;
      this.dialogForward = false;
      this.selected = [];
      this.otp = '';
      this.e1 = 1;
      this.checkboxModel = false;
      this.forwardDetail.totalAmountPercentage = 0;
      this.forms.new = {
        dPurchaseDate: "",
        sPaymentRecipientId: "",
        sInvoice: "",
        dTotalAmount: "",
        dRateProtection: "",
        iCreditPeriod: "",
      };
      this.forms.detail = {
        sInvoiceId: "",
        dPurchaseDate: "",
        sPaymentRecipientId: "",
        sInvoice: "",
        dTotalAmount: "",
        dRateProtection: "",
        iCreditPeriod: "",
      };
    },
    cancelPayMxn() {
      this.dialogSendPayMxn = false;
      this.dialogDelete = false;
      this.dialogSendPay = false;
      this.selectedStatus = 1;
      this.forwardActualRate = null;
      this.selectedCard = null;
      this.dialogEditInvoice = false;
      this.dialogConvert = false;
      this.dDateForward = null;
      this.dialogForex = false;
      this.dialogForward = false;
      this.selected = [];
      this.otp = '';
      this.e1 = 1;
      this.checkboxModel = false;
      this.forwardDetail.totalAmountPercentage = 0;
      this.forms.new = {
        dPurchaseDate: "",
        sPaymentRecipientId: "",
        sInvoice: "",
        dTotalAmount: "",
        dRateProtection: "",
        iCreditPeriod: "",
      };
      this.forms.detail = {
        sInvoiceId: "",
        dPurchaseDate: "",
        sPaymentRecipientId: "",
        sInvoice: "",
        dTotalAmount: "",
        dRateProtection: "",
        iCreditPeriod: "",
      };
    },
    async getForwardRate(invoice) {
      this.loadingExchange = true;
      const payload = {
        dDateForward: invoice.dForwardDate,
        dAmount: invoice.dTotalAmount,
        referenceExchangeRate: this.sExchangeRateReference,
        bExternal: false,
        reverse: true,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/transactions/forwardRate`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then(async (response) => {
          this.loadingExchange = false;
          invoice.dForwardExchangeRate = response.data.forwardRate.rate;
          invoice.sForwardExchangeRate = response.data.forwardRate.uuid;
          invoice.dForwardAmountPercentage = response.data.forwardRate.initial_percentage_amount;
          invoice.dTotalUsd = await this.calculatorAmount(invoice.dTotalAmount, invoice.sForwardExchangeRate);
          this.updateAmountForwardMXN();
          const allDatesSelected = this.forwardDetail.invoices.every(invoice => invoice.dForwardDate);
          const allRatesSelected = this.forwardDetail.invoices.every(invoice => invoice.dForwardExchangeRate);
          this.allDatesSelected = allDatesSelected;
          this.allRatesSelected = allRatesSelected;
          invoice.modal = false;
        })
        .catch((error) => {
          this.loadingExchange = false;
          this.mixError(error.response.data.message, error.response.status);
          invoice.modal = false;
        });
    },
    updateAmountForwardMXN() {
      this.forwardDetail.totalUSD = this.forwardDetail.invoices.reduce((sum, invoice) => sum + parseFloat(invoice.dTotalUsd), 0);
      this.forwardDetail.totalAmountMxn = this.forwardDetail.invoices.reduce((sum, invoice) => sum + parseFloat(invoice.dTotalAmount), 0);
      this.forwardDetail.totalAmountPercentage = this.forwardDetail.invoices.reduce((sum, invoice) => sum + parseFloat(invoice.dForwardAmountPercentage), 0);
    },
    closeDatePicker(invoice) {
      invoice.modal = false;
      //this.getForwardRate(invoice);
    },
    getSuppliers: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/payment-recipient/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      }).then((response) => {
        this.suppliers = response.data.paymentRecipients;
      });
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    debounceGetInvoices() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getInvoices();
      }, 2300);
    },
    getInvoices() {
      this.isLoading = true;
      //this.invoices = [];
      //this.selected = [];

      const payload = {
        sPaymentRecipientId: this.selectedSupplier,
        sStatusId: this.status[this.selectedStatus].sStatusId,
        sId: this.selectedId
      };
      DB.post(`${URI}/api/v1/${this.selectLanguage}/invoices/invoices`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.items = response.data;
          this.isLoading = false;
          this.totalAmountMxn = response.data.totalAmount;
          this.startCountdownDashboard();
          this.invoices = this.items.invoices;
          this.invoices = this.items.invoices.map(invoice => {
              const totalAmount = invoice.invoice_payments_relationship.reduce((sum, invoice) => {
                      return sum + (parseFloat(invoice.dAmount) || 0);
                  }, 0)
              invoice.totalAmount = totalAmount;
              return {
                  ...invoice,
              };
          });
        })
        .catch((error) => {
          this.isLoadingSuppliers = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getTransactions() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      }).then((response) => {
        this.transactions = response.data.transactions;
      });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(formattedNumber);
    },
    getContacts: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/contacts-wallet/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.contacts = response.data.contacts;
          this.contacts.push({
            id: 0,
            name: "Crear nuevo contacto",
          });
        })
        .catch((error) => {
          // Handle error if needed
        });
    },
    openModalAddFounds() {},
    openModalSendPay() {
      this.dialogSendPay = true;
    },
    openModalAddFoundss() {
      this.dialogAddFoundss = true;

      const payload = {
        amount: 100,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/exchange-rate/addFounds`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.dataAddFounds = response.data.account;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    closeModalAddFounds() {
      this.dialogAddFounds = false;
    },
    toggleAll: function (checked) {
      this.exchangeRateData.forEach((item) => {
        if (
          item.statusName !== "WAITING_FOR_DEPOSIT" ||
          item.statusName !== "PROCESSING"
        ) {
          item.bSelected = checked;
        }
      });
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
    resetForm() {
      this.editableData = {
        dPurchaseDate: "",
        sProvider: "",
        sInvoiceNumber: "",
        dTotalAmount: "",
        iCreditPeriod: "",
        dInvoiceExchangeRateProtection: "",
      };
    },
    openModalCreate() {
      this.dialogCreate = true;
      this.resetForm();
    },
    validateForm: function () {
      return true;
    },
    saveChanges: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(this.editableData.dPurchaseDate);

      const payload = {
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        createContact: this.showCreateProvider,
        contactData: this.contactData,
        dInvoiceExchangeRateProtection: this.editableData.dInvoiceExchangeRateProtection,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/exchange-rate/`, payload, config)
        .then((response) => {
          this.bLoading = false;
          this.dialogCreate = false;
          this.mixSuccess(response.data.message);
          // this.getExchangeRate();
          this.getDashboard();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    closeDatePicker(invoice) {
      invoice.modal = false;
    },
    formatDateToSend: function (date) {
      let newDate = new Date(date);
      let day = newDate.getDate() + 1;
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },
    changebSelectedAll: function () {
      this.bSelectedAll = !this.bSelectedAll;
    },
    closeModalCreate() {
      this.dialogCreate = false;
      this.showCreateProvider = false;
      this.contactData = {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY",
      };
    },
    sendToPayNow: function () {
      this.isDeferredPayment = false;
      this.sendToPay();
    },
    sendToPay: function () {
      this.itemsSelected = this.exchangeRateData.filter((item) => item.bSelected);
      if (this.itemsSelected.length > 0) {
        this.stepper = true;
        this.itemsSelected.forEach((item) => {
          item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          this.totalUsd += item.dTotalUsdToday;
        });
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    actualizarE1(nuevoValor) {
      this.e1 = nuevoValor;
    },
    closeModalPay() {
      this.dialogPay = false;
      this.totalUsd = 0;
      this.e1 = 1;
    },
    sendToPayNowMxn: function () {
      this.isDeferredPayment = false;
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRateDataSeparate.filter((item) => item.bSelected);
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.dialogPay = true;
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    startCountdownDashboard() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
      this.countdownDashboard = 60;
      this.intervalId = setInterval(() => {
        if (this.countdownDashboard > 0) {
          this.countdownDashboard--;
        } else {
          clearInterval(this.intervalId);
          if (
            this.dialogForward == false &&
            this.dialogDelete == false &&
            this.dialogSendPay == false &&
            this.dialogEditInvoice == false &&
            this.dialogConvert == false &&
            this.dialogForex == false
          ) {
            this.getInvoices();
          }
        }
      }, 1000);
    },
    sendToPayNowForwards: function () {
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRatesStateForwards.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.sendPayEfex();
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    closeModalStepper() {
      this.stepper = false;
      this.totalUsd = 0;
      this.e1 = 1;
      this.isForwards = false;
      this.isForwardsPayment = false;
      //this.getActualCurrency();
    },
    changeDeferredPayment() {
      this.isDeferredPayment = !this.isDeferredPayment;
    },
    changePayMxn() {
      this.isPayMxn = !this.isPayMxn;
    },
    sendPayEfex: function () {
      this.bLoading = true;

      if (this.isPayMxn) {
        this.totalUsd = this.totalUsd * this.actualCurrency;
      }

      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        actualCurrency: this.actualCurrency,
        isDeferredPayment: this.isDeferredPayment,
        isPayMxn: this.isPayMxn,
        totalUsd: this.totalUsd,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayment/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogPay = false;
          this.defferedPayment = false;
          // this.getExchangeRate();
          this.getDashboard();
          this.totalUsd = 0;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.data.message, error.response.status);
        });
    },
    openReceipt: function (item) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getPaymentRecipt/${item.paymentExchangeRate[0].sPayment_reference}/`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (response.data && response.data.payment) {
            let base64 = response.data.payment.data;

            const base64Pattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
            if (base64Pattern.test(base64)) {
              base64 = base64.split(",")[1];
            }

            try {
              const binaryString = window.atob(base64);
              const bytes = new Uint8Array(binaryString.length);
              for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              const blob = new Blob([bytes], { type: "application/pdf" });

              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
            } catch (e) {}
          } else {
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendSeparateEfex: function () {
      this.bLoading = true;
      const payload = {
        exchangeRateId: this.itemsSelected,
        rateUuid: this.referenceCurrency,
        rateInt: this.actualCurrency,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/separateExchangeRate/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogSeparate = false;
          // this.getExchangeRate();
          this.getDashboard();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    toggleAllSeparated: function (checked) {
      this.exchangeRateDataSeparate.forEach((item) => {
        item.bSelected = checked;
      });
    },
    toggleAllForwards: function (checked) {
      this.exchangeRatesStateForwards.forEach((item) => {
        this.$set(item, "bSelected", checked);
      });
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/sub-account/`, payload, config)
        .then((response) => {
          this.mixSuccess(response.data.message);
          // this.getExchangeRate();
          this.getDashboard();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openModal(item) {
      this.totalUsd = 0;
      this.selectedItem = item;
      this.editableData = { ...this.selectedItem };
      this.dialogEditProfileActive = true;
    },
    closeDialog() {
      this.dialogEditProfileActive = false;
    },
    backStep() {
      this.e1 = 1;
    },
    updateExchangeRate: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(this.editableData.dPurchaseDate);
      const payload = {
        sExchangeRateId: this.selectedItem.sExchangeRateId,
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dTotalAmount: parseFloat(this.editableData.dTotalAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        dInvoiceExchangeRateProtection: this.editableData.dInvoiceExchangeRateProtection,
        dAmountAdjustment: parseFloat(this.editableData.dAmountAdjustment),
        sCurrencyAdjustment: "MXN",
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/${this.selectedItem.sExchangeRateId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogEditProfileActive = false;
          this.mixSuccess(response.data.message);
          // this.getExchangeRate();
          this.getDashboard();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeForwards: function (value) {
      this.isForwards = value;
    },
    getForwards: function () {
      this.bLoading = true;
      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        reverse: true,
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getRateForwards/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
            this.bLoading = false;
            this.isForwardsPayment = false;
          } else {
            this.mixSuccess(response.data.message);
            this.bLoading = false;
            this.isForwardsPayment = true;
            this.isForwardRateCalculated = response.data.isForwardRateCalculated;
            this.itemsSelected = response.data.responsePaysEfex;
          }
          //this.getExchangeRate();
          this.getDashboard();
        })
        .catch((error) => {
          this.bLoading = false;
          this.isForwardsPayment = false;
          this.mixError(error.data.message, 400);
        });
    },
    sendForwardPayment: function () {
      const payload = {
        pays: this.itemsSelected,
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayForward/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.stepper = false;
          this.defferedPayment = false;
          // this.getExchangeRate();
          this.getDashboard();
          this.totalUsd = 0;
          this.isForwardsPayment = false;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeModalCurrency: function (value) {
      this.modalCurrency = value;
    },
    updateTotalUsd: function (value) {
      this.totalUsd = value;
    },
    onlyWeekdays(date) {
      const day = new Date(date).getDay();
      return day !== 5 && day !== 6;
    },
    updateHeaders() {
      this.headersInvoices = [
        { text: this.texts.invoices.supplier, value: "sName", align: "center" },
        { text: this.texts.invoices.purchaseDate, value: "dPurchaseDate", align: "center" },
        { text: this.texts.invoices.dueDate, value: "dDueDate", align: "center" },
        { text: this.texts.invoices.invoice, value: "sInvoice", align: "center" },
        { text: this.texts.invoices.sId, value: "sId", align: "center" },
        { text: this.texts.invoices.status, value: "sStatusId", align: "center" },
        { text: this.texts.invoices.totalAmountMXN, value: "dTotalAmount", align: "center" },
        { text: this.texts.invoices.rateProtection, value: "dRateProtection", align: "center" },
        { text: this.texts.invoices.profitPercentage, value: "dProfitPercentage", align: "center" },
        { text: this.texts.invoices.currencyGain, value: "dCurrencyGain", align: "center" },
        { text: this.texts.invoices.actions, value: "actions", align: "center" }
      ];
      this.headersInvoicesConverted = [
        { text: this.texts.invoices.supplier, value: "sName", align: "center" },
        { text: this.texts.invoices.purchaseDate, value: "dPurchaseDate", align: "center" },
        { text: this.texts.invoices.dueDate, value: "dDueDate", align: "center" },
        { text: this.texts.invoices.invoice, value: "sInvoice", align: "center" },
        { text: this.texts.invoices.sId, value: "sId", align: "center" },
        { text: this.texts.invoices.status, value: "sStatusId", align: "center" },
        { text: this.texts.invoices.avaible, value: "sTransactionAvaible", align: "center" },
        { text: this.texts.invoices.totalAmountMXN, value: "dTotalAmount", align: "center" },
        { text: this.texts.invoices.rateProtection, value: "dRateProtection", align: "center" },
        { text: this.texts.invoices.profitPercentage, value: "dProfitPercentage", align: "center" },
        { text: this.texts.invoices.currencyGain, value: "dCurrencyGain", align: "center" },
        { text: this.texts.invoices.actions, value: "actions", align: "center" }
      ];
    },
    dataXLSX() {
      try {
        let data = this.invoices.map((item) => {
          return {
            Supplier: item.sName ? item.sName : "",
            "Purchase date": item.created_at ? this.formatDate(item.created_at) : "",
            "Due date": item.dDueDate ? this.formatDate(item.dDueDate) : "N/A",
            Invoice: item.sInvoice ? item.sInvoice : "",
            "Purchase Order": item.sId ? item.sId : "",
            "Status": item.sStatusId == '453f47d8-d5ad-4159-be49-b48b0f04caa5' ? 'To Pay' : (item.sStatusId == '904a91ab-bdfc-45dc-a72f-fc02c2a15005' ? 'Converted' : (item.sStatusId == 'f42d5b71-bcc8-4c2c-997a-40ad2da2bc31' ? 'Paid' : "N/A")),
            "Total amount (MXN)": item.dTotalAmount ? parseFloat(item.dTotalAmount) : "",
            "Exchange rate protection": item.dRateProtection ? item.dRateProtection : "",
            "Currency profit (%)": item.dProfitPercentage ? parseFloat(item.dProfitPercentage) : "",
            "Currency gain (USD)": item.dCurrencyGain ? parseFloat(item.dCurrencyGain) : "",
          };
        });
        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        saveAs(blob, this.nameXLSX);
        this.success = true;
        this.msgSuccess = "Descargando documento";
      } catch (error) {
        this.error = true;
        this.codeError = "CSV_NO_DATA";
      }
    },
    checkTime() {
      this.currentTime = this.currentDate.format("MMMM DD, YYYY, HH:mm:ss [GMT]Z");
      this.currentTimeCdmx = this.currentDateCdmx.format("MMMM DD, YYYY, HH:mm:ss [GMT-6]");    
      const currentHourCdmx = this.currentDateCdmx.hour();
      const currentDayCdmx = this.currentDateCdmx.day();
      if (currentDayCdmx === 6 || currentDayCdmx === 0) {
        this.isDisabledForward = true;
      } else if (currentHourCdmx >= 9 && currentHourCdmx < 16) {
        this.isDisabledForward = false;
      } else {
        this.isDisabledForward = true;
      }
    }
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.updateHeaders();
    this.getSuppliers();
    // this.getExchangeRate();
    this.checkTime();
    this.getDashboard();
    this.startCountdownDashboard();
    this.today = moment().add(1, "day").format("YYYY-MM-DD");
    this.limit = moment(this.today).add(1, "year").format("YYYY-MM-DD");
  },
  beforeDestroy() {
    // Limpia el intervalo cuando el componente se destruye
    clearInterval(this.intervalId);
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
        this.updateHeaders();
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    },
  },
};

</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #193D6E !important;
  height: 3px;
  color: #193D6E !important;
  border-radius: 100px;
}

.card-main {
  width: 40vh;
  height: 16vh;
  position: relative;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #193D6E;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #193D6E;
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.selected-card {
  border: 2px solid #0075B8 !important;
}
.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #193D6E;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.test {
  border-radius: 100px !important;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}
</style>
