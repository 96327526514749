<template>
  <div class="pt-4">
    <v-container fluid class="px-15">
      <v-row class="ma-0 mb-5">
        <div v-if="!isLoading" class="ma-4">
          <v-col cols="12" class="pa-0">
            <div class="headline-medium text-primary">{{ texts.conversions.title }}</div>  
          </v-col>
          <v-col cols="12" class="pa-0">
            <div class="body-large text-secondary">
              {{ texts.conversions.description }}
            </div>
          </v-col>
        </div>
        <v-skeleton-loader
          v-else
          class="mx-3 my-3"
          height="200px"
          width="400px"
          type="image"
        ></v-skeleton-loader>
        <v-spacer></v-spacer>
        <v-card
          v-if="!isLoading"
          class="card-main mx-3 my-3"
          style="border: 1px solid; height: fit-content"
          width="350px"
          elevation="4"
          outlined
        >
          <v-card-text class="pt-6 pb-0">
            <v-row align="center">
              <v-col cols="9" class="pl-5">
                <div class="usd-balance">{{ texts.conversions.balance }}</div> 
                <div class="content-balance pt-3">
                  ${{ formatMoney(balanceMxn ? balanceMxn : 0) }} MXN
                </div>
              </v-col>
              <v-col cols="3" class="text-right pr-5">
                <v-img
                  src="../../assets/images/Flags_mex.png"
                  alt="mex-flag"
                  width="50"
                  height="30"
                />
              </v-col>
            </v-row>
            <div class="pl-3 pt-2 blocked-amount">
              {{ texts.conversions.assigned + ": " }}
              <span class="blocked-amount-2">${{ formatMoney(balanceMxn ? balanceMxn - avaibleAmount : 0) }}</span>
            </div>
            <div class="pl-3 pt-2 blocked-amount">
              {{ texts.conversions.avaible + ": " }}
              <span class="blocked-amount-2">${{ formatMoney(avaibleAmount ? avaibleAmount : 0) }}</span>
            </div>
            <div class="blocked-amount" style="display: flex; justify-content: flex-end;">
              <v-btn 
                color="#0075B8"
                class="btn-custom my-4 mx-4 mr-0"
                elevation="2"
                outlined
                @click="openPayNowMXN()"
              >
                <v-icon left dark color="#0075B8"
                  >mdi-arrow-top-right-thin</v-icon
                >
                <span class="buttonAddFounds">{{ texts.dashboard.sendPay }}</span>
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
        <v-skeleton-loader
          v-else
          class="mx-3 my-3"
          width="300px"
          type="image"
        ></v-skeleton-loader>
      </v-row>
      <v-card v-if="!isLoading" class="rounded-xl elevation-5">
        <v-toolbar dense dark color="#142e53" elevation="2">
          <v-toolbar-title>{{ texts.conversions.title }}</v-toolbar-title> 
          <v-spacer></v-spacer>
          <v-btn text small @click="dataXLSX()">
            <h4 style="font-family: 'montserrat-bold';">{{ texts.conversions.download }}</h4> 
            <v-icon right>mdi-microsoft-excel</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title>
          <!-- <div style="background: #ffffff; box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); border-radius: 10px; padding: 15px; border: 1px solid #C5CDD3; font-family: 'montserrat-bold';">
            {{ texts.conversions.totalCurrencyGain }}
            <span class="ml-2" style="font-weight: bold;" :style="{ color: totalProfit < 0 ? 'red' : 'green'}"> 
              {{ " $" + formatMoney(totalProfit) + " USD" }}
            </span>
          </div> -->
          <v-spacer></v-spacer>
          <v-btn
            style="
              text-align: center;
              color: #0075B8;
              font-size: 15px;
              background-color: transparent;
              font-family: montserrat-bold;
              font-weight: 600;
              line-height: 24px;
              text-transform: capitalize;
              word-wrap: break-word;
              border-radius: 5px;
              border: 1px solid #0075B8;
              padding: 8px 16px;
            "
            elevation="0"
            class="ml-2"
            :disabled="selected.length == 0"
            @click="openPayNow(selected)"
          >
            <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
            {{ texts.conversions.pay }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-spacer></v-spacer>
          <v-data-table
            show-select
            single-select
            item-key="sTransactionId"
            v-model="selected"
            :headers="headersForex"
            :items="items"
          >
            <template v-slot:item.sTransactionType="{ item }">
              <v-chip
                v-if="item.sTransactionType == 'b80e8dc6-4138-449e-8903-968efb8437b3'"
                class="ma-2"
                color="red"
                outlined
              >
                {{ "Forward" }}
              </v-chip>
              <v-chip
                v-else-if="
                  item.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a'
                "
                class="ma-2"
                color="blue"
                outlined
              >
                {{ "Forex" }}
              </v-chip>
            </template>
            <template v-slot:item.iTrackingId="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                {{ item.iTrackingId }}
              </h4>
            </template>
            <template v-slot:item.sSupplier="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                {{ item.sSupplier ? item.sSupplier : "N/A" }}
              </h4>
            </template>
            <template v-slot:item.dPercentAssigned="{ item }">
              <v-progress-linear
                :value="item.percent"
                height="15"
                style="border-radius: 15px"
              >
                <h4 class="py-3" style="color: white">{{ Math.ceil(item.percent) }}%</h4>
              </v-progress-linear>
            </template>
            <template v-slot:item.dAmount="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                $ {{ formatMoney(item.dAmount) }} USD
              </h4>
            </template>
            <template v-slot:item.dDesAmount="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                $ {{ formatMoney(item.dDesAmount) }} MXN
              </h4>
            </template>
            <template v-slot:item.dExchangeRate="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                $ {{ formatMoney(item.dExchangeRate) }} MXN
              </h4>
            </template>
            <template v-slot:item.invoice="{ item }">
              <div v-if="item.invoice.length > 0">
                <h4
                  style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                  v-for="(item, idx) in item.invoice"
                >
                  {{ item.invoice.sInvoice }}
                </h4>
              </div>
              <div v-else>
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  {{ "N/A" }}
                </h4>
              </div>
            </template>
            <template v-slot:item.created_at="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                {{ formatDate(item.created_at) }}
              </h4>
            </template>
            <template v-slot:item.invoices="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.invoices ? item.invoices : "N/A" }}</h4>
            </template>
            <template v-slot:item.purchaseDate="{ item }">
              <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.purchaseDate ? item.purchaseDate : "N/A" }}  </h4>
             </template>
            <template v-slot:item.percentProfit="{ item }">
              <h4
                v-if="item.percentProfit"
                :style="{ color: item.percentProfit < 0 ? 'red' : 'green' }"
                style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
              >
                {{ item.percentProfit ? formatMoney(item.percentProfit) + "%" : "N/A" }}
              </h4>
              <h4 v-else>{{ "N/A" }}</h4>
            </template>
            <template v-slot:item.dollarProfit="{ item }">
              <h4
                v-if="item.dollarProfit"
                :style="{ color: item.dollarProfit < 0 ? 'red' : 'green' }"
                style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
              >
                {{
                  item.dollarProfit ? "$ " + formatMoney(item.dollarProfit) + " USD" : ""
                }}
              </h4>
              <h4 v-else>{{ "N/A" }}</h4>
            </template>
            <template v-slot:item.actions="{ item }">
              <div style="display: flex; align-items: center; justify-content: center">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="openDialog(item)"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="black"
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar conversión</span>
                </v-tooltip>
                <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="red">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete item</span>
                </v-tooltip> -->
              </div>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-skeleton-loader
        v-else
        class="mx-3 my-3"
        height="500px"
        type="image"
      ></v-skeleton-loader>
    </v-container>
    
    <v-dialog v-model="dialogSendPayOfInvoice" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-stepper v-model="step" alt-labels elevation="0">
          <v-stepper-header
            color="#193D6E"
            class="mt-5 mr-15 ml-15"
            elevation="0"
            style="justify-content: center; align-items: center; justify-items: center; align-content: center; box-shadow: none;"
          >
            <v-stepper-step :complete="step > 1" step="1" color="#193D6E"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2" color="#193D6E"></v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Paso 1: Detalle de pago -->
            <v-stepper-content step="1" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center">
                  <span class="title font-weight-bold text-textblack"> Detalle de pago </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="!loading">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                      flex-direction: column;
                      width: 600px;
                    "
                  >
                    <div
                      class="pt-6 pb-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        Nombre del cliente:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold">
                        {{ conversionPayment.detail.supplier.sName }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">Número CLABE:</div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold">
                        {{ "********" + sAccountNumber }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        El destinatario recibirá:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold">
                        ${{ formatMoney(conversionPayment.detail.dAmount) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        Tipo de cambio:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold">
                        ${{
                          formatMoney(conversionPayment.detail.conversion[0]?.dExchangeRate)
                        }}
                        MXN
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                      v-if="conversionPayment.detail.conversion[0]?.invoice.length > 0"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        Factura a pagar:
                      </div>
                      <div
                        class=""
                        style="
                          color: black;
                          font-size: 22px;
                          font-weight: bold;
                          display: flex;
                          gap: 10px;
                        "
                      >
                        <div
                          v-for="(item, idx) in conversionPayment.detail.conversion[0].invoice"
                        >
                          {{ item.invoice.sInvoice }}
                        </div>
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        Fecha de envío:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold">
                        {{ formatDate(today) }}
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <v-textarea
                        name="input-7-1"
                        label="Concepto de pago"
                        v-model="comment"
                        no-resize
                      ></v-textarea>
                    </div>
                  </div>
                </v-card-text>
                <div v-else style="display: flex; justify-content: center">
                  <v-progress-circular indeterminate color="amber"></v-progress-circular>
                </div>
                <div v-if="!loading" style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    class="button-cancel mon-regular"
                    @click="cancelDialog()"
                    :disabled="loading"
                  >
                    {{ texts.modalForm.cancel }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                    elevation="0"
                    :disabled="!comment || loading"
                    @click="sendVerification(2)"
                    >Continuar
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>

            <!-- Paso 2: Verificación OTP -->
            <v-stepper-content step="2" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
                  <span class="title font-weight-bold text-textblack">{{ texts.twoFactor.twoFactor }}</span>
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <div class="pt-4 px-4 mb-4" style="display: flex; justify-content: space-around; flex-direction: row; width: 600px">
                    <div>
                      <v-otp-input v-model="otp" length="6" type="number"></v-otp-input>
                      <p style="font-size: 14px; font-weight: 500;">{{ texts.twoFactor.enterCode }}</p>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px;">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="step = 1" :disabled="loading">{{ texts.modalForm.cancel }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="button-save"
                    style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                    elevation="0"
                    :disabled="otp.length < 6 || loading"
                    color="#193D6E"
                    @click="sendPayment()">
                    {{ texts.modalForm.confirmPay }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDetailConversion" max-width="1200">
      <v-card v-if="!loadingTransaction">
        <v-card-title
          class="pa-0"
          style="display: flex; text-align: center; justify-content: center"
        >
          <h3 class="font-weight-bold my-4">{{ texts.conversions.detail }}</h3>    
        </v-card-title>
        <v-card-text class="pb-5 px-10">
          <div
            class="my-8"
            style="display: flex; justify-content: space-around; flex-direction: column"
          >
            <div style="display: flex; justify-content: space-between">
              <div>
                <div style="color: black; font-size: 20px">{{ texts.conversions.conversionDetailType }}</div> 
                <div style="color: black; font-size: 22px; font-weight: bold">
                  {{
                    conversion.sTransactionType == "e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a"
                      ? "Forex"
                      : "Forward"
                  }}
                </div>
              </div>
              <div>
                <div style="color: black; font-size: 20px; text-align: center">{{ texts.conversions.conversionDetailAmount }}</div> 
                <div style="color: black; font-size: 22px; font-weight: bold">
                  {{ "$ " + formatMoney(conversion.dDesAmount) + " MXN" }}
                </div>
              </div>
              <template v-if="conversion.invoice">
                <div v-if="conversion.invoice.length > 0">
                  <div style="color: black; font-size: 20px">{{ texts.conversions.conversionDetailProfit }}</div>
                  <div style="color: green; font-size: 22px; font-weight: bold">
                    {{ formatMoney(conversion.percentProfit) + "%" }}
                  </div>
                  <div style="color: green; font-size: 22px; font-weight: bold">
                    {{ formatMoney(conversion.dollarProfit) + " USD" }}
                  </div>
                </div>
                <div v-else></div>
              </template>
            </div>
          </div>
          <div
            class="my-8"
            style="display: flex; justify-content: space-around; flex-direction: column"
          >
            <div style="display: flex; justify-content: space-between">
              <div>
                <div style="color: black; font-size: 20px" v-if="conversion.invoice">
                  {{ texts.conversions.conversionDetailProvider }}
                  <span
                    style="color: black; font-size: 22px; font-weight: bold"
                    v-if="conversion.invoice.length > 0"
                  >
                    {{ conversion.invoice[0].invoice.paymentsRecipent[0].sName }}</span
                  >
                </div>
              </div>
              <div>
                <div style="color: black; font-size: 20px">
                  {{ texts.conversions.conversionDetailExchangeRate }}
                  <span style="color: black; font-size: 22px; font-weight: bold">
                    {{ "$ " + conversion.dExchangeRate + " MXN" }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="my-8"
            style="display: flex; justify-content: space-around; flex-direction: column"
          >
            <div style="display: flex; justify-content: space-between">
              <div>
                <div style="color: black; font-size: 20px">
                  {{ texts.conversions.conversionDetailCreationDate }}
                  <span style="color: black; font-size: 22px; font-weight: bold">
                    {{ formatDate(conversion.created_at) }}</span
                  >
                </div>
              </div>
              <div>
                <div
                  style="color: black; font-size: 20px"
                  v-if="conversion.dForwardTransactionDate"
                >
                  {{ texts.conversions.fulfillmentDate }}
                  <span style="color: black; font-size: 22px; font-weight: bold">
                    {{ formatDate(conversion.dForwardTransactionDate) }}</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="mb-8">
            <div class="mb-4" style="color: black; font-size: 22px; font-weight: bold">
                {{ texts.conversions.conversionDetailPercent }}
            </div>
            <v-progress-linear height="45" :value="conversion.percent">
              <strong style="color: white">{{ formatMoney(conversion.percent) }}%</strong>
            </v-progress-linear>
          </div>
          <div>
            <div
              class="mb-6"
              style="
                display: flex;
                justify-content: space-around;
                color: black;
                font-size: 22px;
                font-weight: bold;
              "
            >
              <div>{{ texts.conversions.conversionDetailAddInvoices }}</div>
              <v-spacer></v-spacer>
              <v-autocomplete
                :label="texts.conversions.supplierName"
                required
                :items="suppliers"
                :disabled="conversion.invoice.length > 0"
                class="pt-0 mt-0"
                v-if="active"
                item-value="sPaymentRecipientId"
                item-text="sName"
                @change="getInvoicesWithSupplier(sPaymentRecipientId)"
                v-model="sPaymentRecipientId"
                :rules="[rules.required]"
              ></v-autocomplete>
              <v-spacer></v-spacer>
              <v-btn
                style="
                  text-align: center;
                  color: #0075B8;
                  font-size: 15px;
                  background-color: transparent;
                  font-family: montserrat-bold;
                  font-weight: 600;
                  line-height: 24px;
                  text-transform: capitalize;
                  word-wrap: break-word;
                  border-radius: 5px;
                  border: 1px solid #0075B8;
                  padding: 8px 16px;
                "
                elevation="0"
                @click="updateInvoice()"
                class="ml-2"
                :disabled="conversion.percent == 100 || loadingTransaction"
              >
                <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
                {{ texts.conversions.conversionDetailAddInvoices }}
              </v-btn>
            </div>
            <div
              v-if="conversion.invoice"
              style="display: flex; justify-content: flex-start; gap: 30px"
            >
              <v-card
                :disabled="active"
                class="mb-4 mx-4"
                style="width: 340px"
                v-for="(item, idx) in conversion.invoice"
                :key="idx"
              >
                <v-card-title>
                  <h3>{{ texts.conversions.conversionDetailInvoice }} {{ item.invoice.sInvoice }}</h3>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="openInvoice(item)"
                        :disabled="false"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="black"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ texts.conversions.conversionDetailModifyInvoice }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="deleteInvoice(item)"
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="red"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ texts.conversions.conversionDetailDeleteInvoice }}</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <div
                    class="mt-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      flex-direction: column;
                    "
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        align-items: flex-start;
                        flex-direction: column;
                      "
                    >
                      <div class="my-2">
                        <div style="color: black; font-size: 20px">{{ texts.conversions.conversionDetailAmount }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold">
                          {{ "$ " + formatMoney(item.dAmount) + " MXN" }}
                        </div>
                      </div>
                      <div class="my-2">
                        <div style="color: black; font-size: 20px">{{ texts.conversions.purchaseDate }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold">
                          {{ formatDate(item.invoice.dPurchaseDate) }}
                        </div>
                      </div>
                      <div class="my-2">
                        <div style="color: black; font-size: 20px; text-align: center">
                          {{ texts.conversions.conversionDetailExchangeRateProtected }}
                        </div>
                        <div style="color: black; font-size: 22px; font-weight: bold">
                          {{ "$ " + formatMoney(item.invoice.dRateProtection) + " MXN" }}
                        </div>
                      </div>
                      <div
                        class="my-2"
                        style="display: flex; width: 100%; justify-content: space-around"
                      >
                        <div>
                          <div class="mb-2" style="color: black; font-size: 20px">
                            {{ texts.conversions.conversionDetailProfit }} (%)
                          </div>
                          <div
                            :style="{
                              color: item.percentProfit < 0 ? 'red' : 'green',
                            }"
                            style="color: black; font-size: 22px; font-weight: bold"
                          >
                            {{ formatMoney(item.percentProfit) + "%" }}
                          </div>
                        </div>
                        <div>
                          <div class="mb-2" style="color: black; font-size: 20px">
                            {{ texts.conversions.conversionDetailProfit }}
                          </div>
                          <div
                            :style="{
                              color: item.dollarProfit < 0 ? 'red' : 'green',
                            }"
                            style="color: black; font-size: 22px; font-weight: bold"
                          >
                            {{ "$ " + formatMoney(item.dollarProfit) + " USD" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
              <v-card
                v-if="!isLoadingInvoices"
                class="mb-4"
                v-for="(item, idx) in avaibleInvoices"
                :key="idx"
                style="background-color: #f3f3f369"
              >
                <v-card-title>
                  <div style="display: flex; flex-direction: column; width: 200px">
                    <h3>{{ texts.conversions.invoice }} {{ item.sInvoice }}</h3> 
                  </div>
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="
                          text-align: center;
                          color: #0075B8;
                          font-size: 15px;
                          background-color: transparent;
                          font-family: montserrat-bold;
                          font-weight: 600;
                          line-height: 24px;
                          text-transform: capitalize;
                          word-wrap: break-word;
                          border-radius: 5px;
                          border: 1px solid #0075B8;
                          padding: 8px 16px;
                        "
                        elevation="1"
                        @click="addInvoice(item)"
                        class="ml-2"
                      >
                        <v-icon left>mdi-plus</v-icon>
                        {{ texts.conversions.addInvoice }}
                      </v-btn>
                    </template>
                    <span>{{ texts.conversions.addInvoiceToInvoice }}</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text>
                  <div
                    class="mt-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      flex-direction: column;
                    "
                  >
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        align-items: flex-start;
                        flex-direction: column;
                      "
                    >
                      <div class="my-2">
                        <div style="color: black; font-size: 20px">{{ texts.conversions.amount }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold">
                          {{ "$ " + formatMoney(item.dTotalAmount) + "MXN" }}
                        </div>
                      </div>
                      <div class="my-2">
                          <div style="color: black; font-size: 20px">{{ texts.conversions.purchaseDate }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold">
                          {{ formatDate(item.dPurchaseDate) }}
                        </div>
                      </div>
                      <div class="my-2">
                        <div style="color: black; font-size: 20px; text-align: center">
                          {{ texts.conversions.exchangeRateProtected }}
                        </div>
                        <div style="color: black; font-size: 22px; font-weight: bold">
                          {{ "$ " + formatMoney(item.dRateProtection) + " MXN" }}
                        </div>
                      </div>
                      <div
                        class="my-2"
                        style="display: flex; width: 100%; justify-content: space-around"
                      >
                        <div>
                          <div class="mb-2" style="color: black; font-size: 20px">
                            {{ texts.conversions.profit }} (%)
                          </div>
                          <div
                            :style="{
                              color: item.dProfitPercentage < 0 ? 'red' : 'green',
                            }"
                            style="color: black; font-size: 22px; font-weight: bold"
                          >
                            {{ formatMoney(item.dProfitPercentage) + "%" }}
                          </div>
                        </div>
                        <div>
                          <div class="mb-2" style="color: black; font-size: 20px">
                            {{ texts.conversions.profit }}
                          </div>
                          <div
                            :style="{
                              color: item.dCurrencyGain < 0 ? 'red' : 'green',
                            }"
                            style="color: black; font-size: 22px; font-weight: bold"
                          >
                            {{ "$ " + formatMoney(item.dCurrencyGain) + " USD" }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <div style="display: flex; justify-content: end; margin-top: 20px">
            <v-btn elevation="0" class="button-cancel mon-regular" @click="cancel()">
              {{ texts.modalForm.cancel }} 
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="button-save"
              style="max-width: 200px; color: #FFF; background-color: #193D6E;"
              elevation="0"
              color="#193D6E"
              :disabled="conversion.percent == 0 || loadingTransaction"
              @click="openPayInvoices(conversion)"
              >{{ texts.conversions.payInvoices }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-skeleton-loader v-else class="mx-auto" type="image"></v-skeleton-loader>
    </v-dialog>
    <v-dialog v-model="dialogSendPay" persistent width="fit-content" class="pa-8">
      <v-card>
        <template v-if="!haveInvoice">
          <v-stepper v-model="step" alt-labels elevation="0">
            <v-stepper-header
              color="#193D6E"
              class="mt-5 mr-15 ml-15"
              elevation="0"
              style="justify-content: center; align-items: center; justify-items: center; align-content: center; box-shadow: none;"
            >
              <v-stepper-step :complete="step > 1" step="1" color="#193D6E"></v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2" color="#193D6E"></v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 3" step="3" color="#193D6E"></v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 4" step="4" color="#193D6E"></v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <!-- Paso 1: Seleccionar contacto -->
              <v-stepper-content step="1" elevation="0" class="pt-0">
                <v-card-title style="display: flex; text-align: center; justify-content: center">
                  <span class="title font-weight-bold text-textblack">{{ texts.conversions.selectContact }}</span>
                </v-card-title>
                <v-card-text class="py-0">
                  <div class="mb-4">
                    <v-list rounded v-if="!isLoadingSuppliers">
                      <v-autocomplete
                        :items="suppliers"
                        filled
                        label="Contactos"
                        item-text="sNameAC"
                        return-object
                        v-model="selectedSupplier"
                        @change="selectSupplier(selectedSupplier)"
                        rounded
                      ></v-autocomplete>
                    </v-list>
                    <v-skeleton-loader v-else class="mx-auto" type="image"></v-skeleton-loader>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="cancel()">{{ texts.modalForm.cancel }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="button-save"
                    style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                    elevation="0"
                    color="#193D6E"
                    @click="step = 2">
                    {{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <!-- Paso 2: Detalle de pago -->
              <v-stepper-content step="2" class="pt-0">
                <v-container class="mt-1">
                  <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
                    <span class="title font-weight-bold text-textblack">{{ texts.conversions.paymentDetail }}</span>
                  </v-card-title>
                  <v-card-text class="py-6 pb-0" v-if="!loading" style="width: 500px;">
                    <div class="mb-8" style="display: flex; justify-content: space-around; background-color: #e2e8f0;">
                      <div class="py-8 px-10">
                        <div class="" style="color: black; font-size: 16px;">
                          {{ texts.conversions.avaible }}
                        </div>
                        <div class="" style="color: black; font-weight: bold; font-size: 22px;">
                          ${{ formatMoney(conversionPayment.detail.dAmount) }} MXN
                        </div>
                      </div>
                    </div>
                    <v-text-field
                      outlined
                      :label="texts.dashboard.sendAmount"
                      type="number"
                      persistent-placeholder
                      v-model="amountForexMXN"
                      prefix="$"
                      class="mt-10"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important;">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_mex.png"
                            alt=""
                            style="border-radius: 10px"
                          >
                          <h4 class="ml-2" style="font-weight: 500;">MXN</h4>
                        </div>
                      </template>
                    </v-text-field>
                    <div style="display: flex; justify-content: end; margin-top: 20px;">
                      <v-btn elevation="0" class="button-cancel mon-regular" @click="haveInvoice ? cancel() : step = 1" :disabled="loading">{{ texts.modalForm.cancel }}</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="button-save"
                        style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                        elevation="0"
                        color="#193D6E"
                        :disabled="0 >= amountForexMXN || loading || parseFloat(conversionPayment.detail.dAmount) < parseFloat(amountForexMXN)"
                        @click="step = 3"
                      >
                        {{ texts.modalForm.continue }}
                      </v-btn>
                    </div>
                  </v-card-text>
                  <div v-else style="display: flex; justify-content: center;">
                    <v-progress-circular indeterminate color="amber"></v-progress-circular>
                  </div>
                </v-container>
              </v-stepper-content>
              <!-- Paso 2: Detalle de pago -->
              <v-stepper-content step="3" class="pt-0">
                <v-container class="mt-1">
                  <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
                    <span class="title font-weight-bold text-textblack">{{ texts.conversions.paymentDetail }}</span>
                  </v-card-title>
                  <v-card-text class="py-6 pb-0" v-if="!loading">
                    <div class="mb-8" style="display: flex; justify-content: space-around; background-color: #e2e8f0; flex-direction: column; width: 600px;">
                      <div class="pt-6 pb-4 px-10" style="display: flex; justify-content: space-between;">
                        <div style="color: black; font-size: 16px;">{{ texts.conversions.clientName }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">{{ conversionPayment.detail.supplier.sName }}</div>
                      </div>
                      <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                        <div style="color: black; font-size: 16px;">{{ texts.conversions.clabe }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">{{ "********" + sAccountNumber }}</div>
                      </div>
                      <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                        <div style="color: black; font-size: 16px;">{{ texts.conversions.destinationReceives }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">${{ formatMoney(amountForexMXN) }} MXN</div>
                      </div>
                      <div class="py-4 px-10" style="display: flex; justify-content: space-between;" v-if="conversionPayment.detail.conversion[0]?.invoice.length > 0">
                        <div style="color: black; font-size: 16px;">{{ texts.conversions.invoiceToPay }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold; display: flex; gap: 10px;">
                          <div v-for="(item, idx) in conversionPayment.detail.conversion[0].invoice">{{ item.invoice.sInvoice }}</div>
                        </div>
                      </div>
                      <div class="py-4 pb-6 px-10" style="display: flex; justify-content: space-between;">
                        <div style="color: black; font-size: 16px;">{{ texts.conversions.sendDate }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">{{ formatDate(today) }}</div>
                      </div>
                      <div class="py-4 pb-6 px-10" style="display: flex; justify-content: space-between;">
                        <v-textarea
                          name="input-7-1"
                          :label="texts.conversions.paymentConcept"
                          v-model="comment"
                          no-resize>
                        </v-textarea>
                      </div>
                    </div>
                  </v-card-text>
                  <div v-else style="display: flex; justify-content: center;">
                    <v-progress-circular indeterminate color="amber"></v-progress-circular>
                  </div>
                  <div style="display: flex; justify-content: end; margin-top: 20px;">
                    <v-btn elevation="0" class="button-cancel mon-regular" @click="haveInvoice ? cancel() : step = 2" :disabled="loading">{{ texts.modalForm.cancel }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="button-save"
                      style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                      elevation="0"
                      color="#193D6E"
                      :disabled="!comment || loading"
                      @click="sendVerification(4)">
                      {{ texts.modalForm.continue }}
                    </v-btn>
                  </div>
                </v-container>
              </v-stepper-content>

              <!-- Paso 3: Verificación OTP -->
              <v-stepper-content step="4" class="pt-0">
                <v-container class="mt-1">
                  <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
                    <span class="title font-weight-bold text-textblack">{{ texts.twoFactor.twoFactor }}</span>
                  </v-card-title>
                  <v-card-text class="py-6 pb-0">
                    <div class="pt-4 px-4 mb-4" style="display: flex; justify-content: space-around; flex-direction: row; width: 600px">
                      <div>
                        <v-otp-input v-model="otp" length="6" type="number"></v-otp-input>
                        <p style="font-size: 14px; font-weight: 500;">{{ texts.twoFactor.enterCode }}</p>
                      </div>
                    </div>
                  </v-card-text>
                  <div style="display: flex; justify-content: end; margin-top: 20px;">
                    <v-btn elevation="0" class="button-cancel mon-regular" @click="step = 2" :disabled="loading">{{ texts.modalForm.cancel }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="button-save"
                      style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                      elevation="0"
                      :disabled="otp.length < 6 || loading"
                      color="#193D6E"
                      @click="sendPayment()">
                      {{ texts.modalForm.confirmPay }}
                    </v-btn>
                  </div>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSendPayMXN" persistent width="fit-content" class="pa-8">
      <v-card>
        <template>
          <v-stepper v-model="step" alt-labels elevation="0">
            <v-stepper-header
              color="#193D6E"
              class="mt-5 mr-15 ml-15"
              elevation="0"
              style="justify-content: center; align-items: center; justify-items: center; align-content: center; box-shadow: none;"
            >
              <v-stepper-step :complete="step > 1" step="1" color="#193D6E"></v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 2" step="2" color="#193D6E"></v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 3" step="3" color="#193D6E"></v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="step > 4" step="4" color="#193D6E"></v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <!-- Paso 1: Seleccionar contacto -->
              <v-stepper-content step="1" elevation="0" class="pt-0">
                <v-card-title style="display: flex; text-align: center; justify-content: center">
                  <span class="title font-weight-bold text-textblack">{{ texts.conversions.selectContact }}</span>
                </v-card-title>
                <v-card-text class="py-0">
                  <div class="mb-4">
                    <v-list rounded v-if="!isLoadingSuppliers">
                      <v-subheader style="font-size: 24px; color: black">{{ texts.conversions.contacts }}</v-subheader>
                        <v-list rounded v-if="!isLoadingSuppliers">
                          <v-autocomplete
                            :items="suppliers"
                            filled
                            label="Contactos"
                            item-text="sNameAC"
                            return-object
                            v-model="selectedSupplier"
                            @change="selectSupplier(selectedSupplier)"
                            rounded
                          ></v-autocomplete>
                        </v-list>
                    </v-list>
                    <v-skeleton-loader v-else class="mx-auto" type="image"></v-skeleton-loader>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="cancel()">{{ texts.modalForm.cancel }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="button-save"
                    style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                    elevation="0"
                    color="#193D6E"
                    @click="step = 2">
                    {{ texts.modalForm.continue }}
                  </v-btn>
                </div>
              </v-stepper-content>

              <!-- Paso 2: Detalle de pago -->
              <v-stepper-content step="2" class="pt-0">
                <v-container class="mt-1">
                  <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
                    <span class="title font-weight-bold text-textblack">{{ texts.conversions.paymentDetail }}</span>
                  </v-card-title>
                  <v-card-text class="py-6 pb-0" v-if="!loading" style="width: 500px;">
                    <div class="mb-8" style="display: flex; justify-content: space-around; background-color: #e2e8f0;">
                      <div class="py-8 px-10">
                        <div class="" style="color: black; font-size: 16px;">
                          {{ texts.conversions.avaible }}
                        </div>
                        <div class="" style="color: black; font-weight: bold; font-size: 22px;">
                          ${{ formatMoney(avaibleAmount) }} MXN
                        </div>
                      </div>
                    </div>
                    <v-text-field
                      outlined
                      :label="texts.dashboard.sendAmount"
                      type="number"
                      persistent-placeholder
                      v-model="amountForexMXN"
                      prefix="$"
                      class="mt-10"
                    >
                      <template v-slot:append>
                        <div style="display: flex; align-items: center !important;">
                          <img
                            width="24"
                            height="24"
                            src="../../assets/images/Flags_mex.png"
                            alt=""
                            style="border-radius: 10px"
                          >
                          <h4 class="ml-2" style="font-weight: 500;">MXN</h4>
                        </div>
                      </template>
                    </v-text-field>
                    <div style="display: flex; justify-content: end; margin-top: 20px;">
                      <v-btn elevation="0" class="button-cancel mon-regular" @click="haveInvoice ? cancel() : step = 1" :disabled="loading">{{ texts.modalForm.cancel }}</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        class="button-save"
                        style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                        elevation="0"
                        color="#193D6E"
                        :disabled="amountForexMXN < 0 || loading || amountForexMXN > avaibleAmount"
                        @click="step = 3">
                        {{ texts.modalForm.continue }}
                      </v-btn>
                    </div>
                  </v-card-text>
                  <div v-else style="display: flex; justify-content: center;">
                    <v-progress-circular indeterminate color="amber"></v-progress-circular>
                  </div>
                </v-container>
              </v-stepper-content>

              <!-- Paso 3: Detalle de pago -->
              <v-stepper-content step="3" class="pt-0">
                <v-container class="mt-1">
                  <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
                    <span class="title font-weight-bold text-textblack">{{ texts.conversions.paymentDetail }}</span>
                  </v-card-title>
                  <v-card-text class="py-6 pb-0" v-if="!loading">
                    <div class="mb-8" style="display: flex; justify-content: space-around; background-color: #e2e8f0; flex-direction: column; width: 600px;">
                      <div class="pt-6 pb-4 px-10" style="display: flex; justify-content: space-between;">
                        <div style="color: black; font-size: 16px;">{{ texts.conversions.clientName }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">{{ conversionPayment.detail.supplier.sName }}</div>
                      </div>
                      <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                        <div style="color: black; font-size: 16px;">{{ texts.conversions.clabe }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">{{ "********" + sAccountNumber }}</div>
                      </div>
                      <div class="py-4 px-10" style="display: flex; justify-content: space-between;">
                        <div style="color: black; font-size: 16px;">{{ texts.conversions.destinationReceives }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">${{ formatMoney(amountForexMXN) }} MXN</div>
                      </div>
                      <div class="py-4 pb-6 px-10" style="display: flex; justify-content: space-between;">
                        <div style="color: black; font-size: 16px;">{{ texts.conversions.sendDate }}</div>
                        <div style="color: black; font-size: 22px; font-weight: bold;">{{ formatDate(today) }}</div>
                      </div>
                      <div class="py-4 pb-6 px-10" style="display: flex; justify-content: space-between;">
                        <v-textarea
                          name="input-7-1"
                          :label="texts.conversions.paymentConcept"
                          v-model="comment"
                          no-resize>
                        </v-textarea>
                      </div>
                    </div>
                  </v-card-text>
                  <div v-else style="display: flex; justify-content: center;">
                    <v-progress-circular indeterminate color="amber"></v-progress-circular>
                  </div>
                  <div style="display: flex; justify-content: end; margin-top: 20px;">
                    <v-btn elevation="0" class="button-cancel mon-regular" @click="haveInvoice ? cancel() : step = 2" :disabled="loading">{{ texts.modalForm.cancel }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="button-save"
                      style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                      elevation="0"
                      color="#193D6E"
                      :disabled="!comment || loading"
                      @click="sendVerification(4)">
                      {{ texts.modalForm.continue }}
                    </v-btn>
                  </div>
                </v-container>
              </v-stepper-content>

              <!-- Paso 4: Verificación OTP -->
              <v-stepper-content step="4" class="pt-0">
                <v-container class="mt-1">
                  <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
                    <span class="title font-weight-bold text-textblack">{{ texts.twoFactor.twoFactor }}</span>
                  </v-card-title>
                  <v-card-text class="py-6 pb-0">
                    <div class="pt-4 px-4 mb-4" style="display: flex; justify-content: space-around; flex-direction: row; width: 600px">
                      <div>
                        <v-otp-input v-model="otp" length="6" type="number"></v-otp-input>
                        <p style="font-size: 14px; font-weight: 500;">{{ texts.twoFactor.enterCode }}</p>
                      </div>
                    </div>
                  </v-card-text>
                  <div style="display: flex; justify-content: end; margin-top: 20px;">
                    <v-btn elevation="0" class="button-cancel mon-regular" @click="step = 2" :disabled="loading">{{ texts.modalForm.cancel }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="button-save"
                      style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                      elevation="0"
                      :disabled="otp.length < 6 || loading"
                      color="#193D6E"
                      @click="sendPaymentMXN()">
                      {{ texts.modalForm.confirmPay }}
                    </v-btn>
                  </div>
                </v-container>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditInvoice" persistent max-width="600px">
      <v-card>
        <v-card-title style="display: flex; justify-content: center">
          <span class="text-h5">Editar factura</span>
        </v-card-title>
        <v-card-subtitle class="pb-0 mt-3">
          <span style="font-size: 15px"
            >Ingresa la siguiente información para agregar una nueva factura.</span
          >
        </v-card-subtitle>
        <v-card-text v-if="!loading">
          <v-container>
            <v-row>
              <v-form
                ref="formEdit"
                style="width: 100%"
                lazy-validation
                v-on:submit.prevent
              >
                <v-col cols="12">
                  <v-text-field
                    label="Nombre de la invoice*"
                    required
                    outlined
                    hide-details
                    v-model="invoice.sInvoice"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Orden de compra"
                    outlined
                    hide-details
                    v-model="invoice.sId"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    label="Nombre del proveedor*"
                    required
                    outlined
                    hide-details
                    :items="suppliers"
                    item-value="sPaymentRecipientId"
                    item-text="sName"
                    v-model="invoice.sPaymentRecipientId"
                    :rules="[rules.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Fecha de compra*"
                    required
                    outlined
                    hide-details
                    type="date"
                    v-model="invoice.dPurchaseDate"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Total en pesos MXN*"
                    required
                    outlined
                    hide-details
                    type="number"
                    v-model="invoice.dTotalAmount"
                    :rules="[rules.required]"
                  >
                    <template v-slot:append>
                      <div>
                        <h4 class="ml-2 mt-1" >MXN</h4>
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Tiempo del credito*"
                    required
                    outlined
                    hide-details
                    type="number"
                    v-model="invoice.iCreditPeriod"
                    :rules="[rules.required]"
                  >
                    <template v-slot:append>
                      <div>
                        <h4 class="ml-2 mt-1" >dias</h4>
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Tipo de cambio protegido*"
                    required
                    outlined
                    hide-details
                    type="number"
                    v-model="invoice.dRateProtection"
                    :rules="[rules.required]"
                  >
                    <template v-slot:append>
                      <div>
                        <h4 class="ml-2 mt-1" >MXN</h4>
                      </div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <div v-else style="display: flex; justify-content: center">
          <v-progress-circular indeterminate color="amber"></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            text
            :disabled="loading"
            @click="dialogEditInvoice = false"
          >
            Cerrar
          </v-btn>
          <v-btn color="#0075B8" class="white--text" :disabled="loading" @click="saveInvoice()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export default {
  name: "DashboardView",
  data() {
    return {
      nameXLSX: "ConversionesDisponibles.xlsx",
      texts: "",
      drawer: null,
      dialogEditInvoice: false,
      dialogSendPayOfInvoice: false,
      dialogDetailConversion: false,
      actualCurrency: "0",
      actualCurrencyMxn: "0",
      referenceCurrency: "0",
      isLoading: false,
      otp: "",
      step: 1,
      haveInvoice: false,
      isRegisterEfex: true,
      exchangeRateData: [],
      selected: [],
      selectedSupplier: null,
      dialogSendPayMXN: false,
      selectedSupplier: null,
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      balanceMxn: "0",
      balanceUsd: "0",
      balance: "0",
      items: [],
      active: false,
      comment: null,
      tab: 0,
      contacts: [],
      step: 0,
      itemsSelected: [],
      totalProfit: "",
      itemsSelectedForwards: [],
      isForwardRateCalculated: false,
      bSelectedAll: false,
      dialogAddFoundss: false,
      dialogSendPay: false,
      dialogAddFounds: false,
      dataAddFounds: {},
      dialogCreate: false,
      amountForexMXN: 0,
      dialogPay: false,
      editableData: {},
      selectedCard: null,
      avaibleAmount: 0,
      today: null,
      totalUsd: 0,
      selectedMenuItem: "dashboard",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY",
      },
      isForwardsActive: false,
      suppliers: [],
      isLoadingInvoices: false,
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      bLoading: false,
      isDeferredPayment: false,
      stepper: false,
      e1: 1,
      timerCount: 30,
      rules: {
        required: (v) => !!v || "El campo es obligatorio",
      },
      headersForex: [
        this.selectLanguage === 'en' ? { text: "Id", value: "iTrackingId", align: "center", sortable: false} : { text: "Id", value: "iTrackingId", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Creation date", value: "created_at", align: "center"} : { text: "Fecha de creación", value: "created_at", align: "center"},
        this.selectLanguage === 'en' ? { text: "Type Transaction", value: "sTransactionType", align: "center"} : { text: "Tipo de transacción", value: "sTransactionType", align: "center"},
        this.selectLanguage === 'en' ? { text: "Total amount USD", value: "dAmount", align: "center"} : { text: "Monto total USD", value: "dAmount", align: "center"},
        this.selectLanguage === 'en' ? { text: "Total amount MXN", value: "dDesAmount", align: "center"} : { text: "Monto total MXN", value: "dDesAmount", align: "center"},
        this.selectLanguage === 'en' ? { text: "Exchange rate", value: "dExchangeRate", align: "center"} : { text: "Tasa de cambio", value: "dExchangeRate", align: "center"},
      ],
      timerId: null,
      dataEfex: {},
      avaibleInvoices: [],
      loading: false,
      exchangeRateAdjustments: [],
      exchangeRatesStateForwards: [],
      isPayMxn: false,
      loadingTransaction: false,
      bSelectedAllSeparate: false,
      dialogEditProfileActive: false,
      isForwards: false,
      isForwardsPayment: false,
      userName: "",
      modalCurrency: 0,
      sections: [
        {
          icon: "mdi mdi-view-dashboard",
          value: "operation",
          title: "Operaciones",
          items: [
            {
              icon: "mdi mdi-view-dashboard",
              value: "dashboard",
              title: "Dashboard",
              disabled: false,
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Seguimiento de facturas",
              disabled: false,
            },
            {
              icon: "mdi-forward",
              value: "activeForwards",
              title: "Forwards activos",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-history",
          value: "history",
          title: "Historial",
          items: [
            {
              icon: "mdi-file-document",
              value: "exchangeRate",
              title: "Historial de conversiones",
              disabled: true,
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Historial de pagos",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-wallet",
          value: "wallets",
          title: "Wallets",
          items: [
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Proveedores",
              disabled: true,
            },
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Estado de cuenta",
              disabled: true,
            },
          ],
        },
      ],
      miniVariant: true,
      expandOnHover: false,
      registerFields: [
        { model: "sBankName", type: "text", label: "sBankName" },
        { model: "sAccountNumber", type: "text", label: "sAccountNumber" },
        { model: "sRoutingNumber", type: "text", label: "sRoutingNumber" },
      ],
      transactions: [],
      conversion: {},
      invoices: [],
      invoice: {},
      supplier: {},
      sAccountNumber: null,
      sPaymentRecipientId: "",
      conversionPayment: {
        sTransactionId: "",
        sPaymentRecipientId: "",
        dAmount: "",
        detail: {
          supplier: {},
          conversion: {},
        },
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    tabStyle(tabIndex) {
      return {
        color: this.tab === tabIndex ? "#193D6E" : "#506172", 
        fontSize: "16px",
        fontWeight: "600",
        width: "200px",
        lineHeight: "24px",
        fontFamily: "montserrat-bold",
      };
    },
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    updateInvoice() {
      this.active = true;
      if (this.sPaymentRecipientId) {
        this.getInvoicesWithSupplier(this.sPaymentRecipientId);
      }
    },
    saveInvoice() {
      if (this.$refs.formEdit.validate()) {
        this.loading = true;

        let payload = {
          sInvoiceId: this.invoice.sInvoiceId,
          dPurchaseDate: this.invoice.dPurchaseDate,
          sPaymentRecipientId: this.invoice.sPaymentRecipientId,
          sInvoice: this.invoice.sInvoice,
          sId: this.invoice.sId,
          dTotalAmount: this.invoice.dTotalAmount,
          dRateProtection: this.invoice.dRateProtection,
          iCreditPeriod: this.invoice.iCreditPeriod,
          sTransactionId: this.conversion.sTransactionId,
          exchangeRate: this.conversion.dExchangeRate,
        };

        DB.put(`${URI}/api/v1/${this.selectLanguage}/invoices/conversion`, payload, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        })
          .then((response) => {
            this.loading = false;
            this.loadingTransaction = false;
            this.getConversionsWithId(this.conversion);
            this.active = false;
            this.dialogEditInvoice = false;
          })
          .catch((error) => {
            this.loading = false;
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.error = true;
        this.codeError = "INVALID_INPUT";
      }
    },
    openInvoice(item) {
      this.loading = true;
      const payload = {
        sInvoiceId: item.sInvoiceId,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/invoices/id`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.loading = false;
          this.invoice = response.data.invoice;
          this.invoice.dPurchaseDate = moment(response.data.invoice.dPurchaseDate).format(
            "YYYY-MM-DD"
          );
          this.dialogEditInvoice = true;
        })
        .catch((error) => {
          this.loading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openDialog(item) {
      // this.$router.push("/balance/" + item.sTransactionId);
      this.dialogDetailConversion = true;
      this.getConversionsWithId(item);
    },
    cancelDialog() {
      this.dialogSendPayOfInvoice = false;
      this.comment = null;
    },
    addInvoice(item) {
      this.loadingTransaction = true;
      this.avaibleInvoices = [];

      const payload = {
        sTransactionId: this.conversion.sTransactionId,
        invoices: [
          {
            sInvoiceId: item.sInvoiceId,
            dAmount: item.dTotalAmount,
          },
        ],
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/transactions/invoice`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.loadingTransaction = false;
          this.getConversionsWithId(this.conversion);
          this.active = false;
        })
        .catch((error) => {
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
          this.getInvoicesWithSupplier(this.sPaymentRecipientId);
        });
    },
    deleteInvoice(item) {
      this.loadingTransaction = true;

      const payload = {
        sTransactionId: this.conversion.sTransactionId,
        invoices: [
          {
            sInvoiceId: item.sInvoiceId,
            dAmount: item.dTotalAmount,
          },
        ],
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/transactions/deleteInvoice`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          this.loadingTransaction = false;
          this.getConversionsWithId(this.conversion);

          this.active = false;
        })
        .catch((error) => {
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selectSupplier(item) {
      this.conversionPayment.detail.supplier = item;
      this.conversionPayment.detail.sPaymentRecipientId = item.sPaymentRecipientId;
      this.sAccountNumber = this.conversionPayment.detail.supplier.sAccountNumber.slice(
        -4
      );
    },
    openPayNowMXN(item) {
      this.step = 0;
      this.haveInvoice = false;
      this.step = 1;
      this.dialogSendPayMXN = true;
    },
    openPayNow(item) {
      this.step = 0;
      this.conversionPayment.detail.sTransactionId = item[0].sTransactionId;
      this.conversionPayment.detail.dAmount = item[0].dDesAmount;
      this.conversionPayment.detail.conversion = item;
      this.haveInvoice = false;
      this.step = 1;
      this.dialogSendPay = true;
    },
    sendVerification(step) {
      //console.log("step", step)
      this.step = step;
      try {
        DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }).then((response) => {
          if(!response.data.success){
            this.mixError(response.data.message, response.status);
          }
        })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
        }
        catch(error){
          this.mixError(error.response.data.message, error.response.status);
        }
    },
    openPayInvoices(item) {
      console.log('openPayInvoices', item)
      //this.dialogSendPay = true;
      this.dialogSendPayOfInvoice = true;
      //this.haveInvoice = true;
      this.conversionPayment.detail.sTransactionId = item.sTransactionId;
      this.conversionPayment.detail.dAmount = item.totalAmount;
      this.conversionPayment.detail.conversion = [item];
      this.conversionPayment.detail.supplier = this.suppliers.find(
        (supplier) =>
          supplier.sPaymentRecipientId === item.invoice[0].invoice.sPaymentRecipientId
      );
      this.conversionPayment.detail.sPaymentRecipientId =
        item.invoice[0].invoice.sPaymentRecipientId;
      this.sAccountNumber = this.conversionPayment.detail.supplier.sAccountNumber.slice(
        -4
      );
      this.step = 1;
    },
    async validateOtp() {
    try {
        const payload = {
          sCode: this.otp
        };

      const response = await DB.post(`${URI}/api/v1/${this.selectLanguage}/authenticator/verify`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : ""
        }
      });

      return response.data.success;
    } catch (error) {
      console.error('Error in validateOtp:', error);
      return false;
      }
    },
    async sendPayment() {
      this.loading = true;
      const isOtpValid = await this.validateOtp();

      if (!isOtpValid) {
        this.loading = false;
        this.mixError('Invalid code', 400);
        return;
      }

      const payload = {
        sTransactionId: this.conversionPayment.detail.sTransactionId,
        sPaymentRecipientId: this.conversionPayment.detail.sPaymentRecipientId,
        isMxn: true,
        dAmount: this.amountForexMXN,
        comment: this.comment,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/payments/`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.loadingTransaction = false;
          this.getConversions();
          this.getSuppliers();
        })
        .catch((error) => {
          this.loading = false;
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
        })
        .finally(() => {
          this.loading = false;
          this.cancel();
          });
    },
    async sendPaymentMXN() {
      this.loading = true;
      const isOtpValid = await this.validateOtp();

      if (!isOtpValid) {
        this.loading = false;
        this.mixError('Invalid code', 400);
        return;
      }

      const payload = {
        sPaymentRecipientId: this.conversionPayment.detail.sPaymentRecipientId,
        isMxn: true,
        dAmount: this.amountForexMXN,
        comment: this.comment,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/payments/MX`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.loadingTransaction = false;
          this.getConversions();
          this.getSuppliers();
        })
        .catch((error) => {
          this.loading = false;
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
        })
        .finally(() => {
          this.loading = false;
          this.cancel();
          });
    },
    getInvoicesWithSupplier(item) {
      this.isLoadingInvoices = true;
      this.avaibleInvoices = [];

      const payload = {
        sPaymentRecipientId: item,
        dExchangeRate: this.conversion.dExchangeRate,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/invoices/supplier`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.avaibleInvoices = response.data.invoices;
          this.isLoadingInvoices = false;
        })
        .catch((error) => {
          this.isLoadingInvoices = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    cancel() {
      this.dialogSendPay = false;
      this.dialogSendPayMXN = false;
      this.amountForexMXN = 0;
      this.otp = "";
      this.dialogEditInvoice = false;
      this.haveInvoice = !this.haveInvoice;
      this.comment = null;
      this.loadingTransaction = false;
      this.dialogSendPayOfInvoice = false;
      this.step = "";
      this.e1 = 1;
      this.avaibleInvoices = [];
      this.selected = [];
      this.selectedSupplier = null;
      this.dialogDetailConversion = false;
      this.active = false;
      this.isLoading = false;
      this.selectedSupplier = [];
      this.dialogModalPay = false;
      this.totalUsd = 0;
      this.isDeferredPayment = false;
      this.isPayMxn = false;
      this.itemsSelected = [];
      this.getConversions();
    },
    getConversionsWithId(item) {
      this.loadingTransaction = true;
      this.conversion = [];

      const payload = {
        sTransactionId: item.sTransactionId,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/transactions/id`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.conversionWithId = response.data.transactions.map((transaction) => {
            let totalAmount = 0;
            if (transaction.invoice.length > 0) {
              transaction.invoice.map((invoice) => {
                totalAmount += parseFloat(invoice.dAmount);
              });
            }
            transaction.totalAmount = totalAmount;
            transaction.percent =
              (transaction.totalAmount / transaction.dDesAmount) * 100;
            return transaction;
          });

          this.conversion = this.conversionWithId[0];

          if (this.conversion.invoice.length > 0) {
            this.sPaymentRecipientId = this.conversion.invoice[0].invoice.paymentsRecipent[0].sPaymentRecipientId;
          } else {
            this.sPaymentRecipientId = null;
          }

          this.getConversions();
          this.loadingTransaction = false;
        })
        .catch((error) => {
          this.loadingTransaction = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getConversions() {
      this.isLoading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.avaibleAmount = response.data.avaibleAmount;
          this.items = response.data.transactions.map((transaction) => {
            let totalAmount = 0;
            let invoices = [];
            let purchaseDate = [];
            let supplier = [];

            if (transaction.invoice.length > 0) {
              transaction.invoice.map((invoice) => {
                totalAmount += parseFloat(invoice.dAmount);
                invoices.push(invoice.invoice.sInvoice)
                supplier.push(invoice.invoice.paymentsRecipent[0].sName)
                purchaseDate.push(this.formatDate(invoice.invoice.dPurchaseDate))
              });
            }

            transaction.sSupplier = supplier[0];
            transaction.invoices = invoices.join(', ');
            transaction.purchaseDate = purchaseDate.join(', ');

            transaction.percent = (totalAmount / transaction.dDesAmount) * 100;
            transaction.totalAmount = totalAmount;
            return transaction;
          });

          this.items.sort((a, b) => {
            const dateA = new Date(a.created_at);
            const dateB = new Date(b.created_at);

            return dateB - dateA;
          });
          this.isLoading = false;
          this.balanceMxn = response.data.balance.balanceMxn.balance;
        })
        .catch((error) => {
          // this.isLoading = false;
          if (error.response.data.code) {
            this.$router.push("/welcome").catch(() => {});
          }
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getInvoices() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/invoices/?exchangeRateToday=${this.actualCurrency}`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      ).then((response) => {
        this.invoices = response.data.invoices;
      });
    },
    getTransactions() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/transactions/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      }).then((response) => {
        this.transactions = response.data.transactions;
      });
    },
    formatMoney: function (value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(formattedNumber);
    },
    getExchangeRate: function () {
      this.items = [];

      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/?exchangeRateToday=${this.actualCurrency}`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          this.isForwardsActive = response.data.isForwardsActive;
          this.userName = response.data.user;
          this.isRegisterEfex = response.data.registerInEfex;
          this.exchangeRateDataPaid = response.data.exchangeRate.exchangeRatesStatePaid;
          this.exchangeRateData = response.data.exchangeRate.exchangeRatesStateToPay;
          this.exchangeRateDataSeparate =
            response.data.exchangeRate.exchangeRatesStateSeparated;
          this.exchangeRatesStateForwards =
            response.data.exchangeRate.exchangeRatesStateForwards;
          this.balanceMxn = response.data.balance.balanceMxn;
          this.balanceUsd = response.data.balance.balanceUsd;
          this.exchangeRateAdjustments = response.data.exchangeRateAdjustments;
          this.balance =
            parseFloat(this.balanceMxn) / parseFloat(this.actualCurrency) +
            parseFloat(this.balanceUsd);
          this.balance = this.balance.toString();

          // this.totalUsd = 0;

          // this.itemsSelected.forEach((item) => {
          //   item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          //   this.totalUsd += item.dTotalUsdToday;
          // });

          this.mixSuccess(response.data.message);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        })
        .finally(() => {
          this.getContacts();
        });
    },
    getContacts: function () {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/contacts-wallet/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.contacts = response.data.contacts;
          this.contacts.push({
            id: 0,
            name: "Crear nuevo contacto",
          });
        })
        .catch((error) => {
          // Handle error if needed
        });
    },
    openModalAddFoundss() {
      this.dialogAddFoundss = true;

      const payload = {
        amount: 100,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/exchange-rate/addFounds`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.dataAddFounds = response.data.account;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    closeModalAddFounds() {
      this.dialogAddFounds = false;
    },
    toggleAll: function (checked) {
      this.exchangeRateData.forEach((item) => {
        if (
          item.statusName !== "WAITING_FOR_DEPOSIT" ||
          item.statusName !== "PROCESSING"
        ) {
          item.bSelected = checked;
        }
      });
    },
    formatDate: function (date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
    resetForm() {
      this.editableData = {
        dPurchaseDate: "",
        sProvider: "",
        sInvoiceNumber: "",
        dDesAmount: "",
        iCreditPeriod: "",
        dInvoiceExchangeRateProtection: "",
      };
    },
    openModalCreate() {
      this.dialogCreate = true;
      this.resetForm();
    },
    validateForm: function () {
      return true;
    },
    saveChanges: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(this.editableData.dPurchaseDate);

      const payload = {
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dDesAmount: parseFloat(this.editableData.dDesAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        createContact: this.showCreateProvider,
        contactData: this.contactData,
        dInvoiceExchangeRateProtection: this.editableData.dInvoiceExchangeRateProtection,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/exchange-rate/`, payload, config)
        .then((response) => {
          this.bLoading = false;
          this.dialogCreate = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatDateToSend: function (date) {
      let newDate = new Date(date);
      let day = newDate.getDate() + 1;
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      return `${year}-${month}-${day}`;
    },
    changebSelectedAll: function () {
      this.bSelectedAll = !this.bSelectedAll;
    },
    closeModalCreate() {
      this.dialogCreate = false;
      this.showCreateProvider = false;
      this.contactData = {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY",
      };
    },
    sendToPayNow: function () {
      this.isDeferredPayment = false;
      this.sendToPay();
    },
    sendToPay: function () {
      this.itemsSelected = this.exchangeRateData.filter((item) => item.bSelected);
      if (this.itemsSelected.length > 0) {
        this.stepper = true;
        this.itemsSelected.forEach((item) => {
          item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
          this.totalUsd += item.dTotalUsdToday;
        });
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    actualizarE1(nuevoValor) {
      this.e1 = nuevoValor;
    },
    closeModalPay() {
      this.dialogPay = false;
      this.totalUsd = 0;
      this.e1 = 1;
    },
    sendToPayNowMxn: function () {
      this.isDeferredPayment = false;
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRateDataSeparate.filter((item) => item.bSelected);
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.dialogPay = true;
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    sendToPayNowForwards: function () {
      this.isPayMxn = true;
      this.itemsSelected = this.exchangeRatesStateForwards.filter(
        (item) => item.bSelected
      );
      this.itemsSelected.forEach((item) => {
        item.dTotalUsdToday = parseFloat(item.dTotalUsdToday);
        this.totalUsd += item.dTotalUsdToday;
      });

      if (this.itemsSelected.length > 0) {
        this.sendPayEfex();
      } else {
        this.mixError("No hay elementos seleccionados", 400);
      }
    },
    closeModalStepper() {
      this.stepper = false;
      this.totalUsd = 0;
      this.e1 = 1;
      this.isForwards = false;
      this.isForwardsPayment = false;
      //this.getActualCurrency();
    },
    changeDeferredPayment() {
      this.isDeferredPayment = !this.isDeferredPayment;
    },
    changePayMxn() {
      this.isPayMxn = !this.isPayMxn;
    },
    sendPayEfex: function () {
      this.bLoading = true;

      if (this.isPayMxn) {
        this.totalUsd = this.totalUsd * this.actualCurrency;
      }

      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        actualCurrency: this.actualCurrency,
        isDeferredPayment: this.isDeferredPayment,
        isPayMxn: this.isPayMxn,
        totalUsd: this.totalUsd,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayment/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogPay = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.data.message, error.response.status);
        });
    },
    openReceipt: function (item) {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getPaymentRecipt/${item.paymentExchangeRate[0].sPayment_reference}/`,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (response.data && response.data.payment) {
            let base64 = response.data.payment.data;

            const base64Pattern = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/;
            if (base64Pattern.test(base64)) {
              base64 = base64.split(",")[1];
            }

            try {
              const binaryString = window.atob(base64);
              const bytes = new Uint8Array(binaryString.length);
              for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
              }
              const blob = new Blob([bytes], { type: "application/pdf" });

              const url = URL.createObjectURL(blob);
              window.open(url, "_blank");
            } catch (e) {
              console.error("Error al decodificar base64: ", e);
            }
          } else {
            console.error("No se encontraron los datos del PDF en la respuesta");
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sendSeparateEfex: function () {
      this.bLoading = true;
      const payload = {
        exchangeRateId: this.itemsSelected,
        rateUuid: this.referenceCurrency,
        rateInt: this.actualCurrency,
      };

      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/separateExchangeRate/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.dialogSeparate = false;
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    toggleAllSeparated: function (checked) {
      this.exchangeRateDataSeparate.forEach((item) => {
        item.bSelected = checked;
      });
    },
    toggleAllForwards: function (checked) {
      this.exchangeRatesStateForwards.forEach((item) => {
        this.$set(item, "bSelected", checked);
      });
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/sub-account/`, payload, config)
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openModal(item) {
      this.totalUsd = 0;
      this.selectedItem = item;
      this.editableData = { ...this.selectedItem };
      this.dialogEditProfileActive = true;
    },
    closeDialog() {
      this.dialogEditProfileActive = false;
    },
    backStep() {
      this.e1 = 1;
    },
    updateExchangeRate: function () {
      this.bLoading = true;
      let newdPurchaseDate = this.formatDateToSend(this.editableData.dPurchaseDate);
      const payload = {
        sExchangeRateId: this.selectedItem.sExchangeRateId,
        dPurchaseDate: newdPurchaseDate.toString(),
        sProvider: this.editableData.sProvider,
        sInvoiceNumber: this.editableData.sInvoiceNumber,
        dDesAmount: parseFloat(this.editableData.dDesAmount),
        iCreditPeriod: parseInt(this.editableData.iCreditPeriod),
        dInvoiceExchangeRateProtection: this.editableData.dInvoiceExchangeRateProtection,
        dAmountAdjustment: parseFloat(this.editableData.dAmountAdjustment),
        sCurrencyAdjustment: "MXN",
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/${this.selectedItem.sExchangeRateId}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.dialogEditProfileActive = false;
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
          this.closeDialog();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeForwards: function (value) {
      this.isForwards = value;
    },
    getForwards: function () {
      this.bLoading = true;
      const payload = {
        pays: this.itemsSelected,
        referenceCurrency: this.referenceCurrency,
        reverse: true,
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/getRateForwards/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
            this.bLoading = false;
            this.isForwardsPayment = false;
          } else {
            this.mixSuccess(response.data.message);
            this.bLoading = false;
            this.isForwardsPayment = true;
            this.isForwardRateCalculated = response.data.isForwardRateCalculated;
            this.itemsSelected = response.data.responsePaysEfex;
          }
          this.getExchangeRate();
        })
        .catch((error) => {
          this.bLoading = false;
          this.isForwardsPayment = false;
          this.mixError(error.data.message, 400);
        });
    },
    sendForwardPayment: function () {
      const payload = {
        pays: this.itemsSelected,
      };
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/exchange-rate/sendPayForward/`,
        payload,
        {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        }
      )
        .then((response) => {
          if (!response.data.status) {
            this.mixError(response.data.message, 400);
          } else {
            this.mixSuccess(response.data.message);
          }
          this.bLoading = false;
          this.stepper = false;
          this.defferedPayment = false;
          this.getExchangeRate();
          this.totalUsd = 0;
          this.isForwardsPayment = false;
          this.isPayMxn = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    changeModalCurrency: function (value) {
      this.modalCurrency = value;
    },
    updateTotalUsd: function (value) {
      this.totalUsd = value;
    },
    getSuppliers() {
      this.isLoadingSuppliers = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/payment-recipient/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      }).then((response) => {
        this.isLoadingSuppliers = false;
        this.suppliers = response.data.paymentRecipients;
        this.suppliers.map((item) => {
          item.sNameAC = item.sName + " - *******" + item.sAccountNumber.slice(-4);
        });
      });
    },
    dataXLSX() {
      try {
        let data = this.items.map((item) => {
          return {
            Id: item.iTrackingId ? item.iTrackingId : "",
            "Fecha de creación": item.created_at ? this.formatDate(item.created_at) : "",
            "Type Transaction": item.sTransactionType == 'b80e8dc6-4138-449e-8903-968efb8437b3' ? 'Forward' : (item.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a' ? 'Forex' : "N/A"),
            "Total amount (USD)": item.dAmount ? parseFloat(item.dAmount) : "",
            "Total amount (MXN)": item.dDesAmount ? parseFloat(item.dDesAmount) : "",
            "Exchange rate": item.dExchangeRate ? item.dExchangeRate : "",
            Invoice: item.invoice ? item.invoice : "",
            "Purchase Date": item.purchaseDate ? item.purchaseDate : "",
            "Currency profit (%)": item.percentProfit ? parseFloat(item.percentProfit) : "",
            "Currency gain (USD)": item.dollarProfit ? parseFloat(item.dollarProfit) : "",
          };
        });
        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        saveAs(blob, this.nameXLSX);
        this.success = true;
        this.msgSuccess = "Descargando documento";
      } catch (error) {
        this.error = true;
        this.codeError = "CSV_NO_DATA";
      }
    },
    updateHeaders() {
      this.headersForex = [
        { text: this.texts.conversions.id, value: "iTrackingId", align: "center" },
        { text: this.texts.conversions.transactionDate, value: "created_at", align: "center" },
        { text: this.texts.conversions.typeTransaction, value: "sTransactionType", align: "center" },
        { text: this.texts.conversions.totalAmountUSD, value: "dAmount", align: "center" },
        { text: this.texts.conversions.totalAmountMXN, value: "dDesAmount", align: "center" },
        { text: this.texts.conversions.exchangeRate, value: "dExchangeRate", align: "center" },
      ]
    }
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getConversions();
    this.getSuppliers();
    this.updateHeaders();
    this.today = moment().format("YYYY-MM-DD");
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
      this.updateHeaders();
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    },
  },
};
</script>

<style scoped>
.bg-container {
  width: 100%;
  background: #fff;
  opacity: 1;
  height: 100%;
}
.full-height {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}
.active {
  background-color: #f0f0f0;
}
/* .active-section .v-list-item__icon {
  color: yellow !important;
  background-color: yellow !important;
}

.active-section .v-list-item__title {
  background-color: yellow !important;
} */

.active-item {
  background-color: #f5f7f9 !important;
}
.button-add {
  background: transparent linear-gradient(258deg, #ffc556 0%, #fffc81 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}
.v-tabs-slider {
  background: #193D6E !important;
  height: 3px;
  color: #193D6E !important;
  border-radius: 100px;
}

.card-main {
  width: fit-content;
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.card-content {
  position: absolute;
  top: 19px;
  left: 16px;
}

.circle-content {
  text-align: center;
  color: white;
  margin: auto;
}

.btn-custom {
  color: #193D6E;
}

.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.card-info,
.balance-title {
  color: #777777;
  font-size: 10px;
  font-weight: 400;
  line-height: 20px;
}

.card-mxn {
  width: 200px;
  color: #193D6E; 
  font-size: 24px;
  font-family: Mons;
  font-weight: 700;
  line-height: 36px;
  font-style: normal;
  word-wrap: break-word;
}

.container-width,
.header-container {
  max-width: 1280px;
  margin: auto;
}

.title-medium {
  font-family: "montserrat-semibold";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.body-large {
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.blocked-amount {
  font-family: "montserrat-regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.blocked-amount-2 {
  color: #302e2e;
  font-family: "montserrat-regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.circle-sheet {
  width: 200px;
  height: 200px;
  padding-left: 17px;
  padding-right: 62px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  position: absolute;
  right: -60px;
  top: -20px;
}

.usd-balance {
  color: #666565;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.content-balance {
  color: #302e2e;
  font-family: "montserrat-medium", sans-serif;
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

.buttonAddFounds {
  text-align: center;
  color: #163969;
  font-size: 16px;
  font-family: "montserrat-semibold";
  font-weight: 600;
  line-height: 24px;
  text-transform: capitalize;
  word-wrap: break-word;
}

.modalTextAddFounds {
  color: #302e2e;
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.table th {
  background-color: #f2f2f4;
  /* border: 1px solid #ccc; */
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }

  .card-mxn {
    font-size: 20px;
  }

  .usd-balance {
    font-size: 12px;
  }

  .content-balance {
    font-size: 18px;
  }

  .blocked-amount {
    font-size: 12px;
  }

  .blocked-amount-2 {
    font-size: 14px;
  }

  .buttonAddFounds {
    font-size: 14px;
  }

  .circle-sheet {
    display: none;
  }
}

@media (max-width: 1250px) {
  .circle-sheet {
    width: 155px;
    height: 170px;
    padding-left: 17px;
    padding-right: 40px;
    right: -50px;
    top: -5px;
  }
}
</style>
