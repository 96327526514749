<template>
  <div>
    <v-app id="inspire">
      <v-navigation-drawer
        dark
        v-model="drawer"
        width="290"
        app
        fixed
        style="
          background-color: #193d6e;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        "
      >
        <div
          style="
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          "
        >
          <div>
            <v-list-item title="My Application" subtitle="Vuetify">
              <v-img :src="require('../assets/logos/Logotipo_Negativo.png')"></v-img>
            </v-list-item>
            <v-divider></v-divider>
            <v-list class="mx-5">
              <v-list-group
                v-for="item in sections"
                :key="item.title"
                v-model="item.active"
                active-class="activeGroup"
                :prepend-icon="item.icon"
                no-action
              >
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap">{{
                      texts.menu[item.value]
                    }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="child in item.items"
                  link
                  active-class="activeItem"
                  :to="child.path"
                  :key="child.title"
                  :disabled="child.disabled"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-wrap">{{
                      texts.subMenu[child.value]
                    }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-list>
            <v-divider></v-divider>
          </div>
          <div>
            <v-divider></v-divider>
            <div class="pa-9">
              <div>
                <v-btn
                  @click="
                    changeLanguage('en');
                    $store.commit('setFadeMenu', false);
                  "
                  :class="
                    selectLanguage == 'en'
                      ? 'mon-medium button-select-language-active mb-2'
                      : 'mon-medium button-select-language mb-2'
                  "
                  elevation="0"
                >
                  <img class="mr-2" src="@/assets/images/US.svg" alt="" />
                  English
                </v-btn>
              </div>
              <div>
                <v-btn
                  @click="
                    changeLanguage('sp');
                    $store.commit('setFadeMenu', false);
                  "
                  :class="
                    selectLanguage == 'sp'
                      ? 'mon-medium button-select-language-active mt-2'
                      : 'mon-medium button-select-language mt-2'
                  "
                  elevation="0"
                >
                  <img class="mr-2" src="@/assets/images/MX.svg" alt="" />
                  Español
                </v-btn>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="">
              <v-btn
                text
                class="button-session mon-regular px-0"
                @click="logout"
                style="font-family: 'montserrat-semibold'; justify-content: center"
              >
                <v-icon class="mr-2"> mdi-logout </v-icon>
                {{ texts.dashboard.logout }}
              </v-btn>
              <h5
                style="
                  font-family: 'montserrat-regular';
                  color: #ffffff;
                  font-size: 14px;
                  text-align: center;
                  font-weight: 500;
                "
              >
                {{ texts.dashboard.version }} {{ appVersion }} | {{ currentYear }} ©
                FRESHER
              </h5>
              <h5
                class="pb-3"
                style="
                  font-family: 'montserrat-regular';
                  color: #ffffff;
                  font-size: 14px;
                  text-align: center;
                  font-weight: 500;
                "
              >
                {{ texts.dashboard.allRightsReserved }}
              </h5>
            </div>
          </div>
        </div>
      </v-navigation-drawer>
      <!-- <v-app-bar dense height="83" app style="display: flex; justify-content: space-between; border-bottom: 1px solid #c7c7c7; align-items: center; padding: 1.25rem;" flat color="#ffffff">
      </v-app-bar> -->
      <v-app-bar color="#ffffff" absolute app height="83" persistent>
        <v-toolbar-title
          style="
            font-size: 0.875rem;
            line-height: 1.25rem;
            font-weight: 600;
            display: flex;
            align-items: center;
          "
          class="pa-0"
        >
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>
                  {{ currentDateCdmx }} Ciudad de México, GMT-6
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon dark large color="grey"> mdi-account-circle </v-icon>
        <v-list width="170px">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ userName }}</v-list-item-title>
              <v-list-item-subtitle>{{ "Administrador" }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <!-- <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div class="ml-3" style="display: flex; cursor: pointer; border: 1px #585858 solid; align-items: center; border-radius: 15px; padding: 10px" v-bind="attrs" v-on="on">
                <img
                  v-if="selectLanguage == 'sp'"
                  style="cursor: pointer;"
                  src="../../assets/images/Flags_mex.png"
                  alt="proplat-logo-white"
                  class="mr-3"
                  height="30"
                  width="50"
                />
                <img
                  v-else
                  style="cursor: pointer;"
                  src="../../assets/images/Flags_usa.png"
                  alt="proplat-logo-white"
                  class="mr-3"
                  height="30"
                  width="50"
                />
                <h5>{{ selectLanguage == "sp" ? "Español" : "English" }}</h5>
                
                <v-icon>mdi-menu-down</v-icon>
              </div>
            </template>
            <language-component style="background-color: black; padding: 15px;"></language-component>
          </v-menu>
        </div> -->
      </v-app-bar>
      <v-main style="background-color: #f9fafd">
        <router-view></router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DashboardView",
  data() {
    return {
      texts: "",
      loading: true,
      currentDate: moment(),
      currentDateCdmx: moment().clone().utcOffset("-06:00"),
      drawer: null,
      balance: "0",
      appVersion: "1.0.01",
      currentYear: moment().format("YYYY"),
      items: [],
      userName: "",
      tab: 0,
      editableData: {},
      selectedMenuItem: "",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY",
      },
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      timerCount: 30,
      sections: [],
      sTypeAccount: "",
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    createSubAccount(data) {
      const payload = {
        sBank_name: data.sBankName,
        sAccount_number: data.sAccountNumber,
        sRouting_number: data.sRoutingNumber,
        clabe: data.clabe,
        sBank_mxn_account: data.sBankMxnAccount,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/sub-account/`, payload, config)
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.getExchangeRate();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getDashboard() {
      this.loading = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/dashboard`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.loading = false;
          this.userName = response.data.sUserName;
        })
        .catch((error) => {
          if (error.response.data.code) {
            this.$router.push("/welcome").catch(() => {});
          }
          // this.isLoadingSuppliers = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    updateTime() {
      this.currentDate = moment();
      this.currentDateCdmx = moment().utcOffset("-06:00");
    },
    logout: function () {
      if (this.$store.state.sToken !== "") {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.delete(`${URI}/api/v1/${this.selectLanguage}/session`, config)
          .then((response) => {
            const self = this;

            localStorage.clear();
            this.$store.commit("setIsLogged", false);
            this.$store.commit("setFadeMenu", false);
            this.$store.commit("setToken", "");
            this.$store.commit("setFullName", "");
            this.$store.commit("setEnterpriseId", "");
            this.$store.commit("setUserId", "");

            setTimeout(function () {
              self.mixSuccess(response.data.message);
            }, 200);
            setTimeout(function () {
              self.$router
                .push({
                  path: "/login",
                })
                .catch(() => {});
            }, 400);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      }
    },
    changeLanguage(language) {
      const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        },
        payload = {};

      if (this.$store.state.sUserId) {
        DB.patch(
          `${URI}/api/v1/${language}/users/${this.$store.state.sUserId}/languages`,
          payload,
          config
        )
          .then((response) => {
            // Manejo de respuesta exitosa
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      }
      this.$store.commit("toggle_language", language);
    },
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.interval = setInterval(this.updateTime, 1000); // 1000 ms = 1 segundo
    if (this.$store.state.sTypeAccount == "EFEXUSD") {
      this.sections = [
        {
          icon: "mdi mdi-view-dashboard",
          value: "operation",
          title: "Operaciones",
          items: [
            {
              icon: "mdi mdi-view-dashboard",
              value: "dashboard",
              title: "Dashboard",
              path: "/dashboard",
              disabled: false,
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Seguimiento de facturas",
              path: "/invoices",
              disabled: false,
            },
            {
              icon: "mdi-forward",
              value: "activeForwards",
              title: "Forwards activos",
              path: "/forwards",
              disabled: false,
            },
            {
              icon: "mdi-cash",
              value: "balance",
              title: "Saldo disponible MXN",
              path: "/balance",
              disabled: false,
            },
            {
              icon: "mdi-calendar",
              value: "calendar",
              title: "Calendar",
              path: "/calendars",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-history",
          value: "history",
          title: "Historial",
          items: [
            {
              icon: "mdi-file-document",
              value: "historyIn",
              title: "Historial de ingresos",
              path: "/incomes",
              disabled: false,
            },
            {
              icon: "mdi-file-document",
              value: "historyConversion",
              title: "Historial de conversiones",
              path: "/conversions",
              disabled: false,
            },
            {
              icon: "mdi-file-document",
              value: "historyOut",
              title: "Historial de egresos",
              path: "/payments",
              disabled: false,
            },
            {
              icon: "mdi-note-text-outline",
              value: "statement",
              title: "Estado de cuenta",
              path: "/statement",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-wallet",
          value: "wallets",
          title: "Wallets",
          items: [
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Proveedores",
              path: "/suppliers",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-cog",
          value: "settings",
          title: "Ajustes",
          items: [
            {
              icon: "mdi-shield-account",
              value: "security",
              title: "Seguridad",
              path: "/security",
              disabled: false,
            },
          ],
        },
      ];
    } else {
      this.sections = [
        {
          icon: "mdi mdi-view-dashboard",
          value: "operation",
          title: "Operaciones",
          items: [
            {
              icon: "mdi mdi-view-dashboard",
              value: "dashboard",
              title: "Dashboard",
              path: "/dashboard",
              disabled: false,
            },
            {
              icon: "mdi-file-document",
              value: "history",
              title: "Seguimiento de facturas",
              path: "/invoicesReceivable",
              disabled: false,
            },
            {
              icon: "mdi-forward",
              value: "activeForwardsMX",
              title: "Forwards activos",
              path: "/forwardsMX",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-history",
          value: "history",
          title: "Historial",
          items: [
            {
              icon: "mdi-file-document",
              value: "historyIn",
              title: "Historial de ingresos",
              path: "/incomes",
              disabled: false,
            },
            {
              icon: "mdi-file-document",
              value: "historyConversionMX",
              title: "Historial conversiones ",
              path: "/conversionsMX",
              disabled: false,
            },
            {
              icon: "mdi-file-document",
              value: "historyOut",
              title: "Historial egresos",
              path: "/paymentsMX",
              disabled: false,
            },
            {
              icon: "mdi-note-text-outline",
              value: "statement",
              title: "Estado de cuenta",
              path: "/statement",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-account",
          value: "wallets",
          title: "Wallets",
          items: [
            {
              icon: "mdi-account-group",
              value: "clients",
              title: "Clientes",
              path: "/clients",
              disabled: false,
            },
            {
              icon: "mdi-account-group",
              value: "suppliers",
              title: "Proveedores",
              path: "/suppliers",
              disabled: false,
            },
          ],
        },
        {
          icon: "mdi-cog",
          value: "settings",
          title: "Ajustes",
          items: [
            {
              icon: "mdi-shield-account",
              value: "security",
              title: "Seguridad",
              path: "/security",
              disabled: false,
            },
          ],
        },
      ];
    }
    this.getDashboard();
  },
  mounted() {
    this.currentTime = this.currentDate.format("MMMM DD, YYYY, HH:mm:ss [GMT]Z");
    this.currentTimeCdmx = this.currentDateCdmx.format("MMMM DD, YYYY, HH:mm:ss [GMT-6]");
  },
  beforeDestroy() {
    // Limpia el intervalo cuando el componente se destruye
    clearInterval(this.intervalId);
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    },
  },
};
</script>

<style>
/* .v-list-item--active {
  color: yellow !important;
  background-color: yellow !important;
} */

.custom-bold {
  font-family: "montserrat-bold";
  font-weight: bold;
  font-size: medium;
  color: #000;
}

.v-dialog {
  border-radius: 25px !important;
}
.active-section .v-list-item__title {
  background-color: yellow !important;
}

.active-item {
  background-color: #f5f7f9 !important;
}

.v-list-item__content {
  font-family: "montserrat-semibold";
}

.activeGroup {
  border-left: 8px #61a3d9 solid;
  border-radius: 5px;
  color: #ffffff !important;
  background-color: #163969;
}
.activeItem {
  border-left: 8px #61a3d9 solid;
  border-radius: 5px;
  color: #ffffff !important;
  background-color: #0075b8;
}
.button-select-language {
  width: 100%;
  height: 40px !important;
  background-color: transparent !important;
  border-radius: 10px;
  opacity: 1;
  color: #ffffff !important;
  font-size: 14px !important;
  text-transform: capitalize;
  justify-content: flex-start;
}

.button-select-language-active {
  width: 100%;
  height: 40px !important;
  background: #163969 0% 0% no-repeat padding-box !important;
  border-radius: 10px;
  opacity: 1;
  color: #ffffff !important;
  font-size: 14px !important;
  text-transform: capitalize;
  justify-content: flex-start;
}

.button-session {
  background-color: transparent !important;
  height: 70px !important;
  width: 100% !important;
  border-radius: 0px;
  color: #ffffff !important;
  font-size: 16px;
  text-transform: initial;
  justify-content: flex-start;
  padding-left: 40px !important;
}

.text-wrap {
  white-space: normal;
  word-wrap: break-word;
}
</style>
