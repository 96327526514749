<template>
  <div class="pt-4">
    <v-container fluid class="px-15">
      <div>
        <v-btn @click="$router.go(-1)" text class="pl-0">
          <v-icon class="mr-1" size="15px"> mdi-arrow-left </v-icon>
          <span class="text-return mon-regular" style="font-family: 'montserrat-medium';">{{ "Regresar" }}</span>
        </v-btn>
      </div>
      <div v-if="!isLoading">
        <div v-if="!isLoading">
          <v-row justify="start" align="start" class="ma-0 mt-5 mb-4">
            <v-col cols="12" lg="4" class="pa-0">
            </v-col>
            <v-col cols="12" lg="4">
              <div class="headline-medium text-primary" style="text-align: center;">{{ "Detalle de la factura" }}</div>
            </v-col>
            <v-col cols="12" lg="4" style="text-align: end;">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="editInvoice()"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="invoice.sStatusId == 'f42d5b71-bcc8-4c2c-997a-40ad2da2bc31'"
                    icon
                    color="black"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar factura</span>
              </v-tooltip>
              <v-btn
                class="button-save ml-4"
                style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                elevation="0"
                color="#193D6E"
                :disabled="invoice.sStatusId == 'f42d5b71-bcc8-4c2c-997a-40ad2da2bc31'"
                @click="openPayInvoices(conversion)"
                >{{ "Pagar factura" }}
              </v-btn>
            </v-col>
          </v-row>
          <v-card-text class="pb-5 px-10">
            <div class="my-8"  style="display: flex; justify-content: space-around; flex-direction: column;">
              <div style="display: flex; justify-content: space-between;">
                <div>
                  <div style="color: black; font-size: 20px; text-align: start;">
                    {{ "Factura" }}
                  </div>
                  <div style="color: black; font-size: 22px; font-weight: bold;">
                    {{ invoice.sInvoice }}
                  </div>
                </div>
                <div>
                  <div style="color: black; font-size: 20px; text-align: center;">
                    {{ "Monto" }}
                  </div>
                  <div style="color: black; font-size: 22px; font-weight: bold;">
                    {{ "$ " + formatMoney(invoice.dTotalAmount) + " MXN" }}
                  </div>
                </div>
                <div>
                  <div style="color: black; font-size: 20px; text-align: center;">
                    {{ "Status" }}
                  </div>
                  <div style="color: black; font-size: 22px; font-weight: bold;">
                    <v-chip
                      v-if="invoice.sStatusId == '453f47d8-d5ad-4159-be49-b48b0f04caa5'"
                      color="primary"
                      outlined
                    >
                      <h4 style="font-family: 'montserrat-medium';">{{ "To pay" }}</h4>
                    </v-chip>
                    <v-chip
                      v-else-if="invoice.sStatusId == '904a91ab-bdfc-45dc-a72f-fc02c2a15005'"
                      outlined
                      color="green"
                    >
                      <h4 style="font-family: 'montserrat-medium';">{{ "Assigned" }}</h4>
                    </v-chip>
                    <v-chip
                      v-else-if="invoice.sStatusId == 'f42d5b71-bcc8-4c2c-997a-40ad2da2bc31'"
                      outlined
                      color="#193D6E"
                    >
                      <h4 style="font-family: 'montserrat-medium';">{{ "Paid" }}</h4>
                    </v-chip>
                    <v-chip
                      v-else-if="invoice.sStatusId == '63b83525-e0ff-4d52-821a-df20611cb4a3'"
                      outlined
                      color="red"
                    >
                      <h4 style="font-family: 'montserrat-medium';">{{ "Parcial" }}</h4>
                    </v-chip>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="invoice" class="my-8" style="display: flex; justify-content: space-around; flex-direction: column;">
              <div style="display: flex; justify-content: space-between;">
                <div>
                  <div style="color: black; font-size: 20px; text-align: center;">
                    {{ "Purchase Order" }}
                  </div>
                  <div style="color: black; font-size: 22px; font-weight: bold; text-align: start;">
                    {{ invoice.sId }}
                  </div>
                </div>
                <div>
                  <div style="color: black; font-size: 20px; text-align: center;">
                    {{ "Tipo de cambio protegido" }}
                  </div>
                  <div style="color: black; font-size: 22px; font-weight: bold; text-align: center;">
                    {{ "$ " + formatMoney(invoice.dRateProtection) + " MXN" }}
                  </div>
                </div>
                <div>
                  <div style="color: black; font-size: 20px; text-align: end;">
                    {{ "Proveedor" }}
                  </div>
                  <div v-if="invoice" style="color: black; font-size: 22px; font-weight: bold;">
                    {{ invoice?.paymentsRecipent[0].sName }}
                  </div>
                </div>
              </div>
            </div>
            <div class="my-8"  style="display: flex; justify-content: space-around; flex-direction: column;">
              <div style="display: flex; justify-content: space-between;">
                <div>
                  <div style="color: black; font-size: 20px; text-align: center;">
                    {{ "Fecha de compra" }}
                  </div>
                  <div style="color: black; font-size: 22px; font-weight: bold;">
                    {{ formatDate(invoice.dPurchaseDate) }}
                  </div>
                </div>
                <div>
                  <div style="color: black; font-size: 20px; text-align: center;">
                    {{ "Dias de credito" }}
                  </div>
                  <div style="color: black; font-size: 22px; font-weight: bold; text-align: center;">
                    {{ invoice.iCreditPeriod + " dias" }}
                  </div>
                </div>
                <div>
                  <div style="color: black; font-size: 20px; text-align: center;">
                    {{ "Fecha de vencimiento" }}
                  </div>
                  <div style="color: black; font-size: 22px; font-weight: bold;">
                    {{ formatDate(invoice.dDueDate) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-8">
              <div class="mb-4" style="color: black; font-size: 22px; font-weight: bold;">{{ "Porcentaje asignado" }}</div> 
              <v-progress-linear
                height="45"
                :value="((invoice.dTotalAmountAssign / invoice.dTotalAmount) * 100)"
              >
                <strong style="color: white;">{{ formatMoney(((invoice.dTotalAmountAssign / invoice.dTotalAmount) * 100)) }}%</strong>
              </v-progress-linear>
            </div>
          <v-card elevation="5" class="pa-4">
            <div
              class="mb-6"
              style="
                display: flex;
                justify-content: space-around;
                color: black;
                font-size: 22px;
                font-weight: bold;
                align-items: center;
              "
            >
              <div>Conversiones asignadas</div>
              <v-spacer></v-spacer>
              <v-btn
                style="
                  text-align: center;
                  color: #0075B8;
                  font-size: 15px;
                  background-color: transparent;
                  font-family: montserrat-bold;
                  font-weight: 600;
                  line-height: 24px;
                  text-transform: capitalize;
                  word-wrap: break-word;
                  border-radius: 5px;
                  border: 1px solid #0075B8;
                  padding: 8px 16px;
                "
                elevation="0"
                @click="openTransactions()"
                class="ml-2"
                :disabled="false"
              >
                <v-icon left>mdi mdi-file-document-plus-outline</v-icon>
                Editar Conversiones Asignadas
              </v-btn>
            </div>
            <v-data-table
              item-key="sInvoiceTransactionId"
              :headers="headersForex"
              disable-sort
              :items="invoice.transacionsAssigned"
            >
              <template v-slot:item.sTransactionType="{ item }">
                <v-chip
                  v-if="item.transaction.sTransactionType == 'b80e8dc6-4138-449e-8903-968efb8437b3'"
                  class="ma-2"
                  color="red"
                  outlined
                >
                  {{ "Forward" }}
                </v-chip>
                <v-chip
                  v-else-if="
                    item.transaction.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a'
                  "
                  class="ma-2"
                  color="blue"
                  outlined
                >
                  {{ "Forex" }}
                </v-chip>
              </template>
              <template v-slot:item.iTrackingId="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  {{ item.transaction.iTrackingId }}
                </h4>
              </template>
              <template v-slot:item.sTransactionAvaible="{ item }">
                <v-icon v-if="item.transaction.sStatusId == '5a792637-1103-4a89-8f67-86c49b93e227'" style="color: green;">mdi-check-circle</v-icon>
                <v-icon v-else="item.transaction.sStatusId == '5a792637-1103-4a89-8f67-86c49b93e227'" style="color: red;">mdi-close-circle</v-icon>
              </template>
              <template v-slot:item.sSupplier="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  {{ item.transaction.sSupplier ? item.transaction.sSupplier : "N/A" }}
                </h4>
              </template>
              <template v-slot:item.dPercentAssigned="{ item }">
                <v-progress-linear
                  :value="item.percent"
                  height="15"
                  style="border-radius: 15px"
                >
                  <h4 class="py-3" style="color: white">{{ Math.ceil(item.percent) }}%</h4>
                </v-progress-linear>
              </template>
              <template v-slot:item.dAmount="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  $ {{ formatMoney(item.dAmount / (item.transaction.dDesAmount / item.transaction.dAmount)) }} USD
                </h4>
              </template>
              <template v-slot:item.dDesAmount="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  $ {{ formatMoney(item.dAmount) }} MXN
                </h4>
              </template>
              <template v-slot:item.dExchangeRate="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  $ {{ formatMoney(item.transaction.dExchangeRate) }} MXN
                </h4>
              </template>
              <template v-slot:item.created_at="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                  {{ formatDate(item.transaction.created_at) }}
                </h4>
              </template>
              <template v-slot:item.purchaseDate="{ item }">
                <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.transaction.purchaseDate ? item.transaction.purchaseDate : "N/A" }}  </h4>
              </template>
              <template v-slot:item.percentProfit="{ item }">
                <h4
                  v-if="item.transaction.percentProfit"
                  :style="{ color: item.transaction.percentProfit < 0 ? 'red' : 'green' }"
                  style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                >
                  {{ item.transaction.percentProfit ? formatMoney(item.transaction.percentProfit) + "%" : "N/A" }}
                </h4>
                <h4 v-else>{{ "N/A" }}</h4>
              </template>
              <template v-slot:item.dollarProfit="{ item }">
                <h4
                  v-if="item.transaction.dollarProfit"
                  :style="{ color: item.transaction.dollarProfit < 0 ? 'red' : 'green' }"
                  style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                >
                  {{
                    item.transaction.dollarProfit ? "$ " + formatMoney(item.transaction.dollarProfit) + " USD" : ""
                  }}
                </h4>
                <h4 v-else>{{ "N/A" }}</h4>
              </template>
              <template v-slot:item.actions="{ item }">
                <div style="display: flex; align-items: center; justify-content: center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="goToConversion(item)"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="true"
                        icon
                        color="black"
                      >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>Ver detalle de conversión</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card>
          </v-card-text>
        </div>
      </div>
      <v-skeleton-loader
        v-else
        class="mx-3 my-3"
        height="500px"
        type="image"
      ></v-skeleton-loader>
    </v-container>
    <v-dialog v-model="dialog" persistent>
      <v-card class="px-3 py-3">
        <div style="width: 100%; text-align: end;">
          <v-btn icon @click="dialog = false">
            <v-icon color="#2E404E">mdi-close</v-icon>
          </v-btn>
        </div>
         
        <v-card elevation="5" class="mb-6 mx-6 mt-2" style="display: flex; justify-content: space-between;">
          <div class="pa-5" style="display: flex; flex-direction: column;">
            <span class="mon-bold">Cantidad aplicada:</span>
            <span class="mon-bold">{{"$ " + formatMoney(invoice?.totalAmount ? invoice?.totalAmount : "") + " MXN"}}</span>
          </div>
          <div class="pa-5" style="display: flex; flex-direction: column;">
            <span class="mon-bold">Disponible:</span>
            <span class="mon-bold">{{"$ " + formatMoney(invoice?.avaibleAmount ? invoice?.avaibleAmount : "") + " MXN"}}</span>
          </div>
        </v-card>
        
        <v-card elevation="5" class="mb-6 mx-6">
          <v-card-text>
            <v-row class="pt-4" style="flex-direction: column; overflow-x: auto;">
              <h2 class="pa-4" style="color: black;">Conversiones Aplicadas</h2>
              <v-data-table
                item-key="sInvoiceTransactionId"
                :headers="headersForex"
                disable-sort
                :items="invoice?.transacionsAssigned"
                :loading="isLoading"
              >
                <template v-slot:item.sTransactionType="{ item }">
                  <v-chip
                    v-if="item.transaction.sTransactionType == 'b80e8dc6-4138-449e-8903-968efb8437b3'"
                    class="ma-2"
                    color="red"
                    outlined
                  >
                    {{ "Forward" }}
                  </v-chip>
                  <v-chip
                    v-else-if="
                      item.transaction.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a'
                    "
                    class="ma-2"
                    color="blue"
                    outlined
                  >
                    {{ "Forex" }}
                  </v-chip>
                </template>
                <template v-slot:item.sTransactionAvaible="{ item }">
                  <v-icon v-if="item.transaction.sStatusId == '5a792637-1103-4a89-8f67-86c49b93e227'" style="color: green;">mdi-check-circle</v-icon>
                  <v-icon v-else="item.transaction.sStatusId == '5a792637-1103-4a89-8f67-86c49b93e227'" style="color: red;">mdi-close-circle</v-icon>
                </template>
                <template v-slot:item.iTrackingId="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    {{ item.transaction.iTrackingId }}
                  </h4>
                </template>
                <template v-slot:item.sSupplier="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    {{ item.transaction.sSupplier ? item.transaction.sSupplier : "N/A" }}
                  </h4>
                </template>
                <template v-slot:item.dPercentAssigned="{ item }">
                  <v-progress-linear
                    :value="item.percent"
                    height="15"
                    style="border-radius: 15px"
                  >
                    <h4 class="py-3" style="color: white">{{ Math.ceil(item.percent) }}%</h4>
                  </v-progress-linear>
                </template>
                <template v-slot:item.dAmount="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    $ {{ formatMoney(item.dAmount / (item.transaction.dDesAmount / item.transaction.dAmount)) }} USD
                  </h4>
                </template>
                <template v-slot:item.dDesAmount="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    $ {{ formatMoney(item.dAmount) }} MXN
                  </h4>
                </template>
                <template v-slot:item.dExchangeRate="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    $ {{ formatMoney(item.transaction.dExchangeRate) }} MXN
                  </h4>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    {{ formatDate(item.transaction.created_at) }}
                  </h4>
                </template>
                <template v-slot:item.purchaseDate="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.transaction.purchaseDate ? item.transaction.purchaseDate : "N/A" }}  </h4>
                </template>
                <template v-slot:item.percentProfit="{ item }">
                  <h4
                    v-if="item.transaction.percentProfit"
                    :style="{ color: item.percentProfit < 0 ? 'red' : 'green' }"
                    style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                  >
                    {{ item.transaction.percentProfit ? formatMoney(item.transaction.percentProfit) + "%" : "N/A" }}
                  </h4>
                  <h4 v-else>{{ "N/A" }}</h4>
                </template>
                <template v-slot:item.dollarProfit="{ item }">
                  <h4
                    v-if="item.transaction.dollarProfit"
                    :style="{ color: item.dollarProfit < 0 ? 'red' : 'green' }"
                    style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                  >
                    {{
                      item.transaction.dollarProfit ? "$ " + formatMoney(item.transaction.dollarProfit) + " USD" : ""
                    }}
                  </h4>
                  <h4 v-else>{{ "N/A" }}</h4>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div style="display: flex; align-items: center; justify-content: center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="openEditConversion(item)"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="invoice.sStatusId == 'f42d5b71-bcc8-4c2c-997a-40ad2da2bc31'"
                          icon
                          color="black"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Editar monto asignado</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="openDialogDeleteConversion(item)"
                          v-bind="attrs"
                          :disabled="invoice.sStatusId == 'f42d5b71-bcc8-4c2c-997a-40ad2da2bc31'"
                          v-on="on"
                          icon
                          color="red"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar conversión de la factura</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card elevation="5" class="mb-6 mx-6">
          <v-card-text>
            <v-row class="pt-4" style="flex-direction: column; overflow-x: auto;">
              <h2 class="pa-4" style="color: black;">Conversiones Disponibles</h2>
              <v-data-table
                :headers="headersAvaibleTransaction"
                disable-sort
                :items="avaibleTransactions"
                :loading="loadingConversion"
              >
                <template v-slot:item.sTransactionType="{ item }">
                  <v-chip
                    v-if="item.sTransactionType == 'b80e8dc6-4138-449e-8903-968efb8437b3'"
                    class="ma-2"
                    color="red"
                    outlined
                  >
                    {{ "Forward" }}
                  </v-chip>
                  <v-chip
                    v-else-if="
                      item.sTransactionType == 'e7b157eb-8dcb-4655-8d0e-4eb4e7e9270a'
                    "
                    class="ma-2"
                    color="blue"
                    outlined
                  >
                    {{ "Forex" }}
                  </v-chip>
                </template>
                <template v-slot:item.iTrackingId="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    {{ item.iTrackingId }}
                  </h4>
                </template>
                <template v-slot:item.sSupplier="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    {{ item.sSupplier ? item.sSupplier : "N/A" }}
                  </h4>
                </template>
                <template v-slot:item.dPercentAssigned="{ item }">
                  <v-progress-linear
                    :value="item.percent"
                    height="15"
                    style="border-radius: 15px"
                  >
                    <h4 class="py-3" style="color: white">{{ Math.ceil(item.percent) }}%</h4>
                  </v-progress-linear>
                </template>
                <template v-slot:item.dAmount="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    $ {{ formatMoney(item.dAmount) }} USD
                  </h4>
                </template>
                <template v-slot:item.dDesAmount="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    $ {{ formatMoney(item.dDesAmount) }} MXN
                  </h4>
                </template>
                <template v-slot:item.dExchangeRate="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    $ {{ formatMoney(item.dExchangeRate) }} MXN
                  </h4>
                </template>
                <template v-slot:item.created_at="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif">
                    {{ formatDate(item.created_at) }}
                  </h4>
                </template>
                <template v-slot:item.purchaseDate="{ item }">
                  <h4 style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif;"> {{ item.purchaseDate ? item.purchaseDate : "N/A" }}  </h4>
                </template>
                <template v-slot:item.percentProfit="{ item }">
                  <h4
                    v-if="item.percentProfit != 0"
                    :style="{ color: item.percentProfit < 0 ? 'red' : 'green' }"
                    style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                  >
                    {{ item.percentProfit ? formatMoney(item.percentProfit) + "%" : "N/A" }}
                  </h4>
                  <h4 v-else>{{ "N/A" }}</h4>
                </template>
                <template v-slot:item.dollarProfit="{ item }">
                  <h4
                    v-if="item.dollarProfit != 0"
                    :style="{ color: item.dollarProfit < 0 ? 'red' : 'green' }"
                    style="text-wrap: nowrap; font-family: 'Montserrat', sans-serif"
                  >
                    {{
                      item.dollarProfit ? "$ " + formatMoney(item.dollarProfit) + " USD" : ""
                    }}
                  </h4>
                  <h4 v-else>{{ "N/A" }}</h4>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div style="display: flex; align-items: center; justify-content: center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="openAddConversion(item)"
                          v-bind="attrs"
                          v-on="on"
                          icon
                          :disabled="invoice?.avaibleAmount == 0"
                          color="black"
                        >
                          <v-icon left>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                      <span>Agregar conversion</span>
                    </v-tooltip>
                  </div>
                </template>
              </v-data-table>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditConversion" persistent width="fit-content">
      <v-card v-if="!loading">
        <v-card-title style="display: flex; justify-content: center">
          <span class="text-h4" style="color: black;">{{ "Editar conversion" }}</span> 
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="formEdit"
              style="width: 100%"
              lazy-validation
              v-on:submit.prevent
            >
              <v-row style="display: flex; align-items: center;">
                <v-col cols="12" md="6">
                  <h2 class="mon-bold" style="color: black;">Total de factura: </h2>
                </v-col>
                <v-col cols="12" md="6" style="text-align: end;">
                  <span class="mon-bold">{{"$ " + formatMoney(invoice?.dTotalAmount) + " MXN"}}</span>
                </v-col>
              </v-row>
              <v-row style="display: flex; align-items: center;">
                <v-col cols="12" md="6">
                  <h2 class="mon-bold" style="color: black;">Por aplicar: </h2>
                </v-col>
                <v-col cols="12" md="6" style="text-align: end;">
                  <span class="mon-bold">{{"$ " + formatMoney(parseFloat(invoice?.dTotalAmount) - (parseFloat(invoice?.totalAmount) - parseFloat(prevAmountSelected) + parseFloat(conversionSelected?.dAmount ? conversionSelected?.dAmount : 0))) + " MXN"}}</span>
                </v-col>
              </v-row>
              <v-row style="display: flex; align-items: center;">
                <v-col cols="12" md="6">
                  <h2 class="mon-bold" style="color: black;">Disponible de conversion: </h2>
                </v-col>
                <v-col cols="12" md="6" style="text-align: end;">
                  <span class="mon-bold">{{"$ " + formatMoney(parseFloat(conversionSelected.dTotalAmount) - parseFloat(conversionSelected?.dAmount ? conversionSelected?.dAmount : 0)) + " MXN"}}</span>
                </v-col>
              </v-row>
              <v-row class="mt-0" style="display: flex; align-items: center;">
                <v-col cols="12" md="6">
                  <h2 class="mon-bold" style="color: black;">Monto a aplicar: </h2>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="texts.invoices.invoice + '*'"
                    required
                    outlined
                    type="number"
                    hide-details
                    v-model="conversionSelected.dAmount"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ texts.invoices.requiredField }}</small>
        </v-card-text>
        <v-card-actions class="pb-3" style="display: flex; width: 100%; justify-content: space-around;">
          <div style="width: 45%;">
            <v-btn color="black" block text outlined :disabled="loading" @click="dialogEditConversion = false"> {{ texts.modalForm.cancel }} </v-btn>
          </div>
          <div style="width: 45%;">
            <v-btn class="button-save"
              style="width: 200px; color: #FFF; background-color: #193D6E;"
              elevation="0"
              block
              :disabled="loading || parseFloat(invoice?.dTotalAmount) - (parseFloat(invoice?.totalAmount) - parseFloat(prevAmountSelected) + parseFloat(conversionSelected?.dAmount ? conversionSelected?.dAmount : 0)) < 0 || parseFloat(conversionSelected.dTotalAmount) - parseFloat(conversionSelected?.dAmount ? conversionSelected?.dAmount : 0) < 0"
              @click="updateConversion()"> 
              {{ texts.modalForm.save }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
      <v-skeleton-loader
        v-else
        width="700px"
        type="image"
      ></v-skeleton-loader>
    </v-dialog>
    <v-dialog v-model="dialogAddConversion" persistent width="fit-content">
      <v-card v-if="!loading">
        <v-card-title style="display: flex; justify-content: center">
          <span class="text-h4" style="color: black;">{{ "Agregar conversion" }}</span> 
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="formEdit"
              style="width: 100%"
              lazy-validation
              v-on:submit.prevent
            >
              <v-row style="display: flex; align-items: center;">
                <v-col cols="12" md="6">
                  <h2 class="mon-bold" style="color: black;">Total de factura: </h2>
                </v-col>
                <v-col cols="12" md="6" style="text-align: end;">
                  <span class="mon-bold">{{"$ " + formatMoney(invoice?.dTotalAmount) + " MXN"}}</span>
                </v-col>
              </v-row>
              <v-row style="display: flex; align-items: center;">
                <v-col cols="12" md="6">
                  <h2 class="mon-bold" style="color: black;">Por aplicar: </h2>
                </v-col>
                <v-col cols="12" md="6" style="text-align: end;">
                  <span class="mon-bold">{{"$ " + formatMoney(invoice?.avaibleAmount - conversionSelected?.dAmount) + " MXN"}}</span>
                </v-col>
              </v-row>
              <v-row style="display: flex; align-items: center;">
                <v-col cols="12" md="6">
                  <h2 class="mon-bold" style="color: black;">Disponible de conversion: </h2>
                </v-col>
                <v-col cols="12" md="6" style="text-align: end;">
                  <span class="mon-bold">{{"$ " + formatMoney(conversionSelected.dTotalAmount - conversionSelected?.dAmount) + " MXN"}}</span>
                </v-col>
              </v-row>
              <v-row class="mt-0" style="display: flex; align-items: center;">
                <v-col cols="12" md="6">
                  <h2 class="mon-bold" style="color: black;">Monto a aplicar: </h2>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    :label="texts.invoices.invoice + '*'"
                    required
                    type="number"
                    outlined
                    hide-details
                    v-model="conversionSelected.dAmount"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ texts.invoices.requiredField }}</small>
        </v-card-text>
        <v-card-actions class="pb-3" style="display: flex; width: 100%; justify-content: space-around;">
          <div style="width: 45%;">
            <v-btn color="black" block text outlined :disabled="loading" @click="dialogAddConversion = false"> {{ texts.modalForm.cancel }} </v-btn>
          </div>
          <div style="width: 45%;">
            <v-btn class="button-save"
              style="max-width: 200px; color: #FFF; background-color: #193D6E;"
              elevation="0"
              block
              :disabled="loading || parseFloat(invoice?.avaibleAmount - conversionSelected?.dAmount).toFixed(2) < 0 || parseFloat(conversionSelected.dTotalAmount - conversionSelected?.dAmount).toFixed(2) < 0"
              @click="addConversion()"> 
              {{ texts.modalForm.save }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
      <v-skeleton-loader
        v-else
        width="700px"
        type="image"
      ></v-skeleton-loader>
    </v-dialog>
    <v-dialog v-model="dialogDeleteConversion" persistent width="fit-content">
      <v-card>
        <v-card-title class="text-h5 mb-4">
          {{ "¿Estás seguro de que deseas eliminar la asignacion de esta conversion?" }}
        </v-card-title>
        <v-card-text v-if="!loading" class="my-6"
          >{{ "Una vez que eliminas la conversion se ira a saldo disponible MXN." }}</v-card-text
        >
        <div v-else style="display: flex; justify-content: center">
          <v-progress-circular indeterminate color="amber"></v-progress-circular>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" text :disabled="loading" @click="dialogDeleteConversion = false"> {{ texts.modalForm.cancel }} </v-btn> 
          <v-btn color="#193D6E" class="white--text" :disabled="loading" @click="deleteConversion()">
            {{ texts.modalForm.delete }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogSendPayOfInvoice" persistent width="fit-content" class="pa-8">
      <v-card>
        <v-stepper v-model="step" alt-labels elevation="0">
          <v-stepper-header
            color="#193D6E"
            class="mt-5 mr-15 ml-15"
            elevation="0"
            style="justify-content: center; align-items: center; justify-items: center; align-content: center; box-shadow: none;"
          >
            <v-stepper-step :complete="step > 1" step="1" color="#193D6E"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 2" step="2" color="#193D6E"></v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step :complete="step > 3" step="3" color="#193D6E"></v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
          <!-- Paso 1: Monto de pago -->
            <v-stepper-content step="1" class="pt-0">
              <v-container class="mt-1">
                  <v-card-title style="display: flex; justify-content: center">
                    <span class="text-h4" style="color: black;">{{ "Selecciona monto" }}</span> 
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-form
                        ref="formEdit"
                        style="width: 100%"
                        lazy-validation
                        v-on:submit.prevent
                      >
                        <v-row style="display: flex; align-items: center;">
                          <v-col cols="12" md="6">
                            <h2 class="mon-bold" style="color: black;">Total de factura: </h2>
                          </v-col>
                          <v-col cols="12" md="6" style="text-align: end;">
                            <span class="mon-bold">{{"$ " + formatMoney(invoice?.dTotalAmount - dAmountToPay) + " MXN"}}</span>
                          </v-col>
                        </v-row>
                        
                        <v-row style="display: flex; align-items: center;">
                          <v-col cols="12" md="6">
                            <h2 class="mon-bold" style="color: black;">Disponible para pagar: </h2>
                          </v-col>
                          <v-col cols="12" md="6" style="text-align: end;">
                            <span class="mon-bold">{{"$ " + formatMoney(invoice?.avaibleToPayAmount - dAmountToPay) + " MXN"}}</span>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0" style="display: flex; align-items: center;">
                          <v-col cols="12" md="6">
                            <h2 class="mon-bold" style="color: black;">Monto a aplicar: </h2>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              :label="texts.invoices.invoice + '*'"
                              required
                              hide-details
                              type="number"
                              outlined
                              v-model="dAmountToPay"
                              :rules="[validateAmount]"
                              :disabled="loading"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                    <small>*{{ texts.invoices.requiredField }}</small>
                  </v-card-text>
                <div v-if="!loading" style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    class="button-cancel mon-regular"
                    @click="cancelPay()"
                    :disabled="loading"
                  >
                    {{ "Cancelar" }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                    elevation="0"
                    :disabled="parseFloat(invoice?.dTotalAmount) != dAmountToPay || loading || invoice?.avaibleToPayAmount - dAmountToPay < 0"
                    @click="step = 2"
                    >Continuar
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
            <!-- Paso 2: Detalle de pago -->
            <v-stepper-content step="2" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center">
                  <span class="title font-weight-bold text-textblack"> Detalle de pago </span>
                </v-card-title>
                <v-card-text class="py-6 pb-0" v-if="!loading">
                  <div
                    class="mb-8"
                    style="
                      display: flex;
                      justify-content: space-around;
                      background-color: #e2e8f0;
                      flex-direction: column;
                      width: 600px;
                    "
                  >
                    <div
                      class="pt-6 pb-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        Nombre del cliente:
                      </div>
                      <div v-if="invoice.paymentsRecipent" class="" style="color: black; font-size: 22px; font-weight: bold">
                        {{ invoice?.paymentsRecipent[0]?.sName }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">Número CLABE:</div>
                      <div v-if="invoice.paymentsRecipent" class="" style="color: black; font-size: 22px; font-weight: bold">
                        {{ "***********" + invoice?.paymentsRecipent[0].sAccountNumber.slice(-4) }}
                      </div>
                    </div>
                    <div
                      class="py-4 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        El destinatario recibirá:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold">
                        $ {{ formatMoney(dAmountToPay) }} MXN
                      </div>
                    </div>
                    <div
                      class="py-4 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="" style="color: black; font-size: 16px">
                        Fecha de envío:
                      </div>
                      <div class="" style="color: black; font-size: 22px; font-weight: bold">
                        {{ formatDate(today) }}
                      </div>
                    </div>
                    <div
                      class="mt-0 pb-6 px-10"
                      style="display: flex; justify-content: space-between"
                    >
                      <v-textarea
                        name="input-7-1"
                        label="Concepto de pago"
                        v-model="comment"
                        no-resize
                      ></v-textarea>
                    </div>
                  </div>
                </v-card-text>
                <div v-else style="display: flex; justify-content: center">
                  <v-progress-circular indeterminate color="amber"></v-progress-circular>
                </div>
                <div v-if="!loading" style="display: flex; justify-content: end; margin-top: 20px">
                  <v-btn
                    elevation="0"
                    class="button-cancel mon-regular"
                    @click="step = 1"
                    :disabled="loading"
                  >
                    {{ "Regresar" }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="button-save"
                    style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                    elevation="0"
                    :disabled="!comment || loading"
                    @click="sendVerification(3)"
                    >Continuar
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>

            <!-- Paso 3: Verificación OTP -->
            <v-stepper-content step="3" class="pt-0">
              <v-container class="mt-1">
                <v-card-title class="pa-0" style="display: flex; text-align: center; justify-content: center;">
                  <span class="title font-weight-bold text-textblack">{{ texts.twoFactor.twoFactor }}</span>
                </v-card-title>
                <v-card-text class="py-6 pb-0">
                  <div class="pt-4 px-4 mb-4" style="display: flex; justify-content: space-around; flex-direction: row; width: 600px">
                    <div>
                      <v-otp-input v-model="otp" length="6" type="number"></v-otp-input>
                      <p style="font-size: 14px; font-weight: 500;">{{ texts.twoFactor.enterCode }}</p>
                    </div>
                  </div>
                </v-card-text>
                <div style="display: flex; justify-content: end; margin-top: 20px;">
                  <v-btn elevation="0" class="button-cancel mon-regular" @click="step = 2" :disabled="loading">{{ texts.modalForm.cancel }}</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="button-save"
                    style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                    elevation="0"
                    :disabled="otp.length < 6 || loading"
                    color="#193D6E"
                    @click="sendPayment()">
                    {{ texts.modalForm.confirmPay }}
                  </v-btn>
                </div>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditInvoice" persistent max-width="600px">
      <v-card>
        <v-card-title style="display: flex; justify-content: center">
          <span class="text-h5">{{ texts.invoices.editInvoice }}</span> 
        </v-card-title>
        <v-card-subtitle class="pb-0 mt-3">
          <span style="font-size: 15px"
            >{{ texts.invoices.addInvoiceDetail }}</span>
        </v-card-subtitle>
        <v-card-text>
          <v-container>
            <v-row>
              <v-form
                ref="formEdit"
                style="width: 100%"
                lazy-validation
                v-on:submit.prevent
              >
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.invoice + '*'"
                    required
                    v-model="forms.detail.sInvoice"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.sId"
                    v-model="forms.detail.sId"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    :label="texts.invoices.supplier + '*'" 
                    required
                    :items="suppliers"
                    item-value="sPaymentRecipientId"
                    item-text="sName"
                    v-model="forms.detail.sPaymentRecipientId"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.purchaseDate + '*'" 
                    required
                    type="date"
                    v-model="forms.detail.dPurchaseDate"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.totalAmountMXN + '*'" 
                    required
                    type="number"
                    v-model="forms.detail.dTotalAmount"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.creditTime + '*'" 
                    required
                    type="number"
                    v-model="forms.detail.iCreditPeriod"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="texts.invoices.exchangeRateProtected + '*'" 
                    required
                    type="number"
                    v-model="forms.detail.dRateProtection"
                    :rules="[rules.required]"
                    :disabled="loading"
                  ></v-text-field>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
          <small>*{{ texts.invoices.requiredField }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
              <v-btn color="black" text :disabled="loading" @click="cancel()"> {{ texts.modalForm.cancel }} </v-btn>
              <v-btn class="button-save"
                style="max-width: 200px; color: #FFF; background-color: #193D6E;"
                elevation="0"
                :disabled="loading"
                @click="updateInvoice()"> 
                {{ texts.modalForm.save }}
              </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

export default {
  name: "DashboardView",
  data() {
    return {
      nameXLSX: "FacturasPorAsignar.xlsx",
      modal: false,
      dialogForward: false,
      dialogDeleteConversion: false,
      dialogAddConversion: false,
      dialogSendPayOfInvoice: false,
      conversionPayment: {
        sTransactionId: "",
        sPaymentRecipientId: "",
        dAmount: "",
        detail: {
          supplier: {},
          conversion: {},
        },
      },
      comment: null,
      otp: "",
      sAccountNumber: null,
      dialogEditInvoice: false,
      sPaymentRecipientId: "",
      conversion: {},
      step: 0,
      prevAmountSelected: 0,
      dAmountToPay: 0,
      loadingExchange: false,
      texts: "",
      selectedCard: 0,
      dialog: false,
      dialogEditConversion: false,
      dialogDelete: false,
      dialogForex: false,
      sExchangeRateReference: null,
      drawer: null,
      actualCurrency: "0",
      actualCurrencyMxn: "0",
      referenceCurrency: "0",
      dialogConvert: false,
      isLoading: false,
      isRegisterEfex: true,
      invoice: [],
      exchangeRateData: [],
      selected: [],
      exchangeRateDataPaid: [],
      exchangeRateDataSeparate: [],
      balanceMxn: "0",
      balanceUsd: "0",
      countdownDashboard: 59,
      balance: "0",
      selected: [],
      items: [],
      selectedSupplier: "",
      selectedStatus: "453f47d8-d5ad-4159-be49-b48b0f04caa5",
      limit: null,
      tab: 0,
      contacts: [],
      today: null,
      itemsSelected: [],
      itemsSelectedForwards: [],
      isForwardRateCalculated: false,
      bSelectedAll: false,
      dialogAddFoundss: false,
      dialogSendPay: false,
      dialogAddFounds: false,
      dataAddFounds: {},
      dialogCreate: false,
      dialogPay: false,
      editableData: {},
      selectedCard: null,
      totalUsd: 0,
      loading: false,
      selectedMenuItem: "dashboard",
      contactData: {
        sName: "",
        sEmail: "",
        sBank_name: "",
        sAccount_number: "",
        sEntity_business: "COMPANY",
      },
      isForwardsActive: false,
      conversionSelected: {},
      dDateForward: null,
      showCreateProvider: false,
      isResponsive: false,
      screenWidth: 0,
      bLoading: false,
      isDeferredPayment: false,
      rules: {
        required: (v) => !!v || "El campo es obligatorio",
      },
      forms: {
        new: {
          dPurchaseDate: "",
          sPaymentRecipientId: "",
          sInvoice: "",
          dTotalAmount: "",
          dRateProtection: "",
          iCreditPeriod: "",
        },
        detail: {
          sInvoiceId: "",
          dPurchaseDate: "",
          sPaymentRecipientId: "",
          sInvoice: "",
          dTotalAmount: "",
          dRateProtection: "",
          iCreditPeriod: "",
        },
        delete: {
          sInvoiceId: "",
        },
      },
      stepper: false,
      e1: 1,
      suppliers: [],
      status: [
        { sName: "Paid", sStatusId: "f42d5b71-bcc8-4c2c-997a-40ad2da2bc31" },
        { sName: "Assigned", sStatusId: "904a91ab-bdfc-45dc-a72f-fc02c2a15005" },
        { sName: "To pay", sStatusId: "453f47d8-d5ad-4159-be49-b48b0f04caa5" },
      ],
      timerCount: 30,
      loadingConversion: false,
      headersInvoices: [
        { text: "Supplier", value: "sName", align: "center" },
        { text: "Purchase date", value: "dPurchaseDate", align: "center" },
        { text: "Due date", value: "dDueDate", align: "center" },
        { text: "Invoice", value: "sInvoice", align: "center" },
        { text: "Status", value: "sStatusId", align: "center" },
        { text: "Total amount MXN", value: "dTotalAmount", align: "center" },
        { text: "Exchange rate protection MXN", value: "dRateProtection", align: "center", sortable: false },
        { text: "Currency profit (%)", value: "dProfitPercentage", align: "center" },
        { text: "Currency gain", value: "dCurrencyGain", align: "center" },
        { text: "Actions", value: "actions", align: "center" },
      ],
      timerId: null,
      dataEfex: {},
      exchangeRateAdjustments: [],
      exchangeRatesStateForwards: [],
      isPayMxn: false,
      bSelectedAllSeparate: false,
      dialogEditProfileActive: false,
      isForwards: false,
      isForwardsPayment: false,
      userName: "",
      forwardRate: null,
      modalCurrency: 0,
      forwardActualRate: null,
      payDetail: {
        supplier: "",
        totalUSD: "",
        exchangeRate: "",
        dTotalAmount: "",
        sAccountNumber: "",
        exchangeRateToday: "",
        sInvoice: "",
      },
      forexDetail: {
        sInvoice: "",
        totalUSD: "",
        exchangeRate: "",
        dTotalAmount: "",
      },
      forwardDetail: {
        sInvoice: "",
        totalUSD: "",
        exchangeRate: "",
        dTotalAmount: "",
      },
      miniVariant: true,
      expandOnHover: false,
      registerFields: [
        { model: "sBankName", type: "text", label: "sBankName" },
        { model: "sAccountNumber", type: "text", label: "sAccountNumber" },
        { model: "sRoutingNumber", type: "text", label: "sRoutingNumber" },
      ],
      headersForex: [
        this.selectLanguage === 'en' ? { text: "Id", value: "iTrackingId", align: "center", sortable: false} : { text: "Id", value: "iTrackingId", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Creation date", value: "created_at", align: "center"} : { text: "Fecha de creación", value: "created_at", align: "center"},
        this.selectLanguage === 'en' ? { text: "Type Transaction", value: "sTransactionType", align: "center"} : { text: "Tipo de transacción", value: "sTransactionType", align: "center"},
        this.selectLanguage === 'en' ? { text: "Type Transaction", value: "sTransactionAvaible", align: "center"} : { text: "Conversion disponible", value: "sTransactionAvaible", align: "center"},
        this.selectLanguage === 'en' ? { text: "Total amount USD", value: "dAmount", align: "center"} : { text: "Monto total USD", value: "dAmount", align: "center"},
        this.selectLanguage === 'en' ? { text: "Total amount MXN", value: "dDesAmount", align: "center"} : { text: "Monto total MXN", value: "dDesAmount", align: "center"},
        this.selectLanguage === 'en' ? { text: "Exchange rate", value: "dExchangeRate", align: "center"} : { text: "Tasa de cambio", value: "dExchangeRate", align: "center"},
        this.selectLanguage === 'en' ? { text: "Currency profit (%)", value: "percentProfit", align: "center", sortable: false} : { text: "Beneficio de moneda (%)", value: "percentProfit", align: "center", sortable: false}, 
        this.selectLanguage === 'en' ? { text: "Currency gain",value: "dollarProfit", align: "center", sortable: false} : { text: "Beneficio de moneda",value: "dollarProfit", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Actions", value: "actions", align: "center", sortable: false } : { text: "Acciones", value: "actions", align: "center", sortable: false },
      ],
      headersAvaibleTransaction: [
        this.selectLanguage === 'en' ? { text: "Id", value: "iTrackingId", align: "center", sortable: false} : { text: "Id", value: "iTrackingId", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Creation date", value: "created_at", align: "center"} : { text: "Fecha de creación", value: "created_at", align: "center"},
        this.selectLanguage === 'en' ? { text: "Type Transaction", value: "sTransactionType", align: "center"} : { text: "Tipo de transacción", value: "sTransactionType", align: "center"},
        this.selectLanguage === 'en' ? { text: "Total amount USD", value: "dAmount", align: "center"} : { text: "Monto total USD", value: "dAmount", align: "center"},
        this.selectLanguage === 'en' ? { text: "Total amount MXN", value: "dDesAmount", align: "center"} : { text: "Monto total MXN", value: "dDesAmount", align: "center"},
        this.selectLanguage === 'en' ? { text: "Exchange rate", value: "dExchangeRate", align: "center"} : { text: "Tasa de cambio", value: "dExchangeRate", align: "center"},
        this.selectLanguage === 'en' ? { text: "Currency profit (%)", value: "percentProfit", align: "center", sortable: false} : { text: "Beneficio de moneda (%)", value: "percentProfit", align: "center", sortable: false}, 
        this.selectLanguage === 'en' ? { text: "Currency gain",value: "dollarProfit", align: "center", sortable: false} : { text: "Beneficio de moneda",value: "dollarProfit", align: "center", sortable: false},
        this.selectLanguage === 'en' ? { text: "Actions", value: "actions", align: "center", sortable: false } : { text: "Acciones", value: "actions", align: "center", sortable: false },
      ],
      avaibleTransactions: [],
      transactions: [],
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    validateAmount() {
      if (parseFloat(this.dAmountToPay) <= parseFloat(this.invoice?.avaibleToPayAmount)) {
        return true;
      }
      return "This field is required";
    },
    cancelPay() {
      this.dialogSendPayOfInvoice = false;
      this.dAmountToPay = 0;
    },
    goToConversion(item) {
      this.$router.push(`/balance/${item.sTransactionId}`);
    },
    openDialogDeleteConversion(item) {
      this.dialogDeleteConversion = true;
      this.conversionSelected = item;
    },
    openAddConversion(conversion) {
      this.dialogAddConversion = true;
      this.loading = true;
      
      let payload = {
        sTransactionId: conversion.sTransactionId
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/transactions/avaibleTransaction`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.conversionSelected = { ...conversion };
          this.conversionSelected.dAmount = 0;
          this.conversionSelected.dTotalAmount = this.conversionSelected.dDesAmount;
          this.loading = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });

    },
    openEditConversion(conversion) {
      this.dialogEditConversion = true;
      this.loading = true;
      
      let payload = {
        sTransactionId: conversion.sTransactionId,
        sInvoiceId: conversion.invoice.sInvoiceId
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/transactions/avaibleTransaction`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.conversionSelected = { ...conversion };
          this.prevAmountSelected = this.conversionSelected.dAmount;
          this.conversionSelected.dAmount = this.conversionSelected.dAmount;


          this.conversionSelected.dTotalAmount = response.data.transactions.dDesAmount
          this.loading = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openPayInvoices(item) {
      this.dialogSendPayOfInvoice = true;
      this.step = 1;
    },
    sendVerification(step) {
      this.step = step;
      try {
        DB.get(`${URI}/api/v1/${this.selectLanguage}/authenticator/`, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        }).then((response) => {
          if(!response.data.success){
            this.mixError(response.data.message, response.status);
          }
        })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
        }
        catch(error){
          this.mixError(error.response.data.message, error.response.status);
        }
    },
    updateConversion(item) {
      this.loadingAddConversion = true;
      this.loading = true;
      this.avaibleTransactions = [];
      this.loadingConversion = true;
      
      let payload = {
        sInvoiceTransactionId: this.conversionSelected.sInvoiceTransactionId,
        dAmount: parseFloat(this.conversionSelected.dAmount)
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/invoices/updateConversion`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.loading = false;
          this.getInvoice(true)
          this.dialogEditConversion = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    addConversion(item) {
      this.loadingAddConversion = true;
      this.loading = true;
      this.avaibleTransactions = [];
      this.loadingConversion = true;
      
      let payload = {
        sTransactionId: this.conversionSelected.sTransactionId,
        sInvoiceId: this.invoice.sInvoiceId,
        dAmount: parseFloat(this.conversionSelected.dAmount)
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/invoices/addConversion`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.loading = false;
          this.getInvoice(true)
          this.dialogAddConversion = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    deleteConversion() {
      this.loadingAddConversion = true;
      this.loading = true;
      this.avaibleTransactions = [];
      this.loadingConversion = true;
      
      let payload = {
        sInvoiceTransactionId: this.conversionSelected.sInvoiceTransactionId,
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/invoices/deleteConversion`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.loading = false;
          this.getInvoice(true)
          this.dialogDeleteConversion = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getSuppliers() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/payment-recipient/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      }).then((response) => {
        this.suppliers = response.data.paymentRecipients;
      });
    },
    updateInvoice() {
      if (this.$refs.formEdit.validate()) {
        this.loading = true;

        let payload = {
          sInvoiceId: this.forms.detail.sInvoiceId,
          dPurchaseDate: this.forms.detail.dPurchaseDate,
          sPaymentRecipientId: this.forms.detail.sPaymentRecipientId,
          sInvoice: this.forms.detail.sInvoice,
          sId: this.forms.detail.sId,
          dTotalAmount: this.forms.detail.dTotalAmount,
          dRateProtection: this.forms.detail.dRateProtection,
          iCreditPeriod: this.forms.detail.iCreditPeriod,
        };

        DB.put(`${URI}/api/v1/${this.selectLanguage}/invoices/`, payload, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : "",
          },
        })
          .then((response) => {
            this.loading = false;
            this.dialogEditInvoice = false;
            this.getInvoice();
            this.cancel();
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      } else {
        this.error = true;
        this.codeError = "INVALID_INPUT";
      }
    },
    openTransactions() {
      this.dialog = true;
      this.avaibleTransactions = [];
      this.loadingConversion = true;

      const payload = {
        avaibleAmount: this.invoice.avaibleAmount,
        dRateProtection: this.invoice.dRateProtection
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/transactions/avaibleTransactions`, payload, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
      .then((response) => {
        this.avaibleTransactions = response.data.transactions
        this.loadingConversion = false;
      });
    },
    editInvoice() {
      this.dialogEditInvoice = true;
      this.forms.detail.sInvoiceId = this.invoice.sInvoiceId;
      this.forms.detail.dPurchaseDate = moment(this.invoice.dPurchaseDate).format("YYYY-MM-DD");
      this.forms.detail.sPaymentRecipientId = this.invoice.sPaymentRecipientId;
      this.forms.detail.sInvoice = this.invoice.sInvoice;
      this.forms.detail.dTotalAmount = this.invoice.dTotalAmount;
      this.forms.detail.dRateProtection = this.invoice.dRateProtection;
      this.forms.detail.iCreditPeriod = this.invoice.iCreditPeriod;
    },
    cancel() {
      this.dialogEditInvoice = false;
      this.dialogDeleteConversion = false;
      this.dialogSendPayOfInvoice = false;
      this.dialog = false;
      this.dialogDelete = false;
      this.dialogSendPay = false;
      this.forwardActualRate = null;
      this.selectedCard = null;
      this.dialogEditConversion = false;
      this.dialogConvert = false;
      this.dDateForward = null;
      this.dialogForex = false;
      this.dialogForward = false;
      this.forms.new = {
        dPurchaseDate: "",
        sPaymentRecipientId: "",
        sInvoice: "",
        dTotalAmount: "",
        dRateProtection: "",
        iCreditPeriod: "",
      };
      this.forms.detail = {
        sInvoiceId: "",
        dPurchaseDate: "",
        sPaymentRecipientId: "",
        sInvoice: "",
        dTotalAmount: "",
        dRateProtection: "",
        iCreditPeriod: "",
      };
    },
    getSuppliers() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/payment-recipient/`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      }).then((response) => {
        this.suppliers = response.data.paymentRecipients;
      });
    },
    handleResize() {
      if (window.innerWidth >= 960) {
        this.screenWidth = 30;
      } else {
        this.screenWidth = 100;
      }

      this.isResponsive = window.innerWidth < 960;
    },
    getInvoice(item) {
      this.isLoading = true;
      this.invoice = [];
      this.selected = [];

      DB.get(`${URI}/api/v1/${this.selectLanguage}/invoices/${this.$route.params.idInvoice}`, {
        headers: {
          Authorization: this.$store.state.sToken
            ? `Bearer ${this.$store.state.sToken}`
            : "",
        },
      })
        .then((response) => {
          this.isLoading = false;
          this.invoice = response.data.invoice;
          if (item) {
            this.openTransactions();
          }
          this.invoice.dTotalAmountAssign = 0;
          this.invoice.transacionsAssigned = [];
          this.invoice.invoice_payments_relationship.map((invoice) => {
            this.invoice.dTotalAmountAssign += parseFloat(invoice.dAmount);
            this.invoice.transacionsAssigned.push(invoice)
            return invoice;
          })
          console.log(this.invoice.transacionsAssigned)
          // this.items = response.data;
          // this.isLoading = false;
          // this.startCountdownDashboard();
          // this.invoices = this.items.invoices;
        })
        .catch((error) => {
          if (error.response.data.code) {
            this.$router.push("/welcome").catch(() => {});
          }
          this.isLoadingSuppliers = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    formatMoney(value) {
      let multiplied = value * 100;
      let rounded = Math.round(multiplied);
      let formattedNumber = rounded / 100;
      return new Intl.NumberFormat("en-US", {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(formattedNumber);
    },
    formatDate(date) {
      if (this.selectLanguage === "sp") {
        moment.locale("es");
      } else {
        moment.locale("en");
      }
      let newDate = moment(date);
      let formattedDate = newDate.format("MMMM D, YYYY");
      return formattedDate;
    },
    async validateOtp() {
      try {
        const payload = {
          sCode: this.otp
        };

        const response = await DB.post(`${URI}/api/v1/${this.selectLanguage}/authenticator/verify`, payload, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
          }
        });

        return response.data.success;
      } catch (error) {
        return false;
      }
      },
    async sendPayment() {
      this.loading = true;

      const isValid = await this.validateOtp();
      if (!isValid) {
        this.mixError("Código OTP incorrecto", 400);
        this.loading = false;
        return;
      }

      const payload = {
        sInvoiceId: this.invoice.sInvoiceId,
        dAmount: parseFloat(this.dAmountToPay),
        comment: this.comment,
        sPaymentRecipientId: this.invoice.sPaymentRecipientId,
        transactions: this.invoice.transacionsAssigned,
        exchangeRate: this.invoice.realExchangeRate
      };

      try {
        const response = await DB.post(`${URI}/api/v1/${this.selectLanguage}/payments/paymentFromInvoice`,
        payload, {
          headers: {
            Authorization: this.$store.state.sToken
              ? `Bearer ${this.$store.state.sToken}`
              : ""
            }
          }
        );
        this.cancel();
        this.getInvoice();
        this.loading = false;
        if (!response.data.success) {
          this.mixError(response.data.message, response.status);
          return;
        }
      } catch (error) {
        this.loading = false;
        this.mixError(error.response.data.message, error.response.status);
      }
    },
    dataXLSX() {
      try {
        let data = this.invoices.map((item) => {
          return {
            Supplier: item.sName ? item.sName : "",
            "Purchase date": item.created_at ? this.formatDate(item.created_at) : "",
            "Due date": item.dDueDate ? this.formatDate(item.dDueDate) : "N/A",
            Invoice: item.sInvoice ? item.sInvoice : "",
            "Status": item.sStatusId == '453f47d8-d5ad-4159-be49-b48b0f04caa5' ? 'To Pay' : (item.sStatusId == '904a91ab-bdfc-45dc-a72f-fc02c2a15005' ? 'Assigned' : (item.sStatusId == 'f42d5b71-bcc8-4c2c-997a-40ad2da2bc31' ? 'Paid' : "N/A")),
            "Total amount (MXN)": item.dTotalAmount ? parseFloat(item.dTotalAmount) : "",
            "Exchange rate protection": item.dRateProtection ? item.dRateProtection : "",
            "Currency profit (%)": item.dProfitPercentage ? parseFloat(item.dProfitPercentage) : "",
            "Currency gain (USD)": item.dCurrencyGain ? parseFloat(item.dCurrencyGain) : "",
          };
        });
        const worksheet = XLSX.utils.json_to_sheet(data);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Hoja1");

        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        saveAs(blob, this.nameXLSX);
        this.success = true;
        this.msgSuccess = "Descargando documento";
      } catch (error) {
        this.error = true;
        this.codeError = "CSV_NO_DATA";
      }
    }
  },
  beforeMount() {
    this.texts = FILE.takeRate[this.selectLanguage];
    this.getInvoice();
    this.getSuppliers();
    this.today = moment().add(1, "day").format("YYYY-MM-DD");
  },
  beforeDestroy() {
    // Limpia el intervalo cuando el componente se destruye
    clearInterval(this.intervalId);
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    formattedTime() {
      const minutes = Math.floor(this.timerCount / 60);
      const seconds = this.timerCount % 60;
      return `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.texts = FILE.takeRate[this.selectLanguage];
      }
    },
    "editableData.sProvider": function (newValue) {
      this.showCreateProvider = newValue === 0;
    },
  },
};
</script>

<style scoped>
.card-title {
  color: #302e2e;
  font-size: 16px;
  font-family: montserrat-bold;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.headline-medium {
  font-family: "montserrat-semibold";
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.h5 {
  font-family: "montserrat-bold";
  font-size: 24px;
  line-height: 36px;
  text-align: left;
}

@media (max-width: 1150px) {
  .card-title {
    font-size: 14px;
  }
}
</style>
